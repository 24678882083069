import {
  ADD_LIVE_RESOURCE,
  ADD_LIVE_TAG,
  ADD_LIVE_TAG_FAILURE,
  ADD_LIVE_TAG_SUCCESS,
  ADD_LIVE_VIEW,
  ADD_LIVE_VIEW_FAILURE,
  ADD_LIVE_VIEW_SUCCESS,
  DELETE_LIVE_RESOURCE,
  DELETE_LIVE_TAG,
  DELETE_LIVE_TAG_FAILURE,
  DELETE_LIVE_TAG_SUCCESS,
  DELETE_LIVE_VIEW,
  DELETE_LIVE_VIEW_FAILURE,
  DELETE_LIVE_VIEW_SUCCESS,
  END_LIVE_STREAM,
  END_LIVE_STREAM_SUCCESS,
  FETCH_LIVE_ACCEL_POLL,
  FETCH_LIVE_ACCEL_POLL_FAILURE,
  FETCH_LIVE_ACCEL_POLL_SUCCESS,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_FAILURE,
  FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_SUCCESS,
  FETCH_LIVE_INCIDENT,
  FETCH_LIVE_INCIDENT_FAILURE,
  FETCH_LIVE_INCIDENT_SUCCESS,
  // to avoid null payload in fetch_..._success
  FETCH_LIVE_LOCATION,
  FETCH_LIVE_LOCATION_FAILURE,
  FETCH_LIVE_LOCATION_SUCCESS,
  FETCH_LIVE_PERSON,
  FETCH_LIVE_PERSON_FAILURE,
  FETCH_LIVE_PERSON_SUCCESS,
  FETCH_LIVE_VEHICLE,
  FETCH_LIVE_VEHICLE_FAILURE,
  FETCH_LIVE_VEHICLE_SUCCESS,
  FETCH_LIVE_VIEWS,
  FETCH_LIVE_VIEWS_FAILURE,
  FETCH_LIVE_VIEWS_SUCCESS,
  FILTER_LIVE_LIST,
  LIVE_STREAM_WARNING,
  START_LIVE_STREAM,
  START_LIVE_STREAM_FAILURE,
  START_LIVE_STREAM_SUCCESS,
  //UPDATE_LIVE_FILTERS,
  UPDATE_LIVE_ADVANCED_FILTERS,
  UPDATE_LIVE_LAYER_VISIBILITIES,
  UPDATE_LIVE_RESOURCE,
  UPDATE_LIVE_RESOURCES,
  UPDATE_LIVE_RESOURCES_IN_LOCATIONS,
  UPDATE_LIVE_SEARCHTEXTS,
} from '@/actions/types';
import { api } from '@/apis';
import { getLiveSubscription } from '@/components/live/constants';
import {
  applyAdvancedFilters,
  applySearch,
  encodeParams,
  fetchLocationRequest,
  fetchPersonRequest,
  fetchVehicleRequest,
  filterDict,
  getNewIdentifier,
  getUser,
  idProperties,
  isStale,
  log,
  objFilter,
} from '@/utils';
import {
  dioStates,
  liveOptions,
  nominatimRootUrl,
  vehicleEmergencyEquipment,
  wsRootUrl,
} from '@/utils/config';
import { liveFilters } from '@/utils/constants';
import { addMilliseconds, format, getMinutes, getSeconds } from 'date-fns';
import ky from 'ky';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, from, interval, of, timer } from 'rxjs';
import {
  catchError,
  endWith,
  filter,
  groupBy,
  last,
  // tap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  toArray,
  windowTime,
} from 'rxjs/operators';

const { dummyMovingVehicle, callSignStatusCategory } = liveOptions;

const typeRename = {
  accelerometerAlerts: 'events',
};

function objectiveIsActive(objective) {
  const now = new Date();

  if (!objective.schedule) {
    return true;
  }

  return objective.schedule[now.getDay()][now.getHours()];
}

const someFilteringApplied = (
  searchTexts,
  filters,
  advancedFilters,
  customFilter,
) => {
  return (
    (searchTexts || '') !== '' ||
    Object.keys(filters || {}).some((key) => filters[key].length > 0) ||
    (advancedFilters || []).some(
      (av) => av.field != null && av.value != null,
    ) ||
    customFilter
  );
};

const searchAndFiltersForType = (type, liveObj) => {
  const {
    searchTexts: { [type]: searchText },
    filters: { [type]: filters },
    advancedFilters: { [type]: advancedFilters },
  } = liveObj;

  return [
    searchText,
    filters,
    advancedFilters,
    customFilterFunctionsByType[type],
  ];
};

function callSignMembersNotFilteredOut(callSign, filteredInIdsByType) {
  // are there any people or vehicles unfiltered for this callSign?
  return ['people', 'vehicles'].some((type) => {
    // if this callsign doesn't have any of the type there are no members of
    // this types filtered in
    if (!callSign.itemsByType[type]) {
      return false;
    }

    // if there are no filters of this type then all the members of this type are in
    if (!filteredInIdsByType?.[type]) {
      return true;
    }

    // if we get here, there are members of this type and there are filters set
    // do we have any members that are filtered in?
    return Object.keys(callSign.itemsByType[type]).some(
      (memberId) => filteredInIdsByType[type][memberId],
    );
  });
}

const customFilterFunctionsByType = {
  callSigns: callSignMembersNotFilteredOut,
};

export const liveLocationResourcesUpdateEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_LIVE_LAYER_VISIBILITIES),
    map(() => {
      return {
        type: UPDATE_LIVE_RESOURCES_IN_LOCATIONS,
      };
    }),
  );

export const liveFiltersChangedEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_LIVE_ADVANCED_FILTERS, UPDATE_LIVE_SEARCHTEXTS),
    map(() => {
      const filteredInIdsByType = {};

      log('Filter', 'Live List', {
        searchTexts: state$.value.live.searchTexts,
        advancedFilters: state$.value.live.advancedFilters,
      });

      Object.keys(liveFilters)
        .concat(['tags', 'callSigns'])
        .forEach((type) => {
          const [searchText, filters, advancedFilters, customFilter] =
            searchAndFiltersForType(type, state$.value.live);

          if (
            someFilteringApplied(
              searchText,
              filters,
              advancedFilters,
              customFilter,
            )
          ) {
            const resources = state$.value.live[type];
            let filteredIn = filterDict(
              resources,
              searchText,
              filters,
              advancedFilters,
            );

            // apply custom secondary filtering (other than the normal filter fields)
            // for now this is just for call signs, if all members of a call sign are
            // filtered out then the call sign should be filtered out too
            if (customFilter) {
              filteredIn = objFilter(
                filteredIn,
                customFilter,
                filteredInIdsByType,
              );
            }

            Object.keys(filteredIn).forEach((key) => (filteredIn[key] = true));

            filteredInIdsByType[type] = filteredIn;
          }
        });

      return {
        type: FILTER_LIVE_LIST,
        payload: filteredInIdsByType,
      };
    }),
  );

function isEmergencyEquipmentOn(resource) {
  return vehicleEmergencyEquipment?.some((k) => resource[k]);
}

function getCallSignCategory(resource, type) {
  if (type === 'people') {
    const callSign = resource.assignments?.callSign;
    if (callSign && callSign.status) {
      const callSignCategory =
        callSignStatusCategory[callSign.status] || 'Unclassified';

      if (callSignCategory) {
        return callSignCategory;
      }
    }
  }
}

function getStatus(resource, type) {
  switch (type) {
    case 'people':
    case 'vehicles': {
      // if something hasn't moved in a while none of the other statuses matter
      if (isStale(resource, type)) {
        return 'stale';
      }

      // this is in order of importance, i.e. emergency trumps callSign trumps mil
      if (resource.strikeButtonOn || resource.emergencyButtonOn) {
        return 'emergency';
      }

      const callSign = resource.assignments?.callSign;
      if (callSign && callSign.status) {
        const callSignStatus =
          callSignStatusCategory[callSign.status] || 'Unclassified';

        if (callSignStatus) {
          return callSignStatus;
        }
      }

      switch (true) {
        case resource.assignments?.incident?.number:
          return 'assigned';
        case resource.malfunctionIndicatorLightOn:
          return 'malfunctioning';
        case isEmergencyEquipmentOn(resource):
          return 'warning equipment';
        case resource.ignitionOn === false:
          return 'parked';
        default:
          return 'default';
      }
    }
    case 'incidents':
      return resource.status;
    case 'objectives':
      return (
        resource.status || (objectiveIsActive(resource) ? 'active' : 'inactive')
      );
    case 'radios':
    case 'telematicsBoxes': {
      if (isStale(resource, type)) {
        return 'stale';
      }

      switch (true) {
        case resource.mostRecentPoll?.emergencyEquipmentOn:
          return 'warning equipment';
        case resource.mostRecentPoll?.ignitionOn === false:
          return 'parked';
        default:
          return 'default';
      }
    }
    default:
      return 'default';
  }
}

function getCallSignStatus(resource, type) {
  if (type === 'people') {
    const callSign = resource.assignments?.callSign;
    if (callSign && callSign.status) {
      const callSignStatus = callSign.status;
      if (callSignStatus) {
        return callSignStatus;
      }
    }
  }
}

function getDriverName(person, rfidCard) {
  const rfidCardNo = rfidCard?.reference ? `(${rfidCard.reference})` : '';
  const rfidCardLabelOrUnknown = rfidCard?.label
    ? `${rfidCard.label} ${rfidCardNo}`
    : 'Unknown driver';

  if (!person) {
    return rfidCardLabelOrUnknown;
  }

  const name = fullName(person);
  // if there is a name return that
  if (name) {
    return name;
  }

  // if they have an id show that
  if (person.identificationReference) {
    return `Unrecognised card (${person.identificationReference})`;
  }

  return rfidCardLabelOrUnknown;
}

function fullName(person) {
  if (!person) {
    return undefined;
  }

  const fullName = [
    person.forenames,
    person.surname,
    person.collarNumber && `[${person.collarNumber}]`,
  ]
    .filter(Boolean)
    .join(' ')
    .trim();

  return fullName.length > 0 ? fullName : undefined;
}

function derivedResourceProperties(type, resource, state$) {
  const homeStation =
    state$.value.live?.locations?.[resource.homeStation]?.name ??
    resource.homeStation;

  switch (type) {
    case 'vehicles': {
      let driver = resource.ignitionOn ? resource.driver : resource.lastDriver;
      const driverName = getDriverName(driver, resource?.rfidCard);
      // wipe these so they don't appear in search string (in case useReduced...)
      [resource.driver, resource.lastDriver].forEach((d) => {
        if (d) {
          d.forenames = '';
          d.surname = '';
        }
      });

      // if the vehicle doesn't have a call sign and the active driver does, use that
      let { assignments } = resource;
      if (
        !assignments?.callSign?.code &&
        resource.driver?.assignments?.callSign?.code
      ) {
        assignments = resource.driver.assignments;
      }

      let speeding,
        speedLimitTag = undefined;
      if (resource.reverseGeocode) {
        const knownLimit = !(resource.reverseGeocode?.unknownLimit ?? true); // assume unknown
        speeding =
          knownLimit &&
          resource.speedKilometresPerHour >
            resource.reverseGeocode?.speedLimitKilometresPerHour;
        speedLimitTag = resource.reverseGeocode.osm?.maxSpeedTag;
      }

      return {
        ...(driver
          ? {
              driverSkillsArray: driver.skills?.map((s) => s.name) || [],
              driverName,
            }
          : {}),
        driver, // overwrite the driver with last driver for easy filtering
        registrationNumber: resource?.registrationNumber?.replace(/ /g, ''),
        assignments,
        emergencyEquipmentOn: isEmergencyEquipmentOn(resource),
        stale: isStale(resource, type),
        homeStation,
        speeding,
        speedLimitTag,
        locationTypesArray:
          resource.currentLocations?.map((item) => item.type) || [],
        locationNamesArray:
          resource.currentLocations?.map(
            (item) => `[${item.type}] ${item.name}`,
          ) || [],
      };
    }
    case 'people':
      return {
        skillsArray: resource.skills?.map((s) => s.name) || [],
        // wipe these so they don't appear in search string
        forenames: '',
        surname: '',
        name: fullName(resource),
        driverKeysArray: resource.rfidCards?.map((r) => r.reference) || [],
        stale: isStale(resource, type),
        homeStation,
        locationTypesArray:
          resource.currentLocations?.map((item) => item.type) || [],
        locationNamesArray:
          resource.currentLocations?.map(
            (item) => `[${item.type}] ${item.name}`,
          ) || [],
      };
    case 'events':
    case 'accelerometerAlerts':
      return {
        driverName: getDriverName(resource.driver),
        locationTypesArray: resource.locations?.map((item) => item.type) || [],
        locationNamesArray:
          resource.locations?.map((item) => `[${item.type}] ${item.name}`) ||
          [],
      };
    case 'incidents':
      return {
        closingCodesArray: resource.closingCodes?.map((c) => c.name) || [],
        locationTypesArray: resource.locations?.map((item) => item.type) || [],
        locationNamesArray:
          resource.locations?.map((item) => `[${item.type}] ${item.name}`) ||
          [],
        reverseGeocode: resource.reverseGeocode,
      };
    case 'telematicsBoxes':
      return {
        lastPosition: resource.mostRecentPoll.position,
        lastPollTime: resource.mostRecentPoll.time,
        stale: isStale(resource, type),
        locationTypesArray:
          resource?.mostRecentPoll?.locations?.map((item) => item.type) || [],
        locationNamesArray:
          resource?.mostRecentPoll?.locations?.map(
            (item) => `[${item.type}] ${item.name}`,
          ) || [],
      };
    case 'radios': {
      return {
        locationTypesArray:
          resource?.lastRadioPoll?.locations?.map((item) => item.type) || [],
        locationNamesArray:
          resource?.lastRadioPoll?.locations?.map(
            (item) => `[${item.type}] ${item.name}`,
          ) || [],
        stale: isStale(resource, type),
      };
    }
    case 'locations':
      return {};
    // case 'objectives':
    //   // handle how newer objectives have an object with icon as the type
    //   return {
    //     type:
    //       typeof resource.type === 'string'
    //         ? resource.type
    //         : resource.type?.name,
    //   };
    default:
      return {};
  }
}

function addToGroup(
  updates,
  groupType,
  groupKey,
  type,
  id,
  groupObject,
  groupAggregations,
) {
  // if this group isn't added yet add it
  if (!updates[groupType][groupKey]) {
    updates[groupType][groupKey] = {
      id: groupKey,
      searchString: groupObject?.searchString || groupKey.toLowerCase(),
      itemsByType: {},
    };
  }

  // if the group has other values, update it
  if (groupObject) {
    updates[groupType][groupKey] = {
      ...updates[groupType][groupKey],
      ...groupObject,
    };
  }

  // if this group doesn't have this type (e.g. vehicles) add it
  if (!updates[groupType][groupKey].itemsByType[type]) {
    updates[groupType][groupKey].itemsByType[type] = {};
  }

  // make sure this resource is added to the group
  updates[groupType][groupKey].itemsByType[type][id] =
    groupObject?.item ?? true;

  // if there are aggregations e.g. getting the names of all officers, add them here
  if (groupAggregations) {
    let aggregations = {};
    Object.keys(groupAggregations).forEach((aggregationKey) => {
      aggregations[aggregationKey] = groupAggregations[aggregationKey](
        updates[groupType][groupKey],
      );
    });

    updates[groupType][groupKey] = {
      ...updates[groupType][groupKey],
      ...aggregations,
    };
  }
}

function removeFromGroup(updates, groupType, groupKey, type, id) {
  const itemsByType = updates[groupType][groupKey]?.itemsByType;
  if (itemsByType?.[type][id]) {
    delete itemsByType[type][id];

    // if there are no items left with this group, delete the group
    const resourcesInThisGroup = Object.keys(itemsByType).reduce(
      (total, type) => total + Object.keys(itemsByType[type] || {}).length,
      0,
    );
    if (resourcesInThisGroup === 0) {
      delete updates[groupType][groupKey];
    }
  }
}

export const liveResourceUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_LIVE_RESOURCE, ADD_LIVE_RESOURCE, DELETE_LIVE_RESOURCE),
    // do some transformations on incoming updates...
    map((update) => ({
      ...update,
      // rename accelerometerAlerts to events
      resourceType: typeRename[update.resourceType] || update.resourceType,
      payload: {
        ...update.payload,
        // reduce areas by type so
        // [{type: 'bcu', name: 'Main BCU'}, {type: 'lpu', name: 'LPU A'}]
        // becomes
        // {bcu: 'Main BCU', lpu: 'LPU A'}
        ...derivedResourceProperties(
          update.resourceType,
          update.payload,
          state$,
        ),
        // make sure everything has 'id' instead of x having 'number', y having 'identifier'...
        id:
          update.payload[idProperties[update.resourceType]] ??
          update.payload.id, //.replace('/','\\'),
        // map a status
        status: getStatus(
          update.payload,
          typeRename[update.resourceType] || update.resourceType,
        ),
        callSignCategory: getCallSignCategory(
          update.payload,
          typeRename[update.resourceType] || update.resourceType,
        ),
        callSignStatus: getCallSignStatus(
          update.payload,
          typeRename[update.resourceType] || update.resourceType,
        ),
      },
    })),
    map((update) => {
      // add search strings
      update.payload.searchString = generateSearchString(
        update.resourceType,
        update,
      );
      return update;
    }),
    windowTime(1000),
    mergeMap((s) =>
      s.pipe(
        groupBy((r) => r.type), // group by type first in case id not unique between types
        mergeMap((g) => g.pipe(toArray())),
        mergeMap((arr) =>
          from(arr).pipe(
            groupBy((r) => r.payload.id), // then group by id to get all updates in this window
            mergeMap((group) =>
              group.pipe(
                last(), // get the most recent one so and add followed by a delete becomes a delete
              ),
            ),
          ),
        ),
        toArray(),
      ),
    ),
    filter((updateArray) => updateArray.length > 0),
    // pass on updates and if needed change filtered-in lists
    map((updateArray) => {
      const tagsType = 'tags';
      const callSignsType = 'callSigns';
      const updates = {
        tags: { ...(state$.value.live.tags || {}) },
        callSigns: { ...(state$.value.live.callSigns || {}) },
        // vehicles: etc. will get filled out if needed
      };

      // tags
      function updateTag(update) {
        const {
          resourceType: type,
          payload: { id, tags = [], tagChanged },
        } = update;

        // if the tag changed, check if a tag was removed
        if (tagChanged || update.type === DELETE_LIVE_RESOURCE) {
          const oldTags = state$.value.live[type]?.[id]?.tags || [];
          const newTags = tagChanged ? tags : [];

          const deletedTags = oldTags.filter(
            (oldTag) => !newTags.includes(oldTag),
          );
          deletedTags.forEach((deletedTag) => {
            removeFromGroup(updates, tagsType, deletedTag, type, id);
          });
        }

        if (tags.length > 0) {
          tags.forEach((tag) => {
            addToGroup(updates, tagsType, tag, type, id);
          });
        }
      }

      // callsigns
      function updateCallSign(update) {
        const {
          resourceType: type,
          payload: { id, assignments: { callSign, incident } = {}, name },
        } = update;

        // need the old value to see if we need to remove old callSign
        const prevCallSign = Object.values(updates.callSigns).find(
          (callSign) => callSign.itemsByType[type]?.[id],
        );

        if (!callSign && !prevCallSign) {
          return;
        }

        // if the callsign changed, remove it from its old callsign
        if (
          ['vehicles', 'people'].includes(type) &&
          prevCallSign &&
          prevCallSign.code !== callSign?.code
        ) {
          removeFromGroup(updates, callSignsType, prevCallSign.code, type, id);
        }

        // if we currently have one add/update it
        if (callSign?.code) {
          // if the callSign's incident changed, remove the incident
          const prevIncidentNumber =
            updates.callSigns?.[callSign.code]?.incident?.number;
          if (prevIncidentNumber && prevIncidentNumber !== incident?.number) {
            removeFromGroup(
              updates,
              callSignsType,
              callSign.code,
              'incidents',
              prevIncidentNumber,
            );
          }

          const searchString = [
            callSign.code,
            incident?.number,
            callSign.status,
            callSign.category,
          ]
            .filter(Boolean)
            .join('+')
            .toLowerCase();

          // so there's a name associated with the call sign, SWP usually have 1:1
          const nameObj = name ? { name } : {};

          addToGroup(
            updates,
            callSignsType,
            callSign.code,
            type,
            id,
            {
              ...callSign,
              ...nameObj,
              searchString,
              incident,
              item: update,
              iconStatus:
                callSign.status ||
                callSign.category ||
                (incident?.number && 'assigned'),
            },
            {
              names: (group) => {
                if (group.itemsByType['people']) {
                  return Object.values(group.itemsByType['people']).map(
                    (p) => p.payload.name,
                  );
                } else {
                  return undefined;
                }
              },
            },
          );
        }

        // if it has an incident add that too
        if (incident?.number) {
          addToGroup(
            updates,
            callSignsType,
            callSign.code,
            'incidents',
            incident.number,
          );
        }
      }

      function updateResource(update) {
        const {
          resourceType: type,
          payload: { id },
        } = update;

        if (!updates[type]) {
          updates[type] = {
            ...(state$.value.live[type] || {}),
          };
        }

        if (update.type === DELETE_LIVE_RESOURCE) {
          delete updates[type][id];
        } else {
          // if this resource has a polltime, check that it's newer, otherwise
          // it might be a reconnect so no need to update it and slow the map
          const unchanged =
            update.lastPollTime &&
            state$.value.live[type]?.id?.lastPollTime === update.lastPollTime;
          if (!unchanged) {
            updates[type][id] = update.payload;
          }
        }
      }

      updateArray.forEach(updateTag);
      updateArray.forEach(updateCallSign);
      updateArray.forEach(updateResource);

      // do any of the updates necessitate a change in filtering?
      let updateNeeded = false;
      const existingFilteredInIdsByType = state$.value.live.filteredInIdsByType;
      const newFilteredInIdsByType = {};
      Object.keys(existingFilteredInIdsByType).forEach(
        (type) =>
          (newFilteredInIdsByType[type] = {
            ...existingFilteredInIdsByType[type],
          }),
      );

      // for each of the update types, check if filtering has changed for any item
      Object.keys(updates).forEach((type) => {
        Object.keys(updates[type]).forEach((id) => {
          // get the searchText, filters and advancedFilters for this type e.g. vehicles
          const [searchText, filters, advancedFilters, customFilter] =
            searchAndFiltersForType(type, state$.value.live);

          // if vehicles have some search or filters set, check if this update for
          // a certain resource changes things, if so, an update is needed
          if (
            someFilteringApplied(
              searchText,
              filters,
              advancedFilters,
              customFilter,
            )
          ) {
            if (!(type in newFilteredInIdsByType)) {
              newFilteredInIdsByType[type] = {};
            }

            const item = updates[type][id];
            const isFilteredIn =
              applySearch(item, searchText?.toLowerCase()) &&
              // applyExtendedFilters(item, filters) &&
              applyAdvancedFilters(item, advancedFilters) &&
              (!customFilter ||
                customFilter(item, existingFilteredInIdsByType));
            const alreadyFilteredIn = item.id in newFilteredInIdsByType[type];

            if (isFilteredIn !== alreadyFilteredIn) {
              if (isFilteredIn) {
                newFilteredInIdsByType[type][item.id] = true;
              } else {
                delete newFilteredInIdsByType[type][item.id];
              }
              updateNeeded = true;
            }
          }
        });
      });

      // if something that was filtered in is now deleted, it should be removed
      // from the filteredInIdsByType
      Object.keys(existingFilteredInIdsByType).forEach((type) => {
        Object.keys(existingFilteredInIdsByType[type]).forEach((id) => {
          if (!(updates[type] || state$.value.live[type])[id]) {
            if (!(type in newFilteredInIdsByType)) {
              newFilteredInIdsByType[type] = existingFilteredInIdsByType[type];
            }

            delete newFilteredInIdsByType[type][id];
            updateNeeded = true;
          }
        });
      });

      // Object.keys(newFilteredInIdsByType).forEach(type => {
      //   if (Object.keys(newFilteredInIdsByType[type]).length
      //     > Object.keys(updates[type]).length) {
      //       debugger;
      //     }
      // })

      return {
        type: UPDATE_LIVE_RESOURCES,
        payload: {
          ...updates,
          filteredInIdsByType: updateNeeded
            ? newFilteredInIdsByType
            : existingFilteredInIdsByType,
        },
      };
    }),
  );

// internal actions just used by socket & epic
const SOCKET_SUBSCRIBED = 'SOCKET_SUBSCRIBED';
const SOCKET_POLL_RECEIVED = 'SOCKET_POLL_RECEIVED';
const SOCKET_POLL_RECONNECT = 'SOCKET_POLL_RECONNECT';
function getLiveObservableSocket(subscriptionTypes, locationTypes, state$) {
  // console.log("getting observable for ", subscription);
  return new Observable((observer) => {
    let socket = null;
    // wrapped so we can try to reconnect on error
    function connect() {
      socket = new WebSocket(wsRootUrl);

      const subscriptions = Object.fromEntries(
        Object.entries(getLiveSubscription(locationTypes)).filter(([type]) =>
          subscriptionTypes.includes(type),
        ),
      );

      const user = getUser();
      const inputParams = {
        action: 'SUBSCRIBE',
        authorization: `Bearer ${user?.access_token}`,
        payload: subscriptions,
      };

      socket.onerror = (e) => {
        // observer.error(e);
        console.error(e);

        observer.next({
          type: SOCKET_POLL_RECONNECT,
          payload: 'ERROR attempting reconnect...',
        });

        setTimeout(connect, 1000);
      };

      let firstMessage = true;
      socket.onmessage = (poll) => {
        try {
          if (firstMessage) {
            firstMessage = false;
            observer.next({
              type: SOCKET_SUBSCRIBED,
            });
          }

          const data = JSON.parse(poll.data);

          if (data.action === 'ERROR') {
            observer.error({ message: data.payload });
          } else {
            observer.next({
              type: SOCKET_POLL_RECEIVED,
              payload: data.payload,
            });
          }
        } catch (e) {
          observer.error(e);
        }
      };

      socket.onopen = () => {
        socket.send(JSON.stringify(inputParams));
      };
    }
    connect();

    // for objectives the active status can change on the hour & isn't server originated
    function updateObjectiveIfStatusChanged(objective) {
      // for testing: }, testInt) {
      const prevStatus = objective.status || 'inactive';
      // const newStatus = (testInt % 2) === 0 ? 'active' : 'inactive';
      const newStatus = objectiveIsActive(objective) ? 'active' : 'inactive';
      if (prevStatus !== newStatus) {
        observer.next({
          type: SOCKET_POLL_RECEIVED,
          payload: {
            $type: 'change',
            ...objective,
            status: newStatus,
          },
        });
      }
    }
    const now = new Date();
    // 3601 so it happens just after the hour changes
    let secondsToNextHour = 3601 - getSeconds(now) - 60 * getMinutes(now);
    if (secondsToNextHour < 1) {
      secondsToNextHour = 3601;
    }
    timer(secondsToNextHour * 1000, 3600 * 1000).subscribe((i) => {
      // for testing: timer(10000, 5000).subscribe((i) => {
      // console.log('timer', i);
      let objectives = state$.value.live['objectives'] || {};
      Object.values(objectives).forEach((objective) => {
        updateObjectiveIfStatusChanged(objective, i);
      });
    });

    // on Edge the connection can be "terminated abnormally" so peridically
    // send a ping to the server
    // interval(60000).subscribe(() => socket.send('ping'));

    // for testing simulate a moving vehicle with changing features
    if (dummyMovingVehicle) {
      interval(3000).subscribe((i) => {
        const lastPollTime = new Date().toISOString();
        for (let j = 0; j < dummyMovingVehicle; j++) {
          const unknownDriver = {};
          const unrecognisedDriver = {
            identificationMethod: 'rfid',
            identificationReference: `RFID${j}`,
          };
          let driver = {
            groups: { team: ['B'] },
            category: 'Constable',
            class: 'Driver',
            code: `EMP${j}`,
            collarNumber: `CN${j}`,
            forenames: `${String.fromCharCode(65 + j)}`,
            identificationCategory: 'Driver',
            identificationMethod: 'rfid',
            identificationReference: `RFID${j}`,
            identificationTime: lastPollTime,
            role: 'Driver role',
            skills: [
              { name: 'J turns', type: 'skill' },
              { name: 'Ramps', type: 'skill' },
            ],
            surname: `O'${String.fromCharCode(65 + j)}`,
          };

          switch (j % 5) {
            case 1:
              driver = unknownDriver;
              break;
            case 2:
              driver = unrecognisedDriver;
              break;
            default:
              break;
          }

          let incident = null;
          let callSign = j % 5 === 1 && {
            code: `CS${~~(i / 3)}`,
            category: 'Test',
            status: 'Unseen',
          };
          // every 4th one will be assigned an incident
          if (j % 4 === 0) {
            switch (j) {
              case 0:
                // the very first one will only be assigned 3/5 of the time
                if (i % 5 < 3) {
                  incident = {
                    number: '000202-02122020',
                  };
                }

                break;
              case 4:
                // the 4th one will be assigned a new incident every 3 seconds
                incident = {
                  number: 'NEW_INC-' + ~~(i / 3),
                };
                callSign = {
                  code: 'NEWCS',
                  category: 'En Route To Incident',
                };
                break;
              default:
                incident = {
                  number: '000202-02122020',
                };
                callSign = {
                  code: 'CS-ASGND',
                  category: 'En Route To Incident',
                };

                break;
            }
          }

          const identificationNumber = `V${j}`;
          const ignitionOn = (i * j) % 2 === 0;
          const angle = 2 * Math.PI * (i / 100 + j / dummyMovingVehicle);
          observer.next({
            type: SOCKET_POLL_RECEIVED,
            payload: {
              vehicles: {
                [identificationNumber]: {
                  $type: 'change', //(j%3 === 0 && i % 3 === 0) ? 'delete': 'change',
                  lastPollTime,
                  identificationNumber,
                  fleetNumber: `F${j}`,
                  registrationNumber: `JLT EST${j}`,
                  position: {
                    coordinates: [
                      -1.6267 + 2 * Math.cos(angle),
                      52.6638 + Math.sin(angle),
                    ],
                    type: 'Point',
                  },
                  ignitionOn,
                  headingDegrees: 360 - (((180 * angle) / Math.PI) % 360),
                  speedKilometresPerHour: 80 + i + j,
                  searchString: `V${j} F${j} JLT EST${j}`,
                  malfunctionIndicatorLightOn: j === 0,
                  beaconsOn: j === 5,
                  assignments: {
                    incident,
                    callSign,
                  },
                  driver: ignitionOn ? driver : undefined,
                  lastDriver: ignitionOn ? undefined : driver,
                  // tags: ['what', 'if', 'these', 'update', ...(Array(i%3).fill('dyn%2Fo'))]
                },
              },
            },
          });
        }
      });
    }
    // end of test simulation

    return () => {
      socket.close();
    };
  });
}

function addProps(terms, i, obj) {
  if (obj) {
    for (let k in obj) {
      if (typeof obj[k] === 'string') {
        // add any string properties
        // take out any tags to remove <img src='data:.......>
        const string = obj[k].replace(/<[^>]*>?/gm, ' ');
        terms[i++] = string;
      } else if (typeof obj[k] === 'boolean' && obj[k]) {
        // add booleans iff they are true
        terms[i++] = k;
      }
    }
  }

  return i;
}

function addPropsFromArray(terms, i, array, accessor) {
  if (array && array.length !== 0) {
    if (!accessor) {
      for (let j = 0; j < array.length; j++) {
        terms[i++] = array[j];
      }
    } else {
      for (let j = 0; j < array.length; j++) {
        terms[i++] = array[j][accessor];
      }
    }
  }

  return i;
}

function generateSearchString(type, data) {
  let terms = [];
  let i = 0;
  const resource = data.payload;

  // add their basic props (no objects), nearly all have areas and tags
  i = addProps(terms, i, resource);
  i = addPropsFromArray(terms, i, resource.tags);
  switch (type) {
    case 'vehicles':
      i = addProps(terms, i, resource.reducedCurrentLocations);
      i = addPropsFromArray(terms, i, resource.driverSkillsArray);
      i = addProps(terms, i, resource.assignments?.callSign);
      i = addProps(terms, i, resource.assignments?.incident);
      if (resource.driverName) {
        terms[i++] = resource.driverName;
      }
      break;
    case 'people':
      i = addPropsFromArray(terms, i, resource.skillsArray);
      i = addPropsFromArray(terms, i, resource.driverKeysArray);
      i = addProps(terms, i, resource.reducedCurrentLocations);
      i = addProps(terms, i, resource.assignments?.callSign);
      i = addProps(terms, i, resource.assignments?.incident);
      if (resource.name) {
        terms[i++] = resource.name;
      }
      break;
    case 'objectives':
    case 'events':
      i = addProps(terms, i, resource.vehicle);
      i = addPropsFromArray(terms, i, resource.locations, 'name');
      break;
    default:
      break;
  }

  return terms.join('+').toLowerCase();
}

export function liveStreamsEpic(action$, state$) {
  return action$.pipe(
    ofType(START_LIVE_STREAM),
    switchMap(({ payload: { subscriptionTypes, locationTypes } }) =>
      getLiveObservableSocket(subscriptionTypes, locationTypes, state$).pipe(
        mergeMap((message) => {
          switch (message.type) {
            case SOCKET_SUBSCRIBED:
              return [
                {
                  type: START_LIVE_STREAM_SUCCESS,
                  payload: subscriptionTypes,
                },
              ];
            case SOCKET_POLL_RECEIVED: {
              const updates = [];
              Object.keys(message.payload).forEach((type) => {
                Object.keys(message.payload[type]).forEach((id) => {
                  const update = message.payload[type][id];
                  // TODO this might have changed so message.action has "UPDATE_RESOURCES"
                  const actionType = update
                    ? UPDATE_LIVE_RESOURCE
                    : DELETE_LIVE_RESOURCE;

                  updates.push({
                    type: actionType,
                    payload: update || { id },
                    resourceType: type,
                  });
                });
              });

              return updates;

              // const actionType = socketToAction[message.payload.$type];
              // if (!actionType) {
              //   return {
              //     type: LIVE_STREAM_WARNING,
              //     payload: `Unknown actionType from socket: ${message.payload.$type}`,
              //   };
              // }

              // return {
              //   type: actionType,
              //   payload: message.payload,
              //   resourceType: subscription.type,
              // };
            }
            case SOCKET_POLL_RECONNECT:
              return [
                {
                  type: LIVE_STREAM_WARNING,
                  payload: message.payload,
                },
              ];
            default:
              // shouldn't happen but warning if no default
              return [
                {
                  type: LIVE_STREAM_WARNING,
                  payload: 'Unknown message from socket',
                },
              ];
          }
        }),
        takeUntil(action$.pipe(ofType(END_LIVE_STREAM))),
        catchError(({ message: payload }) =>
          of({
            type: START_LIVE_STREAM_FAILURE,
            payload,
          }),
        ),
        endWith({
          type: END_LIVE_STREAM_SUCCESS,
          payload: subscriptionTypes,
        }),
      ),
    ), // end switchMap
  ); // end pipe
}

// tags
async function addTagToEntity(tag, type, id) {
  const response = await api
    .put(`tags/${type}/${encodeURIComponent(id)}/${encodeURIComponent(tag)}`)
    .json();

  return response;
}

async function deleteTagFromEntity(tag, type, id) {
  const response = await api
    .delete(`tags/${type}/${encodeURIComponent(id)}/${encodeURIComponent(tag)}`)
    .json();

  return response;
}

export function addTagEpic(action$) {
  return action$.pipe(
    ofType(ADD_LIVE_TAG),
    mergeMap(({ payload: { tag, type, id } }) =>
      from(addTagToEntity(tag, type, id)).pipe(
        map((payload) => ({
          type: ADD_LIVE_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message }) =>
          of({
            type: ADD_LIVE_TAG_FAILURE,
            payload: {
              op: 'adding',
              message,
              tag,
              type,
              id,
            },
          }),
        ),
      ),
    ),
  );
}

export function deleteTagEpic(action$) {
  return action$.pipe(
    ofType(DELETE_LIVE_TAG),
    mergeMap(({ payload: { tag, type, id } }) =>
      from(deleteTagFromEntity(tag, type, id)).pipe(
        map((payload) => ({
          type: DELETE_LIVE_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message }) =>
          of({
            type: DELETE_LIVE_TAG_FAILURE,
            payload: {
              op: 'deleting',
              message,
              tag,
              type,
              id,
            },
          }),
        ),
      ),
    ),
  );
}

// end tags

// 2.0 version
async function fetchIncidentRequest(id) {
  const response = await api
    .get(`incidents/${id}`, {
      searchParams: encodeParams({
        projection: {
          number: true,
          description: true,
          type: true,
          category: true,
          grade: true,
          point: true,
          openedTime: true,
          assignedTime: true,
          attendedTime: true,
          closedTime: true,
          status: true,
          closingCodes: true,
          tags: true,
          tagChanged: true,
          responseCategory: true,
          reference: true,
          date: true,
          locations: true,
          groups: true,
          ward: true,
        },
      }),
    })
    .json();

  const incident = { id, ...response };
  return incident;
}

export function fetchIncidentEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_INCIDENT),
    mergeMap(({ payload: { id } }) =>
      from(fetchIncidentRequest(id)).pipe(
        mergeMap((payload) =>
          of(
            {
              type: FETCH_LIVE_INCIDENT_SUCCESS,
              payload,
            },
            {
              type: ADD_LIVE_RESOURCE,
              resourceType: 'incidents',
              payload,
            },
          ),
        ),
        catchError(({ message }) =>
          of({
            type: FETCH_LIVE_INCIDENT_FAILURE,
            payload: {
              number: id,
              id,
              unavailable: true,
              message,
            },
          }),
        ),
      ),
    ),
  );
}

async function fetchAccelPollRequest(accel) {
  // e.g. 354725063606044-2020-12-01T10:29:24.000Z
  const id = `${accel.vehicle?.telematicsBoxImei ?? ''}-${accel.time}`;
  const response = await api
    .get(`telematicsBoxPolls/${id}`, {
      searchParams: encodeParams({
        projection: {
          speedKilometresPerHour: true,
          headingDegrees: true,
          emergencyEquipmentOn: true,
          ...Object.fromEntries(Object.keys(dioStates).map((k) => [k, true])),
        },
      }),
    })
    .json();

  return response;
}

export function fetchAccelPollEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_ACCEL_POLL),
    mergeMap(({ payload: event }) =>
      from(fetchAccelPollRequest(event)).pipe(
        mergeMap((poll) =>
          of(
            {
              type: FETCH_LIVE_ACCEL_POLL_SUCCESS,
              payload: {
                ...event,
                poll: poll ?? {},
              },
            },
            {
              type: ADD_LIVE_RESOURCE,
              resourceType: 'accelerometerAlerts',
              payload: {
                ...event,
                poll: poll ?? {},
              },
            },
          ),
        ),
        catchError(({ message }) =>
          of({
            type: FETCH_LIVE_ACCEL_POLL_FAILURE,
            payload: {
              ...event,
              error: true,
              message,
              poll: {},
            },
          }),
        ),
      ),
    ),
  );
}

async function fetchIncidentETARequest(vehicle, incident) {
  const position = vehicle?.position?.coordinates;
  const location = incident?.point?.coordinates;
  const from = [...position].reverse().join(',');
  const to = [...location].reverse().join(',');
  const callSign = vehicle.assignments?.callSign?.code;
  const endPoint = `${nominatimRootUrl}/route?point=${from}&point=${to}&type=json`;

  try {
    const response = await ky.get(endPoint).json();

    const time = response.paths[0].time;
    const eta = time
      ? format(addMilliseconds(new Date(), time), 'HH:mm:ss')
      : undefined;

    return { vin: vehicle.identificationNumber, eta, time, callSign };
  } catch (_) {
    return {
      vin: vehicle.identificationNumber,
      eta: '-',
      time: undefined,
      callSign,
    };
  }
}

// fetch estimated time epic
export function fetchIncidentETA(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL),
    mergeMap(({ payload: { vehicle, incident } }) =>
      from(fetchIncidentETARequest(vehicle, incident)).pipe(
        mergeMap((payload) =>
          of({
            type: FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_SUCCESS,
            payload,
          }),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LIVE_ESTIMATED_TIME_OF_ARRIVAL_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function fetchLiveViewsRequest() {
  const projection = {
    identifier: true,
    title: true,
    application: true,
    user: true,
    global: true,
    filters: true,
    tab: true,
    layerVisibilities: true,
    sorts: true,
    showLabels: true,
  };

  const user = getUser();
  const userQuery = {
    application: { $eq: 'Live' },
    user: { $eq: user?.profile?.unique_name },
  };

  const globalQuery = {
    application: { $eq: 'Live' },
    global: { $eq: true },
  };

  const [userFilters, globalFilters] = await Promise.all([
    api
      .get('filters', {
        searchParams: encodeParams({ projection, query: userQuery }),
      })
      .json(),
    api
      .get('filters', {
        searchParams: encodeParams({ projection, query: globalQuery }),
      })
      .json(),
  ]);

  // there may be some overlap so stitch them together
  return _.uniqBy(userFilters.concat(globalFilters), (f) => f.identifier);
}

export function fetchLiveViews(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_VIEWS),
    mergeMap(() =>
      from(fetchLiveViewsRequest()).pipe(
        mergeMap((payload) =>
          of({
            type: FETCH_LIVE_VIEWS_SUCCESS,
            payload,
          }),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LIVE_VIEWS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function saveLiveViewRequest(liveView) {
  const user = getUser();
  liveView.user = user?.profile?.unique_name;
  liveView.application = 'Live';
  if (liveView.identifier) {
    await api.put(`filters/${liveView.identifier}`, { json: liveView });
  } else {
    // need to get a new id
    liveView.identifier = await getNewIdentifier('filters');
    await api.post('filters', { json: liveView });
  }

  return liveView;
}

export function saveLiveView(action$) {
  return action$.pipe(
    ofType(ADD_LIVE_VIEW),
    mergeMap(({ payload: liveView }) =>
      from(saveLiveViewRequest(liveView)).pipe(
        // mergeMap((payload) =>
        //   of({
        //     type: ADD_LIVE_VIEW_SUCCESS,
        //     payload,
        //   })
        // ),
        mergeMap(() =>
          from(fetchLiveViewsRequest()).pipe(
            mergeMap((payload) =>
              of(
                {
                  type: FETCH_LIVE_VIEWS_SUCCESS,
                  payload,
                },
                {
                  type: ADD_LIVE_VIEW_SUCCESS,
                  liveView,
                },
              ),
            ),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: ADD_LIVE_VIEW_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function deleteLiveViewRequest(liveView) {
  if (liveView.identifier) {
    await api.delete(`filters/${liveView.identifier}`);
  }
}

export function deleteLiveView(action$) {
  return action$.pipe(
    ofType(DELETE_LIVE_VIEW),
    mergeMap(({ payload: liveView }) =>
      from(deleteLiveViewRequest(liveView)).pipe(
        mergeMap(() =>
          from(fetchLiveViewsRequest()).pipe(
            mergeMap((payload) =>
              of(
                {
                  type: FETCH_LIVE_VIEWS_SUCCESS,
                  payload,
                },
                {
                  type: DELETE_LIVE_VIEW_SUCCESS,
                  liveView,
                },
              ),
            ),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_LIVE_VIEW_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

// to avoid null payload in FETCH_..._SUCCESS
// use the same request but live will never
// ask for FETCH_...('new') so won't get a
// SUCCESS with a null payload
export function fetchLocationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_LOCATION),
    mergeMap(({ payload: id }) =>
      from(fetchLocationRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_LIVE_LOCATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LIVE_LOCATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchPersonEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_PERSON),
    mergeMap(({ payload: id }) =>
      from(fetchPersonRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_LIVE_PERSON_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LIVE_PERSON_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchVehicleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_LIVE_VEHICLE),
    mergeMap(({ payload: id }) =>
      from(fetchVehicleRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_LIVE_VEHICLE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_LIVE_VEHICLE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}
// END to avoid null payload in FETCH_..._SUCCESS
