import { useDallasKeys } from '@/utils/config';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router';
import {
  Audit,
  DataRetention,
  Engagement,
  GroupOptions,
  LegacyAudit,
  LocationOptions,
  Options,
  PersonOptions,
  RadioOptions,
  RfidCardOptions,
  TelematicsBoxOptions,
  VehicleOptions,
} from './admin';
import { App } from './App';
import { Brief, Briefs } from './briefing';
import { ErrorBoundary } from './ErrorBoundary';
import {
  AccelerometerEvents,
  DoubleCrews,
  DrivingTelemetry,
  Idles,
  IncidentResponses,
  MalfunctionIndicatorLight,
  OnBoardDiagnostics,
  Outages,
  PersonBoundaryVisits,
  PersonLocationVisits,
  PersonObjectiveAttendances,
  SpeedInfractions,
  Stops,
  Trails,
  Trip,
  Trips,
  VehicleBoundaryVisits,
  VehicleEquipmentActivations,
  VehicleLocationVisits,
} from './events';
import { Group, Groups } from './groups';
import {
  BasicCommandUnit,
  Fleet,
  Highlights,
  Sector,
  Team,
} from './Highlights';
import { Home } from './Home';
import { Live } from './live';
import { Location, Locations } from './locations';
import { Objective, Objectives } from './objectives';
import { ProtectedRoute } from './ProtectedRoute';
import { EventReplay, Replay } from './replays';
import {
  DailyActivity,
  DailyUtilisation,
  DriverBehaviour,
  FleetUtilisation,
  HourlyActivity,
  HourlyInLocations,
  HourlyUtilisation,
  LastContact,
  Mileage,
  PersonAvailability,
  PersonHeatMap,
  TimelineInLocations,
  VehicleAvailability,
  VehicleHeatMap,
} from './reports';
import {
  People,
  Person,
  Radio,
  Radios,
  RfidCard,
  RfidCards,
  TelematicsBox,
  TelematicsBoxes,
  TelematicsBoxTest,
  Vehicle,
  Vehicles,
} from './resources';
import { Retrospective } from './retrospective';
import { Tag, Tags } from './tags';
import { MapProvider } from 'react-map-gl/maplibre';

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} errorElement={<ErrorBoundary />}>
          <Route index element={<Home />} />
          <Route
            path="live/:type?/:id?/:subId?"
            element={<ProtectedRoute component={Live} />}
          />
          <Route path="briefs">
            <Route index element={<ProtectedRoute component={Briefs} />} />
            <Route
              path=":code"
              element={<ProtectedRoute component={Brief} />}
            />
          </Route>
          <Route
            path="retrospective/:id?/:layerIndex?/:itemIndex?/:subItemIndex?"
            element={<ProtectedRoute component={Retrospective} />}
          />
          <Route
            path="highlights"
            element={<ProtectedRoute component={Highlights} />}
          >
            <Route
              index
              element={
                <Navigate replace={true} to="/highlights/basiccommandunit" />
              }
            />
            <Route path="basiccommandunit" element={<BasicCommandUnit />} />
            <Route path="sector" element={<Sector />} />
            <Route path="team" element={<Team />} />
            <Route path="fleet" element={<Fleet />} />
          </Route>
          <Route
            path="eventreplay/:type/:id"
            element={<ProtectedRoute component={EventReplay} />}
          />
          <Route
            path="replay"
            element={<ProtectedRoute component={Replay} />}
          />
          <Route path="tags" element={<ProtectedRoute component={Tags} />}>
            <Route path=":id" element={<Tag />} />
          </Route>
          <Route
            path="objectives"
            element={<ProtectedRoute component={Objectives} />}
          >
            <Route path=":id" element={<Objective />} />
          </Route>
          <Route path="groups" element={<ProtectedRoute component={Groups} />}>
            <Route path=":code" element={<Group />} />
          </Route>
          <Route path="locations">
            <Route
              path=":locationType?"
              element={<ProtectedRoute component={Locations} />}
            >
              <Route path=":id" element={<Location />} />
            </Route>
          </Route>
          <Route path="resources">
            <Route
              path="vehicles"
              element={<ProtectedRoute component={Vehicles} />}
            >
              <Route path=":id" element={<Vehicle />} />
            </Route>
            <Route
              path="people"
              element={<ProtectedRoute component={People} />}
            >
              <Route path=":id" element={<Person />} />
            </Route>
            <Route
              path="telematicsboxes"
              element={<ProtectedRoute component={TelematicsBoxes} />}
            >
              <Route path=":id" element={<TelematicsBox />} />
              <Route path=":id/test" element={<TelematicsBoxTest />} />
            </Route>
            <Route
              path="radios"
              element={<ProtectedRoute component={Radios} />}
            >
              <Route path=":id" element={<Radio />} />
            </Route>
            <Route
              path={`${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`}
              element={<ProtectedRoute component={RfidCards} />}
            >
              <Route path=":id" element={<RfidCard />} />
            </Route>
          </Route>
          <Route path="reports">
            <Route path="vehicles">
              <Route
                path="dailyutilisation"
                element={<ProtectedRoute component={DailyUtilisation} />}
              />
              <Route
                path="hourlyutilisation"
                element={<ProtectedRoute component={HourlyUtilisation} />}
              />
              <Route
                path="hourlyinlocations"
                element={<ProtectedRoute component={HourlyInLocations} />}
              />
              <Route
                path="timelineinlocations"
                element={<ProtectedRoute component={TimelineInLocations} />}
              />
              <Route
                path="mileage"
                element={<ProtectedRoute component={Mileage} />}
              />
              <Route
                path="availability"
                element={<ProtectedRoute component={VehicleAvailability} />}
              />
              <Route
                path="fleetutilisation/*"
                element={<ProtectedRoute component={FleetUtilisation} />}
              />
              <Route
                path="heat"
                element={
                  <MapProvider>
                    <ProtectedRoute component={VehicleHeatMap} />
                  </MapProvider>
                }
              />
            </Route>
            <Route path="people">
              <Route
                path="dailyactivity"
                element={<ProtectedRoute component={DailyActivity} />}
              />
              <Route
                path="hourlyactivity"
                element={<ProtectedRoute component={HourlyActivity} />}
              />
              <Route
                path="availability"
                element={<ProtectedRoute component={PersonAvailability} />}
              />
              <Route
                path="driverbehaviour/:year?/:month?/:day?"
                element={<ProtectedRoute component={DriverBehaviour} />}
              />
              <Route
                path="heat"
                element={
                  <MapProvider>
                    <ProtectedRoute component={PersonHeatMap} />
                  </MapProvider>
                }
              />
            </Route>
            <Route path="telematicsboxes">
              <Route
                path="lastcontact"
                element={<ProtectedRoute component={LastContact} />}
              />
            </Route>
          </Route>
          <Route path="events">
            <Route path="vehicles">
              <Route path="trips">
                <Route index element={<ProtectedRoute component={Trips} />} />
                <Route
                  path=":id"
                  element={<ProtectedRoute component={Trip} />}
                />
              </Route>
              <Route
                path="stops"
                element={<ProtectedRoute component={Stops} />}
              />
              <Route
                path="idles"
                element={<ProtectedRoute component={Idles} />}
              />
              <Route
                path="speedinfractions"
                element={<ProtectedRoute component={SpeedInfractions} />}
              />
              <Route
                path="accelerometerevents"
                element={<ProtectedRoute component={AccelerometerEvents} />}
              />
              <Route
                path="locationvisits"
                element={<ProtectedRoute component={VehicleLocationVisits} />}
              />
              <Route
                path="boundaryvisits"
                element={<ProtectedRoute component={VehicleBoundaryVisits} />}
              />
              <Route
                path="equipmentactivations"
                element={
                  <ProtectedRoute component={VehicleEquipmentActivations} />
                }
              />
              <Route
                path="malfunctionindicatorlight"
                element={
                  <ProtectedRoute component={MalfunctionIndicatorLight} />
                }
              />
              <Route
                path="onboarddiagnostics"
                element={<ProtectedRoute component={OnBoardDiagnostics} />}
              />
              <Route
                path="drivingtelemetry"
                element={<ProtectedRoute component={DrivingTelemetry} />}
              />
            </Route>
            <Route path="people">
              <Route
                path="trails"
                element={<ProtectedRoute component={Trails} />}
              />
              <Route
                path="locationvisits"
                element={<ProtectedRoute component={PersonLocationVisits} />}
              />
              <Route
                path="boundaryvisits"
                element={<ProtectedRoute component={PersonBoundaryVisits} />}
              />
              <Route
                path="doublecrews"
                element={<ProtectedRoute component={DoubleCrews} />}
              />
              <Route
                path="attendances"
                element={
                  <ProtectedRoute component={PersonObjectiveAttendances} />
                }
              />
              <Route
                path="responses"
                element={<ProtectedRoute component={IncidentResponses} />}
              />
            </Route>
            <Route path="telematicsboxes">
              <Route
                path="outages"
                element={<ProtectedRoute component={Outages} />}
              />
            </Route>
          </Route>
          <Route path="admin">
            <Route
              path="audit"
              element={<ProtectedRoute component={Audit} />}
            />
            <Route
              path="legacyaudit"
              element={<ProtectedRoute component={LegacyAudit} />}
            />
            <Route
              path="retention"
              element={<ProtectedRoute component={DataRetention} />}
            />
            <Route
              path="engagement"
              element={<ProtectedRoute component={Engagement} />}
            />
            <Route
              path="options"
              element={<ProtectedRoute component={Options} />}
            >
              <Route
                index
                element={<Navigate replace={true} to="/admin/options/people" />}
              />
              <Route path="people" element={<PersonOptions />} />
              <Route path="vehicles" element={<VehicleOptions />} />
              <Route path="locations" element={<LocationOptions />} />
              <Route path="groups" element={<GroupOptions />} />
              <Route
                path="telematicsboxes"
                element={<TelematicsBoxOptions />}
              />
              <Route path="radios" element={<RadioOptions />} />
              <Route
                path={`${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`}
                element={<RfidCardOptions />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
