import { peopleStateAtom } from '@/atoms';
import { NavLink, SearchBox, SortField } from '@/components/controls';
import {
  useDocumentTitle,
  useGroups,
  useOptionLookup,
  usePeople,
  useUserInfo,
} from '@/hooks';
import {
  downloadCSV,
  downloadJSON,
  get,
  getFilenameForDownload,
  getPeople,
  getPeopleAndHeaders,
  isAuthorised,
  sortFn,
} from '@/utils';
import { isFleet, useDallasKeys } from '@/utils/config';
import {
  Add as AddIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format, isBefore } from 'date-fns';
import { useAtom } from 'jotai';
import { useMemo, useRef } from 'react';
import { Link, Outlet, useParams } from 'react-router';

export function People() {
  useDocumentTitle('IR3 | People');
  const { id } = useParams();
  const [{ searchText, sortBy, sortDesc, filter, showSettings }, setState] =
    useAtom(peopleStateAtom);
  const { data: people } = usePeople();
  const { data: groups } = useGroups();
  const typeOptions = useOptionLookup('groupType');
  const parentRef = useRef();
  const { data: userInfo } = useUserInfo();
  const canCreate = isAuthorised(userInfo?.authorisation, 'people', true);

  const validPastDate = '2099-01-01';
  const sortOptions = [
    { label: 'Payroll Number', value: 'code' },
    { label: 'Name', value: 'surname' },
    { label: 'Collar Number', value: 'collarNumber' },
    ...(isFleet ? [] : [{ label: 'Last Poll Time', value: 'lastPollTime' }]),
  ];

  const filterOptions = {
    rfid: [
      { label: 'None', value: 'none' },
      { label: 'Multiple', value: 'multiple' },
    ],
    radio: [{ label: 'None', value: 'none' }],
    leaver: [
      { label: 'Hide', value: false },
      { label: 'Show', value: true },
    ],
  };

  const filteredList = useMemo(() => {
    return people
      .filter(
        (item) =>
          !filter.ancestor ||
          item.groupAncestorCodes.includes(filter.ancestor.value),
      )
      .filter(
        (item) =>
          item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
          searchText === '',
      )
      .filter(
        (item) =>
          !filter.rfid ||
          (filter.rfid === 'none' && (item.rfidCards || []).length === 0) ||
          (filter.rfid === 'multiple' && (item.rfidCards || []).length > 1),
      )
      .filter(
        (item) => !filter.radio || (filter.radio === 'none' && !item.radioSsi),
      )
      .filter(
        (item) =>
          filter.leaver ||
          !isBefore(new Date(item.leavingDate ?? validPastDate), new Date()),
      )
      .sort(sortFn(sortBy, sortDesc));
  }, [filter, people, searchText, sortBy, sortDesc]);
  const ancestors = useMemo(
    () =>
      groups
        .map((group) => ({
          label: group.name,
          value: group.code,
          type: typeOptions[group.type] ?? '',
        }))
        .sort(
          (a, b) =>
            a.type.localeCompare(b.type) || a.label.localeCompare(b.label),
        ),
    [groups, typeOptions],
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSearchChange(event) {
    setState((state) => ({
      ...state,
      searchText: event.target.value,
    }));
  }

  function handleSettingsToggle() {
    setState((state) => ({
      ...state,
      showSettings: !state.showSettings,
    }));
  }

  function handleSortByChange(event) {
    setState((state) => ({
      ...state,
      sortBy: event.target.value,
    }));
  }

  function handleSortToggle() {
    setState((state) => ({
      ...state,
      sortDesc: !state.sortDesc,
    }));
  }

  const handleFilterChange = (field) => (event) => {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        [field]: event.target.value,
      },
    }));
  };

  function handleAncestorFilterChange(event, option) {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        ancestor: option,
      },
    }));
  }

  function getFilteredDataForDownload(data) {
    const filteredData = data.filter((o1) =>
      filteredList.some((o2) => o1.code === o2.code),
    );

    return filteredData;
  }

  async function handleCsvClick() {
    const data = await getPeopleAndHeaders();
    const filteredData = getFilteredDataForDownload(data.people);
    const filename = getFilenameForDownload('people', 'csv');
    downloadCSV(filteredData, filename, data.headers);
  }

  async function handleJsonClick() {
    const data = await getPeople();
    const filteredData = getFilteredDataForDownload(data.people);
    const filename = getFilenameForDownload('people', 'json');
    downloadJSON(filteredData, filename);
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              count={`${filteredList.length}/${
                people.filter(
                  (item) =>
                    filter.leaver ||
                    !isBefore(
                      new Date(item.leavingDate ?? validPastDate),
                      new Date(),
                    ),
                ).length
              }`}
              sx={{ flexGrow: 1 }}
            />
            <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
              <IconButton onClick={handleSettingsToggle} size="small">
                <SettingsIcon
                  fontSize="inherit"
                  color={showSettings ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
            {canCreate && (
              <Tooltip title="Add new">
                <IconButton component={Link} to="new" size="small">
                  <Avatar
                    sx={{
                      color: 'secondary.contrastText',
                      backgroundColor: 'secondary.main',
                      width: 24,
                      height: 24,
                      fontSize: 16,
                    }}
                  >
                    <AddIcon fontSize="inherit" />
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Collapse in={showSettings} unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1}>
              <SortField
                sortBy={sortBy}
                onSortByChange={handleSortByChange}
                sortDesc={sortDesc}
                onSortDescToggle={handleSortToggle}
                options={sortOptions}
              />
            </Stack>
            <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Filters
                </Typography>
              </Divider>
              <TextField
                size="small"
                fullWidth
                select
                label={useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
                value={filter.rfid}
                onChange={handleFilterChange('rfid')}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {filterOptions.rfid.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Radio"
                value={filter.radio}
                onChange={handleFilterChange('radio')}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {filterOptions.radio.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Leavers"
                value={filter.leaver}
                onChange={handleFilterChange('leaver')}
              >
                {filterOptions.leaver.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                value={filter.ancestor}
                onChange={handleAncestorFilterChange}
                options={ancestors}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option?.label ?? option}
                sx={{ minWidth: 200 }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Group | Area" />
                )}
              />
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Export
                </Typography>
              </Divider>
              <Stack
                direction="row"
                // sx={{ p: 1 }}
                spacing={1}
                sx={{ justifyContent: 'center' }}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleCsvClick}
                >
                  CSV
                </Button>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleJsonClick}
                >
                  JSON
                </Button>
              </Stack>
            </Stack>
          </Collapse>
        </Box>
        <Divider />
        <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
              const item = filteredList[index];

              function getSecondaryText() {
                if (!get(item, sortBy)) {
                  return '';
                }

                switch (sortBy) {
                  case 'surname':
                  case 'code':
                    return item.code;
                  case 'collarNumber':
                    return item.collarNumber;
                  case 'lastPollTime':
                    return format(
                      new Date(item.lastPollTime),
                      'dd/MM/yyyy HH:mm:ss',
                    );
                  default:
                    return get(item, sortBy);
                }
              }

              return (
                <ListItemButton
                  dense
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                  component={NavLink}
                  to={`/resources/people/${encodeURIComponent(item.code)}`}
                  selected={id === item.code}
                >
                  <ListItemAvatar>
                    <Avatar src={item.picture}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${item.forenames} ${item.surname}`}
                    secondary={getSecondaryText()}
                    primaryTypographyProps={{ noWrap: true }}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
