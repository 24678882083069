import { useDocumentTitle } from '@/hooks';
import { Box } from '@mui/material';
import { Outlet } from 'react-router';

export function Highlights() {
  useDocumentTitle('IR3 | Highlights');

  return (
    <Box sx={{ height: 'calc(100vh - 96px)', overflow: 'scroll' }}>
      <Outlet />
    </Box>
  );
}
