import { resizeBase64Image } from '@/utils';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import { Upload as UploadIcon } from 'mdi-material-ui';
import { enqueueSnackbar } from 'notistack';
import { useController } from 'react-hook-form';
import { useFilePicker } from 'use-file-picker';
import { FileSizeValidator } from 'use-file-picker/validators';

const errorLookup = {
  FILE_SIZE_TOO_LARGE: 'File size is too large',
};

export function ImageUploadController({ name, control, disabled, alt, sx }) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  function handleFilesRejected({ errors }) {
    captureException(
      new Error(errorLookup[errors[0].reason] ?? errors[0].reason),
    );
    enqueueSnackbar(errorLookup[errors[0].reason] ?? errors[0].reason, {
      variant: 'error',
    });
  }

  function handleFilesSuccessfullySelected({ filesContent }) {
    resizeBase64Image(filesContent[0].content, 1600, 900).then(onChange);
  }

  const { openFilePicker, loading } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    validators: [
      // new FileAmountLimitValidator({ max: 1 }),
      // new FileTypeValidator(['jpg', 'png']),
      new FileSizeValidator({ maxFileSize: 1024 * 1024 * 50 }),
      // new ImageDimensionsValidator({
      //   maxHeight: 900, // in pixels
      //   maxWidth: 1600,
      //   minHeight: 600,
      //   minWidth: 768,
      // }),
    ],
    onFilesRejected: handleFilesRejected,
    onFilesSuccessfullySelected: handleFilesSuccessfullySelected,
  });

  function handleUploadClick() {
    if (!disabled) {
      openFilePicker();
    }
  }

  return (
    <Tooltip title="Click to upload image" arrow>
      <Box
        onClick={handleUploadClick}
        sx={{
          display: 'flex',
          position: 'relative',
          overflow: 'hidden',
          width: 1,
          height: 320,
          bgcolor: 'background.paper',
          borderStyle: 'solid',
          borderColor: 'divider',
          borderWidth: 1,
          borderRadius: 1,
          '&:hover': {
            borderColor: 'action.active',
            bgcolor: 'action.hover',
          },
          ...sx,
        }}
      >
        {value ? (
          <img
            src={value}
            alt={alt}
            style={{ width: '100%', height: 320, objectFit: 'cover' }}
          />
        ) : (
          <UploadIcon
            color="action"
            sx={{
              fontSize: 32,
              alignSelf: 'center',
              textAlign: 'center',
              flex: 1,
            }}
          />
        )}
        {loading && (
          <CircularProgress
            style={{ position: 'absolute', top: 8, right: 8 }}
            size={16}
            color="primary"
          />
        )}
      </Box>
    </Tooltip>
  );
}
