// bug with select and WebGLMap: https://github.com/openlayers/openlayers/issues/9688
// not going to be fixed apparently
// import { Select as SelectInteraction } from 'ol/interaction';

import {
  Label as LabelIcon,
  LabelOff as LabelOffIcon,
  Layers as LayersIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FormatListBulletedType as LegendIcon } from 'mdi-material-ui';
// import { MapLegend as LegendIcon } from 'mdi-material-ui';
import {
  UPDATE_LIVE_LAYER_VISIBILITIES,
  UPDATE_LIVE_SETTINGS,
  UPDATE_LIVE_STATUS,
} from '@/actions';
import {
  GazetteerSearch,
  MapButton,
  MapLayersList,
} from '@/components/controls';
import '@/css/ol-centred-attribution.css';
import { useLocationTypes, usePrevious } from '@/hooks';
import {
  debounce,
  get,
  getGeometryCollectionFromMicrobeats,
  maxBy,
  noop,
} from '@/utils';
import {
  mapLayers as baseMapLayers,
  liveOptions,
  mapExtent,
} from '@/utils/config';
import {
  mapGlyphsByTypeAndSubtype,
  statusZIndexByType,
} from '@/utils/constants';
import {
  getBaseLayers,
  getLiveStyle,
  preloadLiveIcons,
} from '@/utils/mapStyles';
import { dequal } from 'dequal';
import { Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { applyTransform, createEmpty, extend, isEmpty } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { Point, Polygon } from 'ol/geom';
import { defaults as defaultInteractions } from 'ol/interaction';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Cluster as ClusterSource, Vector as VectorSource } from 'ol/source';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LiveLegend } from './LiveLegend';
import {
  getTypeIcons,
  labelPaths,
  listComponentsByType,
  pluralToSingularTypeMap,
  singularToPluralTypeMap,
} from './constants';

const { labelOverridePaths, mapClusterDistance, disableRotatedIconsOnMobile } =
  liveOptions;

const layerZIndexOrder = [
  'objectives',
  'locations',
  'vehicles',
  'people',
  'events',
  'incidents',
];

const isFilteredIn = (id, group, filteredInIdsByType) => {
  return (
    !(group in filteredInIdsByType) || filteredInIdsByType[group][id] === true
  );
};

function safeRemoveFeature(source, id) {
  const feature = source.getFeatureById(id);
  if (feature) {
    source.removeFeature(feature);
  }
}

function isShown(layerVisibilities, type) {
  if (type === 'stale') {
    return layerVisibilities[type];
  }

  // show layer if it's not specified either way or if it set to true
  return !(type in layerVisibilities) || layerVisibilities[type];
}

const STALE = 'stale';
const FILTERED = 'filtered';

const ICON_ZINDEX = 20;
const POLY_ZINDEX = 5;
const STALE_ZINDEX = 10;
const FILTERED_ZINDEX = 0;

function statusToZIndex(status, type) {
  const ffs = statusZIndexByType;
  const zIndex = ffs[pluralToSingularTypeMap[type]]?.[status];
  if (zIndex) {
    return zIndex;
  }

  // default if not above
  switch (status) {
    case 'emergency':
    case 'malfunctioning':
      return 10;
    case 'assigned':
    case 'warning equipment':
      return 7;
    case 'default':
      return 2;
    case 'inactive':
    case 'parked':
    case 'closed':
      return 1;
    case 'stale':
      return 0;
    default:
      return 2;
  }
}

const staleable = ['vehicles', 'people'];
const polygonable = ['locations', 'objectives'];

// lower layers are stale vehicles and people or polygons
function shouldGoInLower(feature) {
  const { type, status } = feature.getProperties();
  const pluralType =
    type in singularToPluralTypeMap ? singularToPluralTypeMap[type] : type;

  return (
    polygonable.includes(pluralType) ||
    (staleable.includes(pluralType) && status === STALE)
  );
}

// upper layers are every icon except stale vehicles and people
function shouldGoInUpper(feature) {
  const { type, status } = feature.getProperties();
  const pluralType =
    type in singularToPluralTypeMap ? singularToPluralTypeMap[type] : type;

  return !(staleable.includes(pluralType) && status === STALE);
}

// JL use all-type filter instead
// toFeature(element, group, filterType, filterIds) {
const toFeatureObj = (
  element,
  group,
  filteredInIdsByType,
  followedIdsByType,
  filteredInIdsByTypeOverride,
) => {
  if (!element) {
    return null;
  }

  const filteredIn = isFilteredIn(element.id, group, filteredInIdsByType);
  const followed = followedIdsByType[group]?.[element.id];
  const focused = filteredInIdsByTypeOverride?.[group]?.[element.id];
  const interiorPoint = element.boundary
    ? new Polygon(element.boundary.coordinates).getInteriorPoint()
    : null;
  const boundaryWithPoint =
    element.boundaryType === 'Microbeats'
      ? getGeometryCollectionFromMicrobeats(element.microbeats || [])
      : !interiorPoint
        ? undefined
        : {
            type: 'GeometryCollection',
            geometries: [
              {
                type: 'Point',
                coordinates: interiorPoint.getCoordinates(),
              },
              element.boundary,
            ],
          };
  // const { coordinates } = (interiorPoint || element.position || { coordinates: null});
  const clusterPoint =
    interiorPoint ||
    (element.position && new Point(element.position.coordinates)) ||
    (element.point && new Point(element.point.coordinates)) ||
    (element.lastPosition && new Point(element.lastPosition.coordinates));

  if (clusterPoint) {
    clusterPoint.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
  }
  const status = element.status; //(element, group);
  const zIndex = followed ? 10 : statusToZIndex(status, group);
  const labelOverride =
    labelOverridePaths?.[group] && get(element, labelOverridePaths[group]);

  const commonProperties = {
    clusterPoint,
    status,
    zIndex,
    type: pluralToSingularTypeMap[group],
    route: `/live/${group}/${encodeURIComponent(element.id)}`,
    filteredIn,
    followed,
    focused,
    label:
      (typeof labelOverride === 'string' && labelOverride) ||
      get(element, labelPaths[group]),
  };

  switch (group) {
    case 'vehicles':
      return element.position
        ? {
            geometry: element.position,
            id: element.id,
            type: 'Feature',
            properties: {
              ...commonProperties,
              headingDegrees: element.headingDegrees,
              ignitionOn: element.ignitionOn,
              registrationNumber: element.registrationNumber,
              active: element.ignitionOn,
              subtype: element.type,
            },
          }
        : null;
    case 'people':
      return element.position
        ? {
            geometry: element.position,
            id: element.code,
            type: 'Feature',
            properties: {
              ...commonProperties,
              collarNumber: element.collarNumber,
            },
          }
        : null;
    case 'events':
      return {
        geometry: element.point,
        id: element.id,
        type: 'Feature',
        properties: {
          ...commonProperties,
          code: element.vehicle.registrationNumber, // doesn't seem to have //element.code,
        },
      };
    case 'incidents':
      return {
        geometry: element.point,
        id: element.id,
        type: 'Feature',
        properties: {
          ...commonProperties,
          number: element.number, // doesn't seem to have //element.code,
          subtype: mapGlyphsByTypeAndSubtype.incident?.[element.grade]
            ? element.grade
            : 'default',
        },
      };
    case 'objectives':
      return {
        geometry: boundaryWithPoint,
        id: element.id,
        type: 'Feature',
        properties: {
          ...commonProperties,
          subtype: element.type,
        },
      };
    case 'locations':
      // make locations have a geometry collection
      return {
        geometry: boundaryWithPoint,
        id: element.id,
        type: 'Feature',
        properties: {
          ...commonProperties,
          name: element.name,
          subtype: element.type,
        },
      };
    case 'radios':
      return {
        geometry: element.lastPosition,
        id: element.ssi,
        type: 'Feature',
        properties: {
          ...commonProperties,
        },
      };
    case 'telematicsBoxes':
      return {
        geometry: element.lastPosition,
        id: element.imei,
        type: 'Feature',
        properties: {
          ...commonProperties,
        },
      };
    // ...
    default:
      return null;
  }
};

// there are a lot of params but this is to avoid writing the function in twice
// due to useEffect deps
function upsertMapFeature({
  features,
  type,
  id,
  itemSources,
  itemSourcesLower,
  itemSourcesFilteredOut,
  getFeatureByTypeId,
  filteredInIdsByType,
  followedIdsByType,
  filteredInIdsByTypeOverride,
}) {
  let existing = getFeatureByTypeId(type, id);
  let currentlyIn = [];
  if (existing) {
    currentlyIn = [
      itemSources,
      itemSourcesLower,
      itemSourcesFilteredOut,
    ].filter((source) => source.current[type].hasFeature(existing));
  }

  // if it's not there now, the update was a delete, remove from map
  const resource = features[type][id];
  if (!resource) {
    currentlyIn.forEach((source) =>
      source.current[type].removeFeature(existing),
    );
    return;
  }

  var featureObj = toFeatureObj(
    resource,
    type,
    filteredInIdsByType,
    followedIdsByType,
    filteredInIdsByTypeOverride,
  );
  if (!featureObj) {
    return;
  }

  var updated = new GeoJSON().readFeature(featureObj, {
    featureProjection: 'EPSG:3857',
  });

  // the feature can go in upper (icons), lower (stale/polygon) or filtered out sources
  let shouldGoIn = [];
  if (isFilteredIn(id, type, filteredInIdsByType)) {
    if (shouldGoInUpper(updated)) {
      shouldGoIn.push(itemSources);
    }

    if (shouldGoInLower(updated)) {
      shouldGoIn.push(itemSourcesLower);
    }
  } else {
    shouldGoIn.push(itemSourcesFilteredOut);
  }

  if (existing) {
    existing.setGeometry(updated.getGeometry());
    existing.setProperties(updated.getProperties());
  } else {
    shouldGoIn.forEach((source) => source.current[type].addFeature(updated));
  }
}

export function LiveMap({
  authorisedTypes,
  hoveredItem,
  onHover,
  selectedItem,
  onSelect,
}) {
  const { type = 'vehicles', id: encodedId, subId: encodedSubId } = useParams();
  const id = encodedId && decodeURIComponent(encodedId);
  const subId = encodedSubId && decodeURIComponent(encodedSubId);
  const idRef = useRef(id);
  idRef.current = id;

  const [zoomDisabled, setZoomDisabled] = useState({ in: false, out: false });
  const [layerToggles, setLayerToggles] = useState({});
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [layerAnchorEl, setLayerAnchorEl] = useState(null);
  const [legendAnchorEl, setLegendAnchorEl] = useState(null);
  const [mapStyle, setMapStyle] = useState();
  const [gazetteerResults, setGazetteerResults] = useState([]);

  const mapDiv = useRef(null);
  const mapRef = useRef(null);
  const view = mapRef.current && mapRef.current.getView();
  const itemSources = useRef({});
  const itemSourcesLower = useRef({});
  const itemSourcesFilteredOut = useRef({});
  const itemLayers = useRef([]);
  const itemLayersLower = useRef([]);
  const itemLayersFilteredOut = useRef([]);
  const hoverSource = useRef({});
  const selectSource = useRef({});
  const showLabels = useRef(false);

  const mapLayers =
    mapRef.current
      ?.getLayers()
      ?.getArray()
      ?.filter(
        (layer) => layer instanceof TileLayer || layer instanceof ImageLayer,
      ) || [];

  const vehicles = useSelector((state) => state.live.vehicles);
  const people = useSelector((state) => state.live.people);
  const locations = useSelector((state) => state.live.locations);
  const events = useSelector((state) => state.live.events);
  const incidents = useSelector((state) => state.live.incidents);
  const callSigns = useSelector((state) => state.live.callSigns);
  const objectives = useSelector((state) => state.live.objectives);
  const tags = useSelector((state) => state.live.tags);
  const radios = useSelector((state) => state.live.radios);
  const telematicsBoxes = useSelector((state) => state.live.telematicsBoxes);
  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities,
  );
  const followedIdsByType = useSelector(
    (state) =>
      state.live.followedIdsByTypeOverride || state.live.followedIdsByType,
  );
  const prevFollowedIdsByType = usePrevious(followedIdsByType);
  const filteredInIdsByTypeOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride,
  );
  const prevFilteredInIdsByTypeOverride = usePrevious(
    filteredInIdsByTypeOverride,
  );
  const filteredInIdsByType = useSelector(
    (state) =>
      state.live.filteredInIdsByTypeOverride || state.live.filteredInIdsByType,
  );
  const prevFilteredInIdsByType = usePrevious(filteredInIdsByType);

  const showStale = isShown(layerVisibilities, STALE);
  const showStaleRef = useRef();
  showStaleRef.current = showStale;

  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const allowRotations = !(disableRotatedIconsOnMobile && isMobile);
  const fitOptions = { maxZoom: 16, duration: 750, padding: [30, 30, 30, 30] };
  const fitOptionsMobile = {
    ...fitOptions,
    padding: [30, 30, isMobile ? 400 : 30, 30],
  };

  const settings = useSelector((state) => state.live.settings);
  const prevSettings = usePrevious(settings);
  useEffect(() => {
    if (settings && settings !== prevSettings) {
      if (settings.showLabels !== prevSettings?.showLabels) {
        showLabels.current = settings.showLabels;
        if (mapRef.current) {
          mapRef.current.getLayers().forEach((layer) => layer.changed());
        }
        setRefreshToggle(!refreshToggle);
      }
    }
  }, [settings, prevSettings, setRefreshToggle, refreshToggle]);
  const { data: locationTypes } = useLocationTypes();

  function changeSettings(settings) {
    dispatch({
      type: UPDATE_LIVE_SETTINGS,
      payload: settings,
    });
  }

  const features = useMemo(
    () => ({
      vehicles,
      people,
      locations,
      events,
      incidents,
      callSigns,
      objectives,
      radios,
      telematicsBoxes,
    }),
    [
      vehicles,
      people,
      locations,
      events,
      incidents,
      callSigns,
      objectives,
      radios,
      telematicsBoxes,
    ],
  );

  const prevFeatures = usePrevious(features);
  const featuresUpdated = prevFeatures !== features;
  const updatedIds = useMemo(() => {
    let updatedIds = {};

    Object.keys(features).forEach((key) => {
      const prev = (prevFeatures || {})[key] || {};
      const curr = features[key];
      //let thisChanged = false;

      // how many have changed?
      // updated ones weren't in the previous features or changed
      const updated = Object.keys(curr).filter((k) => prev[k] !== curr[k]);
      // deleted ones are previous ones that aren't in the current features
      const deleted = Object.keys(prev).filter((k) => !curr[k]);

      if (updated.length || deleted.length) {
        updatedIds[key] = updated.concat(deleted);
      }

      // if (thisChanged) console.log("updated Ids changed...", key);
    });

    return updatedIds;
  }, [features, prevFeatures]);

  // checks filtered in source and filtered out source
  const getFeatureByTypeId = useCallback((type, id) => {
    if (id) {
      if (type in singularToPluralTypeMap) {
        type = singularToPluralTypeMap[type];
      }

      const tryGetFrom = (sources) => {
        if (type in sources.current) {
          return sources.current[type].getFeatureById(id);
        }

        return false;
      };

      let target = tryGetFrom(itemSources);
      if (target) {
        return target;
      }
      target = tryGetFrom(itemSourcesLower);
      if (target) {
        return target;
      }

      target = tryGetFrom(itemSourcesFilteredOut);
      return target;
    }

    return null;
  }, []);

  // used when setting a source to have a single feature (select and hover layers)
  const setSourceFeature = useCallback(
    (type, id, source) => {
      const feature = getFeatureByTypeId(type, id);
      if (feature) {
        source.clear();
        source.addFeature(feature);
      }

      return feature;
    },
    [getFeatureByTypeId],
  );

  // get whatever feature was clicked
  const featureFromEvent = (event) => {
    const pixel = mapRef.current.getEventPixel(event.originalEvent);
    const features = mapRef.current.getFeaturesAtPixel(pixel);

    if (!features?.length) {
      return null;
    }

    // otherwise return the top one
    // if it's a cluster, return the one with the highest z index
    const topFeature = features[0];
    if (topFeature.get('features')) {
      return maxBy(
        topFeature.get('features'),
        (f) => f.getProperties().zIndex || 0,
      );
    }

    return topFeature;
  };

  const handleHover = (event) => {
    if (!event.dragging) {
      //} && !selectedItem.id) {
      const feature = featureFromEvent(event);

      if (feature) {
        const individualType = feature.getProperties().type;
        const type = singularToPluralTypeMap[individualType];
        const newHoveredItem = {
          id: feature.getId(),
          type,
        };

        if (!dequal(newHoveredItem, hoveredItem)) {
          hoverSource.current.clear();
          hoverSource.current.addFeature(feature);
          onHover(newHoveredItem);
        }
      } else {
        hoverSource.current.clear();
        if (hoveredItem.id) {
          // if there was one, clear it now
          onHover({});
        }
      }
    }
  };

  const debouncedHover = debounce(handleHover, 150);

  function getClusterPoint(feature) {
    return feature.getProperties().clusterPoint;
  }

  // use a current ref so don't have to reregister map clicks every
  // time parent handler changes
  const onSelectRef = useRef(onSelect);
  onSelectRef.current = onSelect;

  // set up map on startup
  useEffect(
    () => {
      if (mapRef.current !== null) {
        return;
      }
      // console.log("set up map once!");

      const getDefaultStyle = (feature) => {
        return getLiveStyle({
          layer: 'default',
          feature,
          showLabels: showLabels.current,
          zoom: mapRef.current.getView().getZoom(),
          allowRotations,
          showStale: showStaleRef.current,
        });
      };

      const getIconsOnlyStyle = (feature) => {
        return getLiveStyle({
          layer: 'default',
          feature,
          showLabels: showLabels.current,
          zoom: mapRef.current.getView().getZoom(),
          iconsOnly: true,
          allowRotations,
          showStale: showStaleRef.current,
        });
      };

      const getPolygonsOnlyStyle = (feature) => {
        return getLiveStyle({
          layer: 'default',
          feature,
          showLabels: showLabels.current,
          zoom: mapRef.current.getView().getZoom(),
          polygonsOnly: true,
          polygonIsSelected: idRef.current === feature.getId(),
          allowRotations,
          showStale: showStaleRef.current,
        });
      };

      const getSelectStyle = (feature) => {
        return getLiveStyle({
          layer: 'select',
          feature,
          showLabels: showLabels.current,
          zoom: mapRef.current.getView().getZoom(),
          allowRotations,
          showStale: showStaleRef.current,
        });
      };

      const getHoverStyle = (feature) => {
        return getLiveStyle({
          layer: 'hover',
          feature,
          showLabels: showLabels.current,
          zoom: mapRef.current.getView().getZoom(),
          allowRotations,
          showStale: showStaleRef.current,
        });
      };

      function isShown(type) {
        if (type === 'stale') {
          return layerVisibilities[type];
        }

        // show layer if it's not in the state or use the state value
        return !(type in layerVisibilities) || layerVisibilities[type];
      }

      // build this up then set once
      const layerToggles = {};
      const clusterDistance = mapClusterDistance || 0;

      const initSourceAndLayer = (
        sourceArray,
        layerArray,
        type,
        opacity,
        zIndex,
        style,
        show,
        shouldCluster,
      ) => {
        sourceArray[type] = new VectorSource({ features: [] });

        const layer = new VectorLayer({
          title: type,
          source:
            clusterDistance > 0 && shouldCluster
              ? new ClusterSource({
                  distance: clusterDistance,
                  source: sourceArray[type],
                  geometryFunction: getClusterPoint,
                })
              : sourceArray[type],
          style: style || getDefaultStyle,
          renderMode: 'image',
          opacity: opacity,
          visible: show,
        });

        // I want to control when changed happens, otherwise changing a single
        // features geometry or properties or removing it causes a rerender
        // so create a new "changeComplete" method I can call when needed
        // and override changed so it does nothing
        sourceArray[type].changeComplete = sourceArray[type].changed;
        sourceArray[type].changed = noop;

        layer.setZIndex(zIndex + layerZIndexOrder.indexOf(type));

        layerArray.push(layer);

        if (!(type in layerToggles)) {
          layerToggles[type] = { show, layers: [] };
        }
        layerToggles[type].layers.push(layer);
      };

      // earlier types e.g. vehicles and officers are so numerous
      // they are obscuring the more important icons such as incidents
      // this makes it so the order of features determines the z index
      Object.values(singularToPluralTypeMap).forEach((type) => {
        const polygonLayer = polygonable.includes(type);

        // the top layer for icons
        initSourceAndLayer(
          itemSources.current,
          itemLayers.current,
          type,
          1,
          ICON_ZINDEX,
          getIconsOnlyStyle,
          isShown(type),
          !polygonLayer, // don't cluster if it's a polygon type
        );

        // the lower layer for stale icons or polygons (so icons are always on top)
        // stale vehicles/officers don't use polygons so they have a higher z-index
        // than polygons (but lower than non-stale) and use the icons only style.
        // types that have polygon areas have a lower z-index so they are beneath
        // non-stale and stale icons
        initSourceAndLayer(
          itemSourcesLower.current,
          itemLayersLower.current,
          type,
          1,
          polygonLayer ? POLY_ZINDEX : STALE_ZINDEX,
          polygonLayer ? getPolygonsOnlyStyle : getIconsOnlyStyle,
          // if not a polygon layer, it can have an additional pred if stale layers are hidden
          polygonLayer ? isShown(type) : isShown(type) && isShown(STALE),
          !polygonLayer, // don't cluster if it's a polygon type
        );

        // the filtered out layer - has the lowest z-index of all, everything is above it
        initSourceAndLayer(
          itemSourcesFilteredOut.current,
          itemLayersFilteredOut.current,
          type,
          0.3,
          FILTERED_ZINDEX,
          getDefaultStyle,
          // filtered layers can have an additonal pred if filtered layers are hidden
          isShown(type) && isShown(FILTERED),
          true,
        );
      });

      // these are the special layerToggles which apply to one or more layers
      layerToggles[STALE] = { show: isShown(STALE) };
      layerToggles[FILTERED] = { show: isShown(FILTERED) };

      setLayerToggles(layerToggles);

      hoverSource.current = new VectorSource();
      selectSource.current = new VectorSource();

      let lastView = undefined;

      try {
        const lastViewSetting = window.localStorage.getItem(LIVE_MAP_VIEW_KEY);
        const lastViewOptions =
          !!lastViewSetting && JSON.parse(lastViewSetting);

        if (lastViewOptions) {
          lastView = new View(lastViewOptions);
        }
      } catch (e) {
        lastView = undefined;
        console.warn('Could not load map view', e);
      }

      const baseLayers = getBaseLayers(baseMapLayers);

      mapRef.current = new Map({
        target: mapDiv.current,
        layers: [
          ...baseLayers,
          ...itemLayersFilteredOut.current,
          ...itemLayersLower.current,
          ...itemLayers.current,
        ],
        interactions: defaultInteractions({
          pinchRotate: false,
          altShiftDragRotate: false,
        }),
        view:
          lastView ||
          new View({
            center: [0, 0],
            zoom: 2,
          }),
        controls: defaultControls({
          attribution: true,
          attributionOptions: { collabsible: false, collapsed: false },
          rotate: false,
          zoom: false,
        }),
      });

      // these are "unmanaged" layers and always appear on top
      const hoverLayer = new VectorLayer({
        source: hoverSource.current,
        style: getHoverStyle,
      });
      const selectLayer = new VectorLayer({
        source: selectSource.current,
        style: getSelectStyle,
      });
      hoverLayer.setMap(mapRef.current);
      selectLayer.setMap(mapRef.current);

      // debouncing hover so it's not called so often (any time pointer
      // moves) & slows map
      mapRef.current.on('pointermove', debouncedHover);

      function selectFeatureOnMap(feature) {
        const { type: individualType, route } = feature.getProperties();
        let type = singularToPluralTypeMap[individualType];
        let id = feature.getId();

        setSourceFeature(type, id, selectSource.current);

        return { type, id, route };
      }

      function handleClick(event) {
        hoverSource.current.clear();
        const feature = featureFromEvent(event);

        if (feature) {
          let item = selectFeatureOnMap(feature);

          // tell parent page it's selected
          // handleSelect(item);
          onSelectRef.current(item);
        } else {
          selectSource.current.clear();
          // handleSelect(null);
          onSelectRef.current(null);
        }

        // const oe = event.originalEvent;
        // const clonedEvent = new oe.constructor(oe.type, oe);
        // mapDiv.current.dispatchEvent(clonedEvent);
        mapDiv.current.focus();
      }

      mapRef.current.on('singleclick', handleClick);

      // when all the layers are ready (tile layers may need to load)
      // preload all the live icons
      function loadIconsWhenBaseLayersLoaded(e) {
        const someLoading = e.target
          .getLayerStatesArray()
          .some((s) => s.sourceState === 'loading');

        if (!someLoading) {
          const loadingFromCacheCallback = noop;
          const regeneratingCallback = () => {
            dispatch({
              type: UPDATE_LIVE_STATUS,
              payload: 'Need to regenerate icons, please wait...',
            });
          };
          const regenerationCompleteCallback = () => {
            dispatch({
              type: UPDATE_LIVE_STATUS,
              payload: 'Icon regeneration complete!',
            });
          };

          preloadLiveIcons({
            authorisedTypes: authorisedTypes.map(
              (type) => pluralToSingularTypeMap[type],
            ),
            allowRotations,
            loadingFromCacheCallback,
            regeneratingCallback,
            regenerationCompleteCallback,
          });
          e.target.un('change', loadIconsWhenBaseLayersLoaded);
        }
      }

      mapRef.current
        .getLayerGroup()
        .on('change', loadIconsWhenBaseLayersLoaded);

      if (!lastView) {
        // fit the map to the config's mapExtent as a default
        mapRef.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')),
          );
      }
    },
    [
      type,
      showLabels,
      debouncedHover,
      layerVisibilities,
      onSelect,
      setSourceFeature,
      authorisedTypes,
      allowRotations,
      dispatch,
    ], // useEffect deps
  );

  const LIVE_MAP_VIEW_KEY = 'liveMapView';
  function saveMapView(view) {
    try {
      window.localStorage.setItem(
        LIVE_MAP_VIEW_KEY,
        JSON.stringify({
          center: view.getCenter(),
          zoom: view.getZoom(),
        }),
      );
    } catch (e) {
      console.debug(e);
    }
  }

  const debouncedSaveMapView = debounce(saveMapView, 500);

  // any time the zoom disabled state changes, reregister the moveend callback
  // so we can check if we need to disable the buttons
  useEffect(() => {
    const handleMoveEnd = (e) => {
      const view = e.map.getView();
      const zoom = view.getZoom();
      const minZoom = view.getMinZoom();
      const maxZoom = view.getMaxZoom();

      const zoomInDisabled = zoom === maxZoom;
      const zoomOutDisabled = zoom === minZoom;
      if (
        zoomDisabled.in !== zoomInDisabled ||
        zoomDisabled.out !== zoomOutDisabled
      ) {
        setZoomDisabled({ in: zoomInDisabled, out: zoomOutDisabled });
      }

      // also (debounced) save the last position for reading later
      debouncedSaveMapView(view);
    };

    // any time it moves check if we need to disable zoom buttons
    mapRef.current.on('moveend', handleMoveEnd);

    return () => {
      mapRef.current.un('moveend', handleMoveEnd);
    };
  }, [zoomDisabled, debouncedSaveMapView]);

  // if an item is hovered add it to the hover source to highlight
  useEffect(() => {
    hoverSource.current.clear();
    const { type, id } = hoveredItem;
    if (id) {
      setSourceFeature(type, id, hoverSource.current);
    }
  }, [hoveredItem, setSourceFeature]);

  // if an item is first-selected, highlight it in the select source
  useEffect(() => {
    selectSource.current.clear();
    const { type, id } = selectedItem;
    if (id) {
      setSourceFeature(type, id, selectSource.current);
    }

    // refresh the map in case a polygon needs to be updated (selected colour)
    Object.keys(itemSourcesLower.current).forEach((type) =>
      itemSourcesLower.current[type].changeComplete(),
    );
  }, [selectedItem, setSourceFeature]);

  const [waitingForMatch, setWaitingForMatch] = useState(false);
  const prevId = usePrevious(id);
  const prevSubId = usePrevious(subId);
  useEffect(() => {
    // if the id has changed try to select the feature in the select source
    if (id && (id !== prevId || subId !== prevSubId)) {
      // console.log("trying to find nav'd id - when id changes");
      let target = setSourceFeature(type, subId || id, selectSource.current);
      if (target) {
        // JL TODO if autozoom is added this gets uncommented
        // // zoom in to it (if mobile pad bottom so it centres on top)
        // mapRef.current
        //   .getView()
        //   .fit(target.getGeometry().getExtent(), fitOptionsMobile);
      } else {
        // it hasn't loaded yet, keep a lookout for it
        setWaitingForMatch(true);
      }
    } else if (waitingForMatch && !id) {
      // if we were waiting for a match and there's no longer an id stop waiting
      setWaitingForMatch(false);
    }
  }, [type, id, setSourceFeature, prevId, waitingForMatch, subId, prevSubId]);

  // if there's no item selected clear the selected layer
  useEffect(() => {
    if (prevId && !id) {
      selectSource.current.clear();
    }
  }, [prevId, id, selectSource]);

  // // when an item arrives and it was selected, // don't zoom to it
  // const matchArrived = waitingForMatch && getFeatureByTypeId(type, id);
  // useEffect(() => {
  //   if (matchArrived) {
  //     // console.log("waiting for match");
  //     let target = getFeatureByTypeId(type, id);
  //     if (target) {
  //       // // console.log("got match");
  //       selectSource.current.clear();
  //       selectSource.current.addFeature(target);

  //       // // zoom in to it (if mobile pad bottom so it centres on top)
  //       // mapRef.current
  //       //   .getView()
  //       //   .fit(target.getGeometry().getExtent(), fitOptionsMobile);

  //       log('Read', type, id);

  //       setWaitingForMatch(false);
  //     }
  //   }
  // }, [
  //   type,
  //   id,
  //   setWaitingForMatch,
  //   matchArrived,
  //   getFeatureByTypeId,
  //   fitOptionsMobile,
  //   setSourceFeature,
  //   features
  // ]);

  // if the filtered ids change, check for differences and move just the ones that need it
  useEffect(() => {
    const filtersChanged =
      (prevFilteredInIdsByType || filteredInIdsByType) !== filteredInIdsByType;

    if (featuresUpdated || filtersChanged) {
      Object.keys(pluralToSingularTypeMap).forEach((type) => {
        let filteredInSource = itemSources.current[type];
        let filteredInSourceLower = itemSourcesLower.current[type];
        let filteredOutSource = itemSourcesFilteredOut.current[type];
        let needsRedraw = false;

        // add or update any features properties and location
        if (featuresUpdated && updatedIds[type]) {
          needsRedraw = true;
          updatedIds[type].forEach((id) => {
            upsertMapFeature({
              features,
              type,
              id,
              itemSources,
              itemSourcesLower,
              itemSourcesFilteredOut,
              getFeatureByTypeId,
              filteredInIdsByType,
              followedIdsByType,
              filteredInIdsByTypeOverride,
            });
          });

          // if we're waiting for an item to show up (e.g. nav'd to vehicle/V1) and the
          // item finally shows up, select it
          const targetId = subId || id;
          if (waitingForMatch && updatedIds[type].includes(targetId)) {
            const feature = toFeatureObj(
              features[type][targetId],
              type,
              filteredInIdsByType,
              followedIdsByType,
              filteredInIdsByTypeOverride,
            );

            if (feature) {
              const selectedFeature = new GeoJSON().readFeature(feature, {
                featureProjection: 'EPSG:3857',
              });
              selectSource.current.clear();
              selectSource.current.addFeature(selectedFeature);
              setWaitingForMatch(false);
            }
          }
        }

        // if the filters have changed we may need to move items to a different layer
        if (filtersChanged) {
          const all = features[type] || {};
          const prev = prevFilteredInIdsByType[type] || all;
          const curr = filteredInIdsByType[type] || all;

          // how many have changed?
          const nowFilteredIn = Object.keys(curr).filter((k) => !prev[k]);
          const nowFilteredOut = Object.keys(prev).filter((k) => !curr[k]);

          const totalChanges = nowFilteredIn.length + nowFilteredOut.length;
          if (totalChanges) {
            needsRedraw = true;
            // console.log(`${type} has ${nowFilteredIn.length} in and ${nowFilteredOut.length} out`);

            nowFilteredOut.forEach((id) => {
              const outcast =
                filteredInSource.getFeatureById(id) ||
                filteredInSourceLower.getFeatureById(id);

              if (outcast) {
                outcast.setProperties({
                  ...outcast.getProperties(),
                  filteredIn: false,
                  focused: false,
                });
                safeRemoveFeature(filteredInSource, id);
                safeRemoveFeature(filteredInSourceLower, id);

                filteredOutSource.addFeature(outcast);
              }
            });

            nowFilteredIn.forEach((id) => {
              const incast = filteredOutSource.getFeatureById(id);

              if (incast) {
                incast.setProperties({
                  ...incast.getProperties(),
                  filteredIn: true,
                  focused: !!filteredInIdsByTypeOverride?.[type]?.[id],
                });
                safeRemoveFeature(filteredOutSource, id);

                if (shouldGoInUpper(incast)) {
                  filteredInSource.addFeature(incast);
                }
                if (shouldGoInLower(incast)) {
                  filteredInSourceLower.addFeature(incast);
                }
              }
            });
          }

          if (
            filteredInIdsByTypeOverride?.[type] !==
            prevFilteredInIdsByTypeOverride?.[type]
          ) {
            needsRedraw = true;

            const updateFocus = (obj = {}, focused) => {
              Object.keys(obj).forEach((id) => {
                const feature = getFeatureByTypeId(type, id);

                if (feature) {
                  feature.setProperties({
                    ...feature.getProperties(),
                    focused,
                  });
                }
              });
            };

            if (filteredInIdsByTypeOverride?.[type]) {
              updateFocus(filteredInIdsByTypeOverride?.[type], true);
            } else {
              updateFocus(prevFilteredInIdsByTypeOverride?.[type], false);
            }
          }
        }

        if (needsRedraw) {
          filteredInSource.changeComplete();
          filteredInSourceLower.changeComplete();
          filteredOutSource.changeComplete();
        }
      });
    }
  }, [
    featuresUpdated,
    getFeatureByTypeId,
    updatedIds,
    features,
    filteredInIdsByType,
    prevFilteredInIdsByType,
    filteredInIdsByTypeOverride,
    prevFilteredInIdsByTypeOverride,
    id,
    subId,
    waitingForMatch,
    followedIdsByType,
  ]);

  // whenever type changes, make it the foremost layer
  useEffect(() => {
    function updateZIndex(layer, baseIndex) {
      const layerType = layer.get('title');
      layer.setZIndex(
        baseIndex +
          (layerType === type
            ? layerZIndexOrder.length
            : layerZIndexOrder.indexOf(layerType)),
      );
    }

    itemLayers.current.forEach((layer) => updateZIndex(layer, ICON_ZINDEX));
    itemLayersFilteredOut.current.forEach((layer) =>
      updateZIndex(layer, FILTERED_ZINDEX),
    );
  }, [type]);

  // whenever layerVisibilities change, make layers visible/invisible
  const prevLayerVisibilities = usePrevious(layerVisibilities);
  useEffect(() => {
    function isShown(type) {
      // show layer if it's not in the state or use the state value
      return !(type in layerVisibilities) || layerVisibilities[type];
    }

    if (
      Object.keys(layerToggles).length > 0 &&
      prevLayerVisibilities !== layerVisibilities
    ) {
      const showStale = isShown('stale');
      const showFiltered = isShown('filtered');

      Object.keys(layerToggles).forEach((type) => {
        let shown = isShown(type);
        layerToggles[type].show = shown;

        if (layerToggles[type].layers) {
          //layerToggles[type].layers.forEach(layer => layer.setVisible(shown));
          layerToggles[type].layers[0].setVisible(shown);
          const showLower = shown && (polygonable.includes(type) || showStale);
          layerToggles[type].layers[1].setVisible(showLower);
          layerToggles[type].layers[2].setVisible(shown && showFiltered);
        }
      });

      if (mapRef.current) {
        mapRef.current.getLayers().forEach((layer) => layer.changed());
      }

      setLayerToggles({ ...layerToggles });
    }
  }, [layerVisibilities, layerToggles, prevLayerVisibilities]);

  useEffect(() => {
    // follow every item in the followed list
    let extent = createEmpty();

    // if the list changed or at least one of the items moved, move the map to follow
    const someMoved = Object.keys(followedIdsByType).some((type) =>
      Object.keys(followedIdsByType[type]).some(
        (id) => type in updatedIds && updatedIds[type].includes(id),
      ),
    );

    if (
      (prevFollowedIdsByType || followedIdsByType) !== followedIdsByType ||
      someMoved
    ) {
      let typesThatNeedUpdating = {};

      // unfollow any that aren't followed now
      Object.keys(prevFollowedIdsByType ?? {}).forEach((type) => {
        Object.keys(prevFollowedIdsByType[type]).forEach((id) => {
          if (!followedIdsByType[type]?.[id]) {
            let feature = getFeatureByTypeId(type, id);

            if (feature) {
              typesThatNeedUpdating[type] = true;

              const props = feature.getProperties();
              feature.setProperties({
                ...props,
                followed: false,
                zIndex: statusToZIndex(props.status, type),
              });
            }
          }
        });
      });

      Object.keys(followedIdsByType).forEach((type) => {
        Object.keys(followedIdsByType[type]).forEach((id) => {
          let feature = getFeatureByTypeId(type, id);

          if (feature) {
            typesThatNeedUpdating[type] = true;

            // if the follow attribute isn't set (followed but hasn't moved), set it
            const props = feature.getProperties();
            if (!props.followed) {
              feature.setProperties({
                ...props,
                followed: true,
                zIndex: 10,
              });
            }

            let geom = feature.getGeometry();
            if (geom) {
              extend(extent, geom.getExtent());
            }
          }
        });
      });

      if (!isEmpty(extent)) {
        let view = mapRef.current.getView();
        let zoom = view.getZoom();
        view.fit(extent, {
          maxZoom: zoom,
          duration: 300,
          padding: [30, 30, 30, 30],
        });
      }

      Object.keys(typesThatNeedUpdating).forEach((type) => {
        itemSources.current[type]?.changeComplete();
        itemSourcesLower.current[type]?.changeComplete();
        itemSourcesFilteredOut.current[type]?.changeComplete();
      });
    }
  }, [
    followedIdsByType,
    updatedIds,
    getFeatureByTypeId,
    prevFollowedIdsByType,
  ]);

  function fitByIdForType(extent, dictionary, type) {
    Object.keys(dictionary[type]).forEach((id) => {
      const feature = getFeatureByTypeId(type, id);
      if (feature) {
        const geom = feature.getGeometry();
        if (geom) {
          extend(extent, geom.getExtent());
        }
      }
    });
  }

  function fitByIdForAllTypes(extent, dictionary) {
    Object.keys(dictionary).forEach((type) => {
      // normally we would only care about items in the top layer (itemSources)
      // but if an incident is selected the filters are overridden and luckily
      // contain all the ids of what we need to zoom in on
      fitByIdForType(extent, dictionary, type);
    });
  }

  // fit to everything that's filtered in...
  const fitMap = () => {
    let extent = createEmpty();
    let options = fitOptions;

    if (type === 'tags' || type === 'callSigns') {
      const groupType = type === 'tags' ? tags : callSigns;
      // fit to a tag's items
      // if we're just looking at a tag (has no features of its own) fit to its items
      if (id) {
        fitByIdForAllTypes(extent, groupType[id]?.itemsByType || {});
      } else {
        // this case doesn't really make sense but keeps it consistent with other types
        Object.keys(tags).forEach((id) =>
          fitByIdForAllTypes(extent, groupType[id]?.itemsByType || {}),
        );
      }
    } else if (
      // fit to an individual (unless it's the owner of a filter overide)
      id &&
      (!filteredInIdsByTypeOverride ||
        filteredInIdsByTypeOverride?.owner !== id)
    ) {
      // if there's an individual id selected zoom into that
      // unless it's the owner of an override filter
      let target = setSourceFeature(type, id, selectSource.current);
      if (target && target.getGeometry()) {
        // zoom in to it (if mobile pad bottom so it centres on top)
        extent = target.getGeometry().getExtent();
        // if id selected need to zoom to top half because of drawer
        options = fitOptionsMobile;
      }
    } else if (filteredInIdsByTypeOverride) {
      // if it's there's a filter override fit to all items of all types
      // e.g. an incident override might have vehicles, people and incident types
      fitByIdForAllTypes(extent, filteredInIdsByTypeOverride);
    } else if (filteredInIdsByType[type]) {
      // otherwise if the type is filtered fit to everything of that type
      fitByIdForType(extent, filteredInIdsByType, type);
    } else {
      // zoom to everything filtered in on only the selected type
      // so if you have filtered to two vehicles and click fit map
      // it doesn't zoom out to all the locations that are filtered in
      //Object.keys(itemSources.current).forEach(type => {
      let topFeatures = itemSources.current[type].getFeatures();

      // add in the stale items IF they are shown
      if (isShown(layerVisibilities, STALE)) {
        topFeatures = topFeatures.concat(
          itemSourcesLower.current[type]?.getFeatures() || [],
        );
      }

      topFeatures.forEach((feature) => {
        if (feature.getProperties().filteredIn) {
          let geom = feature.getGeometry();
          if (geom) {
            extend(extent, geom.getExtent());
          }
        }
      });
      // });
    }

    if (!isEmpty(extent)) {
      view.fit(extent, options);
    }
  };

  const zoom = (step) => {
    if (!view) {
      return;
    }

    const currentZoom = view.getZoom();
    const maxZoom = view.getMaxZoom();
    const minZoom = view.getMinZoom();
    const newZoom = currentZoom + step;

    // if the new one is ok, set the zoom
    if (minZoom <= newZoom && newZoom <= maxZoom) {
      view.setZoom(newZoom);
    }
  };

  function handleLayerMenuClick(event) {
    setLayerAnchorEl(event.currentTarget);
  }

  function handleLayerMenuClose() {
    setLayerAnchorEl(null);
  }

  function handleLegendOpen(event) {
    setLegendAnchorEl(event.currentTarget);
  }

  function handleLegendClose() {
    setLegendAnchorEl(null);
  }

  function fitMapToGazetteerSelection(selection) {
    if (selection.extent) {
      let extent = applyTransform(
        selection.extent,
        getTransform('EPSG:4326', 'EPSG:3857'),
      );
      view.fit(extent, fitOptions);
    }
  }

  function handleGazetteerChange(selection) {
    fitMapToGazetteerSelection(selection);

    if (selection.item?.id) {
      onSelect(selection.item);
    }
  }

  function handleGazetteerHighlightChange(selection) {
    fitMapToGazetteerSelection(selection);
  }

  function handleGazetteerInputChange(term) {
    if (term) {
      term = term.toLowerCase();

      let results = [];
      const max = 3;
      Object.keys(features).forEach((type) => {
        let count = 0;
        for (let id in features[type]) {
          const item = features[type][id];
          if (item.searchString?.includes(term)) {
            results.push({ type, item });
            count++;
          }

          if (count >= max) {
            break;
          }
        }
      });

      setGazetteerResults(results);
    }
  }

  function renderGazetteerItem({ type, item }) {
    const SpecificListItemComponent =
      listComponentsByType[type].listItemComponent;

    // refactoring to reduce duplication
    // - in the original, PlanListItem didn't have onClick={selectItem}
    return (
      SpecificListItemComponent && (
        <SpecificListItemComponent
          style={{ padding: 'unset' }}
          item={item}
          hideFollow={true}
        />
      )
    );
  }

  function itemToGazetteerChangeEvent({ item = {}, type }) {
    const geojson = item.position ?? item.point ?? item.boundary;

    try {
      if (geojson) {
        const feature = new GeoJSON().readFeature(geojson);
        return {
          extent: feature.getGeometry().getExtent(),
          item: { type, id: item.id },
        };
      }
    } catch (e) {
      console.debug(e);
    }

    if (item.id) {
      return { item: { type, id: item.id } };
    }

    return {};
  }

  let staleFilterDivider = false;

  return (
    <Box
      sx={{
        position: 'relative',
        width: 1,
        minWidth: 240,
        height: 1,
        color: 'background.default',
      }}
    >
      <Box
        id="map"
        sx={{ width: 1, height: 1 }}
        // onMouseOut={debouncedHover.cancel}
        ref={mapDiv}
      />
      <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
        <MapButton
          title="Zoom In"
          disabled={zoomDisabled.in}
          onClick={() => zoom(+1)}
        >
          <ZoomInIcon />
        </MapButton>
        <MapButton
          title="Zoom Out"
          disabled={zoomDisabled.out}
          onClick={() => zoom(-1)}
        >
          <ZoomOutIcon />
        </MapButton>
        <MapButton title="Fit" onClick={fitMap}>
          <ZoomOutMapIcon />
        </MapButton>
        <MapButton
          title="Labels"
          onClick={() => changeSettings({ showLabels: !showLabels.current })}
        >
          {showLabels.current ? <LabelIcon /> : <LabelOffIcon />}
        </MapButton>
      </Stack>
      <Box
        sx={{
          top: 8,
          right: 240,
          left: 240,
          minWidth: 240,
          position: 'absolute',
          justifyContent: 'center',
          // flexDirection: 'column',
          // backgroundColor: 'rgba(255,255,255,.8)',
        }}
      >
        <GazetteerSearch
          fullWidth
          onChange={handleGazetteerChange}
          onHighlightChange={handleGazetteerHighlightChange}
          onInputChange={handleGazetteerInputChange}
          customResults={gazetteerResults}
          customRender={renderGazetteerItem}
          customToChangeEvent={itemToGazetteerChangeEvent}
        />
      </Box>
      <Box sx={{ position: 'absolute', bottom: 8, left: 8 }}>
        <Menu
          open={Boolean(layerAnchorEl)}
          anchorEl={layerAnchorEl}
          onClose={handleLayerMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {Object.keys(layerToggles)
            .filter((type) =>
              authorisedTypes
                .filter((t) => !['tags', 'callSigns'].includes(t))
                .concat(STALE, FILTERED)
                .includes(type),
            )
            .map((type) => {
              const Icon = getTypeIcons(locationTypes)[type];
              let showDivider = false;
              if (!staleFilterDivider && ['stale', 'filtered'].includes(type)) {
                staleFilterDivider = true;
                showDivider = true;
              }

              return (
                <Box key={type}>
                  {showDivider && <Divider />}
                  <MenuItem
                    onClick={() => {
                      dispatch({
                        type: UPDATE_LIVE_LAYER_VISIBILITIES,
                        payload: {
                          ...layerVisibilities,
                          [type]: !(type in layerVisibilities
                            ? layerVisibilities[type]
                            : true),
                        },
                      });
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <Icon />
                    </ListItemIcon>
                    <Typography
                      variant="subtitle1"
                      sx={{ textTransform: 'capitalize', ml: 1 }}
                    >
                      {layerToggles[type].show ? 'Hide' : 'Show'} {type}
                    </Typography>

                    {/* <Fragment>
                      <Box sx={classes.diagonalContainer}>
                          <Icon />
                          {layerToggles[type].show || (
                            <Fragment>
                              <Box sx={classes.diagonalWhite}>|</Box>
                              <Box sx={classes.diagonal}>|</Box>
                            </Fragment>
                          )}
                      </Box>
                    </Fragment> 

                    <Box sx={{ textTransform: 'capitalize', ml: 1 }}>
                      {layerToggles[type].show ? 'Hide' : 'Show'} {type}
                    </Box>*/}
                  </MenuItem>
                </Box>
              );
            })}
          {mapLayers.length > 1 && (
            <Box>
              <Divider />
              <Box>
                <MapLayersList
                  mapRef={mapRef}
                  mapLayers={mapLayers}
                  mapStyle={mapStyle}
                  onMapStyleChange={setMapStyle}
                />
              </Box>
            </Box>
          )}
        </Menu>
        <MapButton onClick={handleLayerMenuClick} title="Layers">
          <LayersIcon />
        </MapButton>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          right: 8,
        }}
      >
        <Popover
          open={Boolean(legendAnchorEl)}
          anchorEl={legendAnchorEl}
          onClose={handleLegendClose}
          width="100%"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          PaperProps={{ sx: { maxHeight: '90%' } }}
        >
          <LiveLegend authorisedTypes={authorisedTypes} />
        </Popover>
        <MapButton
          sx={{ position: 'relative' }}
          onClick={handleLegendOpen}
          title="Legend"
        >
          <LegendIcon />
        </MapButton>
      </Box>
    </Box>
  );
}
