import { useDocumentTitle } from '@/hooks';
import { Box } from '@mui/material';
import { Outlet } from 'react-router';

export function Options() {
  useDocumentTitle('IR3 | Options');

  return (
    <Box sx={{ height: 'calc(100vh - 96px)' }}>
      <Outlet />
    </Box>
  );
}
