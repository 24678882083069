import { useBrief, useDocumentTitle, useSetting } from '@/hooks';
import { Box, Drawer, Step, StepButton, Stepper, Toolbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router';
import { Section } from './Section';

export function Brief() {
  const { code } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = searchParams.get('page');
  const { data: brief } = useBrief(code);
  useDocumentTitle(`IR3 | Briefs | ${brief?.title}`);
  const { data: sections } = useSetting('sections');
  const [completed, setCompleted] = useState([]);
  const includedSections = brief
    ? Object.entries(sections || {}).filter(
        ([key]) => !brief.excludedSections.includes(key),
      )
    : [];

  const handleStep = (page) => () => {
    setSearchParams({
      page,
    });
  };

  const handleComplete = (page) => () => {
    const incompletePages = [...Array(includedSections.length).keys()].filter(
      (p) => !completed.includes(p),
    );
    if (incompletePages.length === 1) {
      navigate('../');
    } else {
      setCompleted((existingValue) => existingValue.concat(+page));
      setSearchParams({
        page:
          incompletePages.filter((ip) => ip > +page && ip !== +page)[0] ??
          incompletePages[0] ??
          0,
      });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar variant="dense" />
        <Box sx={{ overflow: 'auto', p: 1 }}>
          <Stepper nonLinear activeStep={+(page ?? 0)} orientation="vertical">
            {includedSections.map(([key, { title }], index) => (
              <Step key={key} completed={completed.includes(index)}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {title}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 48px)',
          width: 'calc(100vw - 240px)',
          backgroundColor: 'background.paper',
        }}
      >
        {includedSections.length > 0 && (
          <Section
            id={includedSections[page] ? includedSections[page][0] : ''}
            groupCodes={brief.groupCodes}
            onComplete={handleComplete(page)}
          />
        )}
      </Box>
    </Box>
  );
}
