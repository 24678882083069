import { api } from '@/apis';
import { addPropertiesPrefixToMatch, isEmpty, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useVehicleBoundaryPolls({ boundary, query }) {
  return useQuery({
    queryKey: ['vehicleBoundaryPolls', boundary, query],
    queryFn: async ({ signal }) => {
      const { time, ...match } = query;
      const featureMatch = addPropertiesPrefixToMatch(match);
      const featurePipeline = [
        {
          $match: {
            time,
            position: { $geoWithin: { $geometry: boundary } },
          },
        },
        {
          $project: {
            _id: false,
            imei: true,
            time: true,
            speedKilometresPerHour: true,
            emergencyEquipmentOn: true,
            ignitionOn: true,
            position: true,
          },
        },
        {
          $lookup: {
            from: 'vehicleHistory',
            localField: 'imei',
            foreignField: 'vehicle.telematicsBoxImei',
            as: 'vehicle',
            let: { pollTime: '$time' },
            pipeline: [
              { $match: { $expr: { $lt: ['$time', '$$pollTime'] } } },
              { $sort: { time: -1 } },
              { $limit: 1 },
              { $replaceRoot: { newRoot: '$vehicle' } },
            ],
          },
        },
        {
          $set: {
            vehicle: {
              $ifNull: [{ $first: '$vehicle' }, { telematicsBoxImei: '$imei' }],
            },
          },
        },
        {
          $project: {
            _id: false,
            id: '$identifier',
            type: 'Feature',
            geometry: '$position',
            properties: {
              imei: '$imei',
              time: '$time',
              speedKilometresPerHour: '$speedKilometresPerHour',
              emergencyEquipmentOn: '$emergencyEquipmentOn',
              ignitionOn: '$ignitionOn',
              vehicle: {
                registrationNumber: '$vehicle.registrationNumber',
                fleetNumber: '$vehicle.fleetNumber',
                role: '$vehicle.role',
                type: '$vehicle.type',
                homeStation: '$vehicle.homeStation',
              },
            },
          },
        },
        !isEmpty(featureMatch) && { $match: featureMatch },
        {
          $setWindowFields: {
            sortBy: { 'properties.time': 1 },
            output: {
              id: {
                $count: {},
                window: { documents: ['unbounded', 'current'] },
              },
            },
          },
        },
      ].filter(Boolean);

      log('Read', 'Vehicle Boundary Polls', { boundary, query });

      return api
        .post('pipeline/telematicsBoxPolls', {
          json: featurePipeline,
          signal,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 8,
    enabled: !!boundary && !isEmpty(query),
  });
}
