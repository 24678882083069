import {
  useDeleteTelematicsBox,
  useDocumentTitle,
  useTelematicsBox,
  useUpsertTelematicsBox,
  useUpsertVehicle,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { TelematicsBoxForm } from './TelematicsBoxForm';

export function TelematicsBox() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: box } = useTelematicsBox(id);
  const { mutate: upsertTelematicsBox } = useUpsertTelematicsBox();
  const { mutate: upsertVehicle } = useUpsertVehicle();
  const { mutate: deleteTelematicsBox } = useDeleteTelematicsBox();
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'vehicles', true);
  useDocumentTitle(
    `IR3 | Telematics Boxes | ${box?._id ? box?.imei : 'New Telematics Box'}`,
  );

  function handleDelete() {
    deleteTelematicsBox(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertTelematicsBox(values, {
      onSuccess: (data) => {
        navigate(`../${data.imei}`, { replace: true });
      },
    });
  }

  function handleRemove(id) {
    upsertVehicle(
      { _id: id, telematicsBoxImei: null },
      {
        onSuccess: () =>
          queryClient.invalidateQueries({ queryKey: ['telematicsBoxes'] }),
      },
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <TelematicsBoxForm
        key={[box?._id, box?.lastEdit?.time]}
        defaultValues={box}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onRemove={handleRemove}
      />
    </Box>
  );
}
