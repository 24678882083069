import {
  DetailsPanel,
  InputStatusIndicator,
  PointMap,
} from '@/components/controls';
import { reviveDate } from '@/utils';
import { dioStates, useDallasKeys } from '@/utils/config';
import { Navigation as NavigationIcon } from '@mui/icons-material';
import { Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ConfigurationPanel } from './ConfigurationPanel';
import { PollHistory } from './PollHistory';
import { SignalStrengthIcon } from './SignalStrengthIcon';
import { SocketStatus } from './SocketStatus';
import { useTelematicsBoxPollStream } from './useTelematicsBoxPollStream';
import { useTelematicsBoxVehicle } from './useTelematicsBoxVehicle';
import {
  columns,
  convertSoftwareId,
  getBatteryColor,
  getCanBusStatus,
  getResetReason,
  getTrigger,
} from './utils';

export function TelematicsBoxTest() {
  const { id } = useParams();
  const [recievedPolls, setRecievedPolls] = useState([]);
  const [tab, setTab] = useState('stream');
  const [date, setDate] = useState(new Date());
  const { data: vehicle } = useTelematicsBoxVehicle(id);

  const { lastMessage, readyState } = useTelematicsBoxPollStream(id);
  const poll = useMemo(() => {
    if (lastMessage) {
      return (
        JSON.parse(lastMessage.data, reviveDate)?.payload?.telematicsBoxes?.[id]
          ?.mostRecentPoll ?? null
      );
    }

    return null;
  }, [id, lastMessage]);

  useEffect(() => {
    if (poll?.identifier) {
      setRecievedPolls((existingPolls) =>
        existingPolls.findIndex((p) => p.identifier === poll.identifier) === -1
          ? [...existingPolls.slice(existingPolls.length < 20 ? 0 : 1), poll]
          : existingPolls,
      );
    }
  }, [poll]);

  function handleTabChange(event, value) {
    setTab(value);
  }

  function handleDateChange(date) {
    setDate(date);
  }

  const table = useMaterialReactTable({
    data: recievedPolls,
    columns: columns,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    defaultColumn: { size: 0 },
    initialState: {
      density: 'compact',
      sorting: [{ id: 'deviceProperties.enqueuedTimestamp', desc: true }],
    },
    muiTableBodyRowProps: {
      sx: { '&:last-child td, &:last-child th': { border: 0 } },
    },
  });

  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ p: 1 }}>
          {id} Test & Configure
        </Typography>
        <SocketStatus readyState={readyState} sx={{ m: 1, fontSize: 16 }} />
      </Stack>
      <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
        {poll?.position && (
          <PointMap
            key={poll?.time}
            point={poll.position}
            width={400}
            height={400}
          />
        )}
        <DetailsPanel
          entries={[
            {
              label: 'Recieved Time',
              value: poll?.deviceProperties?.enqueuedTimestamp
                ? format(
                    poll.deviceProperties.enqueuedTimestamp,
                    'dd/MM/yyyy HH:mm:ss',
                  )
                : '',
            },
            {
              label: 'Ignition',
              value: <InputStatusIndicator value={poll?.ignitionOn} />,
            },
            {
              label: 'Heading',
              value: (
                <Tooltip title={`${poll?.headingDegrees ?? 0}º`} arrow>
                  <NavigationIcon
                    sx={{ fontSize: 16 }}
                    style={{
                      transform: `rotate(${poll?.headingDegrees ?? 0}deg)`,
                    }}
                  />
                </Tooltip>
              ),
            },
            {
              label: 'Signal',
              value: (
                <Tooltip
                  title={poll?.deviceProperties?.deviceSignalStrength}
                  arrow
                >
                  <SignalStrengthIcon
                    strength={poll?.deviceProperties?.deviceSignalStrength}
                    sx={{ fontSize: 16 }}
                  />
                </Tooltip>
              ),
            },
            {
              label: 'Battery',
              value: (
                <Typography
                  noWrap
                  variant="caption"
                  color={getBatteryColor(
                    poll?.deviceProperties?.batteryVoltage,
                  )}
                >
                  {poll?.deviceProperties?.batteryVoltage
                    ? `${poll?.deviceProperties?.batteryVoltage}v`
                    : 'N/A'}
                </Typography>
              ),
            },
            {
              label: 'Speed',
              value: `${Math.round((poll?.speedKilometresPerHour ?? 0) * 0.62137119)} mph`,
            },
            {
              label: 'Device Odometer',
              value: `${Math.round((poll?.distanceKilometres ?? 0) * 0.62137119)} mi`,
            },
            {
              label: 'Buffer Tally',
              value: poll?.bufferCount ?? 0,
            },
            {
              label: 'CAN Bus',
              value: getCanBusStatus(poll?.deviceProperties?.canBusStatus),
            },
            {
              label: 'Reset Reason',
              value: getResetReason(poll?.deviceProperties?.resetReason),
            },
            {
              label: 'Trigger',
              value: getTrigger(
                +poll?.diagnosticCode,
                poll?.deviceProperties?.resetReason,
              ),
            },
            {
              label: useDallasKeys
                ? 'Dallas Key Serial #'
                : 'RFID Card Serial #',
              value: poll?.deviceProperties?.driverId ?? '-',
            },
            {
              label: 'Firmware Version',
              value: convertSoftwareId(poll?.deviceProperties?.softwareId),
            },
          ]}
        />
        <Stack spacing={1}>
          <DetailsPanel
            title="GPS"
            entries={[
              {
                label: 'Fix',
                value: (
                  <InputStatusIndicator
                    value={poll?.deviceProperties?.isValidated}
                    offColor="error.main"
                    onText="Yes"
                    offText="No"
                  />
                ),
              },
              {
                label: 'Time',
                value: poll?.time
                  ? format(poll.time, 'dd/MM/yyyy HH:mm:ss')
                  : '-',
              },
              {
                label: 'Satellites',
                value: poll?.deviceProperties?.satelliteCount ?? 0,
              },
            ]}
          />
          <DetailsPanel
            title="Inputs"
            entries={Object.entries(dioStates).map(([key, label]) => ({
              label,
              value: <InputStatusIndicator value={poll?.[key]} />,
            }))}
          />
        </Stack>
        <Stack spacing={1} sx={{ flexGrow: 1 }}>
          <DetailsPanel
            title="Vehicle"
            entries={[
              {
                label: 'VIN',
                value: vehicle?.identificationNumber ?? '-',
              },
              {
                label: 'Registration',
                value: vehicle?.registrationNumber ?? '-',
              },
              {
                label: 'Fleet #',
                value: vehicle?.fleetNumber ?? '-',
              },
              {
                label: 'Type',
                value: [vehicle?.make, vehicle?.model].join(' ') ?? '-',
              },
              {
                label: 'Role',
                value: vehicle?.role ?? '-',
              },
            ]}
          />
          <ConfigurationPanel imei={id} />
        </Stack>
      </Stack>
      <Tabs value={tab} onChange={handleTabChange} centered>
        <Tab label="Poll Stream" value="stream" />
        <Tab label="Poll History" value="history" />
      </Tabs>
      {tab === 'stream' ? (
        <MaterialReactTable table={table} />
      ) : (
        <PollHistory imei={id} date={date} onDateChange={handleDateChange} />
      )}
    </Stack>
  );
}
