import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useTelematicsBoxVehicle(imei) {
  return useQuery({
    queryKey: ['telematicsBoxVehicle', imei],
    queryFn: async () => {
      const json = [
        { $match: { telematicsBoxImei: imei, deleted: { $exists: false } } },
        {
          $project: {
            _id: false,
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            role: true,
            type: true,
            color: true,
            marked: true,
            make: true,
            model: true,
          },
        },
      ];

      const vehicles = await api.post('pipeline/vehicles', { json }).json();

      return vehicles[0] ?? null;
    },
    placeholderData: null,
    staleTime: 1000 * 60 * 60,
  });
}
