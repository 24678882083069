import { useLocationTypes } from '@/hooks';
import { startCase } from '@/utils';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import { getTypeIcons } from './constants';
import { MenuButton } from './MenuButton';

export function TypeSelectorBar({
  showBack,
  onTypeChange,
  type,
  authorisedTypes,
}) {
  const navigate = useNavigate();
  const { data: locationTypes } = useLocationTypes();

  const shownTypes = Object.keys(getTypeIcons(locationTypes)).filter((type) =>
    authorisedTypes.includes(type),
  );
  const squashed = shownTypes.length > 6;

  return (
    <Box
      sx={(theme) => ({
        minHeight: 48,
        maxHeight: 48,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 420,
        [theme.breakpoints.down('sm')]: {
          width: 1,
        },
        ...(squashed ? { px: 2, justifyContent: 'space-between' } : {}),
      })}
    >
      {showBack ? (
        <IconButton
          sx={(theme) =>
            squashed
              ? {
                  width: 24,
                  height: 24,
                  [theme.breakpoints.down('sm')]: {
                    // margin: -12,
                    padding: 2, // ultimately controls the space left/right of the icons
                  },
                }
              : undefined
          }
          color="inherit"
          aria-label="Back"
          //style={{ marginLeft: -12 }}
          onClick={() => navigate(-1)}
          size="large"
        >
          <BackIcon />
        </IconButton>
      ) : (
        <MenuButton
          color="inherit"
          sx={(theme) =>
            squashed
              ? {
                  width: 24,
                  height: 24,
                  [theme.breakpoints.down('sm')]: {
                    // margin: -12,
                    padding: 2, // ultimately controls the space left/right of the icons
                  },
                }
              : undefined
          }
        />
      )}
      {shownTypes.map((key) => {
        const Icon = getTypeIcons(locationTypes)[key];
        return (
          <Tooltip key={key} title={startCase(key)}>
            <IconButton
              color={key === type ? 'secondary' : 'inherit'}
              sx={(theme) =>
                squashed
                  ? {
                      width: 24,
                      height: 24,
                      [theme.breakpoints.down('sm')]: {
                        // margin: -12,
                        padding: 2, // ultimately controls the space left/right of the icons
                      },
                    }
                  : undefined
              }
              onClick={() => onTypeChange(key)}
              size="large"
            >
              <Icon />
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
}
