import { acceptedAtom, sideNavOpenAtom } from '@/atoms';
import ir3Logo from '@/svg/white-logo.svg';
import { log } from '@/utils';
import {
  autoLogin,
  maintenance,
  promptText,
  useDallasKeys,
} from '@/utils/config';
import {
  AppBar,
  Box,
  Button,
  Snackbar as MuiSnackbar,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Outlet, Route, Routes, useLocation } from 'react-router';
import { HighlightsToolbar } from './Highlights';
import { Maintenance } from './Maintenance';
import { SideNav } from './SideNav';
import { OptionsToolbar } from './admin';
import { BriefHeader } from './briefing';
import { BackButton, LoginAvatar, MenuButton, TextHeader } from './controls';
import { LocationsToolbar } from './locations';

export function App() {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useAtom(sideNavOpenAtom);
  const [accepted, setAccepted] = useAtom(acceptedAtom);
  const { pathname } = useLocation();
  const hideTitle = ['/eventreplay', '/live', '/retrospective'].some((path) =>
    pathname.startsWith(path),
  );

  useEffect(() => {
    if (
      autoLogin &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  function toggleSideNav() {
    setIsSideNavOpen((value) => !value);
  }

  function handleAccept() {
    localStorage.setItem('accepted', true);
    setAccepted(true);

    log('Accept', 'Prompt');
  }

  if (maintenance) {
    return <Maintenance />;
  }

  return (
    <Fragment>
      {!hideTitle && ( // get rid of this when live and retrospective are handled properly
        <Fragment>
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: (theme) => theme.palette.divider,
            }}
          >
            <Toolbar variant="dense">
              {auth.isAuthenticated && (
                <Routes>
                  <Route path="resources">
                    <Route index element={<MenuButton />} />
                    <Route path="telematicsboxes">
                      <Route index element={<MenuButton />} />
                      <Route path=":id">
                        <Route index element={<MenuButton />} />
                        <Route path="test" element={<BackButton />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="*" element={<MenuButton />} />
                </Routes>
              )}
              <img src={ir3Logo} alt="IR3 Logo" style={{ height: 24 }} />
              <Typography variant="h6" sx={{ pl: 0.5, fontWeight: 'bold' }}>
                IR3
              </Typography>
              <Typography variant="h6" sx={{ px: 0.5 }}>
                |
              </Typography>
              <Routes>
                <Route path="/" element={<TextHeader title="Home" />} />
                <Route path="briefs">
                  <Route index element={<TextHeader title="Briefs" />} />
                  <Route path=":code" element={<BriefHeader />} />
                </Route>
                <Route
                  path="highlights/*"
                  element={<TextHeader title="Highlights" />}
                />
                <Route path="replay" element={<TextHeader title="Replay" />} />
                <Route path="tags/*" element={<TextHeader title="Tags" />} />
                <Route
                  path="objectives/*"
                  element={<TextHeader title="Objectives" />}
                />
                <Route
                  path="groups/*"
                  element={<TextHeader title="Groups & Areas" />}
                />
                <Route
                  path="locations/*"
                  element={<TextHeader title="Locations" />}
                />
                <Route path="resources">
                  <Route
                    path="vehicles/*"
                    element={<TextHeader title="Vehicles" />}
                  />
                  <Route
                    path="people/*"
                    element={<TextHeader title="People" />}
                  />
                  <Route
                    path="telematicsboxes/*"
                    element={<TextHeader title="Telematics Boxes" />}
                  />
                  <Route
                    path="radios/*"
                    element={<TextHeader title="Radios" />}
                  />
                  <Route
                    path={useDallasKeys ? 'dallaskeys/*' : 'rfidcards/*'}
                    element={
                      <TextHeader
                        title={useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
                      />
                    }
                  />
                </Route>
                <Route path="reports">
                  <Route path="vehicles">
                    <Route
                      path="dailyutilisation"
                      element={<TextHeader title="Daily Utilisation" />}
                    />
                    <Route
                      path="hourlyutilisation"
                      element={<TextHeader title="Hourly Utilisation" />}
                    />
                    <Route
                      path="hourlyinlocations"
                      element={<TextHeader title="Hourly in Location" />}
                    />
                    <Route
                      path="timelineinlocations"
                      element={<TextHeader title="Timeline in Location" />}
                    />
                    <Route
                      path="mileage"
                      element={<TextHeader title="Mileage" />}
                    />
                    <Route
                      path="availability"
                      element={<TextHeader title="Vehicle Availability" />}
                    />
                    <Route
                      path="fleetutilisation/*"
                      element={<TextHeader title="Fleet Utilisation" />}
                    />
                    <Route
                      path="heat"
                      element={<TextHeader title="Vehicle Heat Map" />}
                    />
                  </Route>
                  <Route path="people">
                    <Route
                      path="dailyactivity"
                      element={<TextHeader title="Daily Activity" />}
                    />
                    <Route
                      path="hourlyactivity"
                      element={<TextHeader title="Hourly Activity" />}
                    />
                    <Route
                      path="availability"
                      element={<TextHeader title="People Availability" />}
                    />
                    <Route
                      path="driverbehaviour/*"
                      element={<TextHeader title="Driver Behaviour" />}
                    />
                    <Route
                      path="heat"
                      element={<TextHeader title="Person Heat Map" />}
                    />
                  </Route>
                  <Route path="telematicsboxes">
                    <Route
                      path="lastcontact"
                      element={<TextHeader title="Last Contact" />}
                    />
                  </Route>
                </Route>
                <Route path="events">
                  <Route path="vehicles">
                    <Route path="trips">
                      <Route index element={<TextHeader title="Trips" />} />
                      <Route path=":id" element={<TextHeader title="Trip" />} />
                    </Route>
                    <Route
                      path="stops"
                      element={<TextHeader title="Stops" />}
                    />
                    <Route
                      path="idles"
                      element={<TextHeader title="Idles" />}
                    />
                    <Route
                      path="speedinfractions"
                      element={<TextHeader title="Speed Infractions" />}
                    />
                    <Route
                      path="accelerometerevents"
                      element={<TextHeader title="Accelerometer Events" />}
                    />
                    <Route
                      path="locationvisits"
                      element={<TextHeader title="Vehicle Location Visits" />}
                    />
                    <Route
                      path="boundaryvisits"
                      element={<TextHeader title="Vehicle Boundary Visits" />}
                    />
                    <Route
                      path="equipmentactivations"
                      element={
                        <TextHeader title="Vehicle Equipment Activations" />
                      }
                    />
                    <Route
                      path="malfunctionindicatorlight"
                      element={
                        <TextHeader title="Malfunction Indicator Light" />
                      }
                    />
                    <Route
                      path="onboarddiagnostics"
                      element={<TextHeader title="On-Board Diagnostics" />}
                    />
                    <Route
                      path="drivingtelemetry"
                      element={<TextHeader title="Driving Telemetry" />}
                    />
                  </Route>
                  <Route path="people">
                    <Route
                      path="trails"
                      element={<TextHeader title="Trails" />}
                    />
                    <Route
                      path="locationvisits"
                      element={<TextHeader title="Person Location Visits" />}
                    />
                    <Route
                      path="boundaryvisits"
                      element={<TextHeader title="Person Boundary Visits" />}
                    />
                    <Route
                      path="doublecrews"
                      element={<TextHeader title="Double Crews" />}
                    />
                    <Route
                      path="attendances"
                      element={<TextHeader title="Objective Attendances" />}
                    />
                    <Route
                      path="responses"
                      element={<TextHeader title="Incident Responses" />}
                    />
                  </Route>
                  <Route path="telematicsboxes">
                    <Route
                      path="outages"
                      element={<TextHeader title="Outages" />}
                    />
                  </Route>
                </Route>
                <Route path="admin">
                  <Route path="audit" element={<TextHeader title="Audit" />} />
                  <Route
                    path="legacyaudit"
                    element={<TextHeader title="Legacy Audit" />}
                  />
                  <Route
                    path="retention"
                    element={<TextHeader title="Data Retention" />}
                  />
                  <Route
                    path="engagement"
                    element={<TextHeader title="Engagement" />}
                  />
                  <Route
                    path="options/*"
                    element={<TextHeader title="Options" />}
                  />
                </Route>
              </Routes>
              <LoginAvatar />
            </Toolbar>
            <Routes>
              <Route path="highlights/*" element={<HighlightsToolbar />} />
              <Route path="admin/options/*" element={<OptionsToolbar />} />
              <Route path="locations/*" element={<LocationsToolbar />} />
            </Routes>
          </AppBar>
          <Toolbar variant="dense" />
        </Fragment>
      )}
      <Routes>
        <Route path="highlights/*" element={<Toolbar variant="dense" />} />
        <Route path="admin/options/*" element={<Toolbar variant="dense" />} />
        <Route path="locations/*" element={<Toolbar variant="dense" />} />
      </Routes>
      {auth.isAuthenticated && (
        <Fragment>
          <SideNav isOpen={isSideNavOpen} onClose={toggleSideNav} />
          {promptText && (
            <MuiSnackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={!accepted}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={
                <Box component="span" id="message-id">
                  {promptText}
                </Box>
              }
              action={
                <Button color="secondary" size="small" onClick={handleAccept}>
                  ACCEPT
                </Button>
              }
            />
          )}
        </Fragment>
      )}
      <Outlet />
    </Fragment>
  );
}
