import { LoadingDialog } from '@/components/controls';
import {
  useBrief,
  useBriefs,
  useDeleteBrief,
  useDocumentTitle,
  useUpsertBrief,
} from '@/hooks';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router';
import { BriefConfigurationDialog } from './BriefConfigurationDialog';
import { BriefCover } from './BriefCover';

export function Briefs() {
  useDocumentTitle('IR3 | Briefs');
  const { data: briefs } = useBriefs();
  const { mutate: upsertBrief } = useUpsertBrief();
  const { mutate: deleteBrief } = useDeleteBrief();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('configure');
  const { data: brief } = useBrief(code);

  function handleAddClick() {
    setSearchParams({ configure: 'new' });
  }

  function handleClose(values) {
    if (values) {
      upsertBrief(values);
    }

    setSearchParams();
  }

  function handleDelete() {
    deleteBrief(code);

    setSearchParams();
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        My Briefs
      </Typography>
      <Grid container spacing={2}>
        {briefs.map((brief) => (
          <Grid key={brief.code} item>
            <BriefCover brief={brief} />
          </Grid>
        ))}
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              width: 180,
              height: 226,
              color: 'text.secondary',
              borderColor: 'text.secondary',
              borderStyle: 'dashed',
              '&:hover': { color: 'primary.main' },
            }}
            endIcon={<AddIcon />}
            onClick={handleAddClick}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      {brief ? (
        <BriefConfigurationDialog
          brief={brief}
          isOpen={Boolean(code)}
          onClose={handleClose}
          onDelete={handleDelete}
        />
      ) : (
        <LoadingDialog isOpen={Boolean(code)} />
      )}
    </Box>
  );
}
