import { api } from '@/apis';
import { dioStates } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';
import { endOfDay, startOfDay } from 'date-fns';

export function useTelematicsBoxPolls(imei, date) {
  const startTime = startOfDay(date);
  const endTime = endOfDay(date);

  return useQuery({
    queryKey: ['telematicsBoxPolls', imei, startTime, endTime],
    queryFn: async () => {
      const json = [
        { $match: { imei } },
        {
          $project: {
            _id: false,
            time: true,
            position: true,
            ignitionOn: true,
            deviceProperties: {
              enqueuedTimestamp: {
                $dateFromString: {
                  dateString: '$deviceProperties.enqueuedTimestamp',
                },
              },
              deviceSignalStrength: true,
              isValidated: true,
              batteryVoltage: true,
              driverId: true,
            },
            ...Object.fromEntries(
              Object.keys(dioStates).map((key) => [key, true]),
            ),
          },
        },
        {
          $match: {
            'deviceProperties.enqueuedTimestamp': {
              $gte: startTime,
              $lt: endTime,
            },
          },
        },
        { $sort: { 'deviceProperties.enqueuedTimestamp': 1 } },
      ];

      return await api.post('pipeline/telematicsBoxPolls', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
