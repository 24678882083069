import { api } from '@/apis';
import { addPropertiesPrefixToMatch, isEmpty, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function usePersonBoundaryPolls({ boundary, query }) {
  return useQuery({
    queryKey: ['personBoundaryPolls', boundary, query],
    queryFn: async ({ signal }) => {
      const { time, ...match } = query;
      const featureMatch = addPropertiesPrefixToMatch(match);
      const featurePipeline = [
        {
          $match: {
            time,
            position: { $geoWithin: { $geometry: boundary } },
          },
        },
        {
          $project: {
            _id: false,
            ssi: true,
            time: true,
            position: true,
            orderNumber: true,
          },
        },
        {
          $lookup: {
            from: 'personHistory',
            localField: 'ssi',
            foreignField: 'person.radioSsi',
            as: 'person',
            let: { pollTime: '$time' },
            pipeline: [
              { $match: { $expr: { $lt: ['$time', '$$pollTime'] } } },
              { $sort: { time: -1 } },
              { $limit: 1 },
              { $replaceRoot: { newRoot: '$person' } },
            ],
          },
        },
        {
          $set: {
            person: {
              $ifNull: [{ $first: '$person' }, { radioSsi: '$ssi' }],
            },
          },
        },
        {
          $project: {
            _id: false,
            id: '$identifier',
            type: 'Feature',
            geometry: '$position',
            properties: {
              ssi: '$ssi',
              time: '$time',
              person: {
                code: '$person.code',
                forenames: '$person.forenames',
                surname: '$person.surname',
                role: '$person.role',
                collarNumber: '$person.collarNumber',
                rank: '$person.rank',
              },
            },
          },
        },
        !isEmpty(featureMatch) && { $match: featureMatch },
        {
          $setWindowFields: {
            sortBy: { 'properties.time': 1 },
            output: {
              id: {
                $count: {},
                window: { documents: ['unbounded', 'current'] },
              },
            },
          },
        },
      ].filter(Boolean);

      log('Read', 'Person Boundary Polls', { boundary, query });

      return api
        .post('pipeline/radioPolls', {
          json: featurePipeline,
          signal,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 8,
    enabled: !!boundary && !isEmpty(query),
  });
}
