import { useUserInfo } from '@/hooks';
import ir3Logo from '@/svg/ir3Logo.svg';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { NavLink } from 'react-router';
import { isAuthorised, siteMap } from './utils';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export function SideNav({ isOpen, onClose }) {
  const [expandedNodes, setExpandedNodes] = useState([]);
  const auth = useAuth();
  const { data: userInfo } = useUserInfo();

  function isExpanded(path) {
    return expandedNodes.includes(path);
  }

  const toggleNode = (path) => () => {
    if (isExpanded(path)) {
      setExpandedNodes(
        expandedNodes.filter((expandedPath) => expandedPath !== path),
      );
    } else {
      setExpandedNodes(expandedNodes.concat(path));
    }
  };

  function Tree({ siteMap, level }) {
    return siteMap
      .filter(({ path, group }) =>
        isAuthorised(path ?? group, auth, userInfo?.authorisation),
      )
      .map(({ label, Icon, path, group, children }) =>
        path ? (
          <ListItemButton
            key={path}
            dense
            component={NavLink}
            to={path}
            sx={{
              '&.active': {
                color: 'primary.main',
                bgcolor: 'background.primary',
                '& svg': {
                  color: 'primary.main',
                },
              },
              borderRadius: 1,
              m: 1,
              height: 40,
              px: 2,
            }}
            onClick={onClose}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
            />
          </ListItemButton>
        ) : (
          <Fragment key={group}>
            <ListItemButton
              dense
              onClick={toggleNode(group)}
              sx={{ borderRadius: 0.5, m: 1, height: 40, px: 2 }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 500,
                  },
                }}
              />
              <ExpandMoreIcon
                sx={(theme) => ({
                  transform: isExpanded(group)
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                  marginLeft: 'auto',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                })}
              />
            </ListItemButton>
            <Collapse in={isExpanded(group)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: level + 1 }}>
                <Tree siteMap={children} level={level + 1} />
              </List>
            </Collapse>
          </Fragment>
        ),
      );
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
    >
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ py: 1, px: 2, alignItems: 'center' }}
        >
          <img src={ir3Logo} alt="IR3 Logo" style={{ height: 24 }} />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              color: 'primary.main',
              flex: 1,
            }}
          >
            IR3
          </Typography>
          <Typography variant="caption">
            v{import.meta.env.VITE_APP_VERSION}
          </Typography>
        </Stack>
        <List disablePadding sx={{ pr: 0, overflow: 'auto', height: 1 }}>
          <Tree siteMap={siteMap} level={0} />
        </List>
        <Stack
          direction="row"
          spacing={1}
          sx={{ p: 1, justifyContent: 'flex-end' }}
        >
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="relative" />
        </Stack>
      </Stack>
    </Drawer>
  );
}
