import { Autorenew as AutorenewIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useTelematicsBoxPolls } from './useTelematicsBoxPolls';
import { columns } from './utils';

export function PollHistory({ imei, date, onDateChange }) {
  const {
    data: polls,
    isFetching,
    isLoading,
    refetch,
  } = useTelematicsBoxPolls(imei, date);

  const table = useMaterialReactTable({
    data: polls,
    columns: columns,
    enablePagination: true,
    enableTopToolbar: false,
    enableBottomToolbar: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    defaultColumn: { size: 0 },
    initialState: { density: 'compact' },
    state: {
      isLoading: isLoading || isFetching,
    },
    muiTableBodyRowProps: {
      sx: { '&:last-child td, &:last-child th': { border: 0 } },
    },
  });

  function handleRefreshClick() {
    refetch();
  }

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <DatePicker
          label="Date"
          value={date}
          onChange={onDateChange}
          slotProps={{ textField: { size: 'small' } }}
        />
        <Tooltip title={isLoading || isFetching ? 'Loading' : 'Refresh'}>
          <Box component="span">
            <IconButton
              onClick={handleRefreshClick}
              disabled={isLoading || isFetching}
              size="small"
            >
              <AutorenewIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
