import {
  useDeleteGroup,
  useDocumentTitle,
  useGroup,
  useOptionValues,
  useUpsertGroup,
} from '@/hooks';
import { Box } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router';
import { GroupForm } from './GroupForm';

export function Group() {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: group } = useGroup(code, searchParams.has('copy'));
  useDocumentTitle(
    ['IR3', 'Groups & Areas', group?._id ? group.name : 'New Group'].join(
      ' | ',
    ),
  );
  const typeOptions = useOptionValues('groupType');
  const { mutate: upsertGroup } = useUpsertGroup();
  const { mutate: deleteGroup } = useDeleteGroup();

  function handleDelete() {
    deleteGroup(code, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertGroup(values, {
      onSuccess: (data) => {
        navigate(`../${data.code}`, { replace: true });
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1 }}>
      <GroupForm
        key={[group?._id, group?.lastEdit?.time]}
        defaultValues={group}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        typeOptions={typeOptions}
      />
    </Box>
  );
}
