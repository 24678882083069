import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router';

export function ReplayLink({ collection, identifier }) {
  function handleClick(event) {
    event.stopPropagation();
  }

  return (
    <Tooltip title="View replay">
      <IconButton
        component={Link}
        size="small"
        onClick={handleClick}
        to={`/eventreplay/${collection}/${identifier}`}
      >
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}
