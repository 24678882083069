import { InputStatusIndicator } from '@/components/controls';
import { round } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  boundary: null,
  query: {},
  parameters: {},
});

export const columns = [
  {
    header: 'Registration',
    id: 'properties.vehicle.registrationNumber',
    accessorFn: ({
      properties: { vehicle: { registrationNumber } = {} } = {},
    } = {}) => registrationNumber ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Fleet Number',
    id: 'properties.vehicle.fleetNumber',
    accessorFn: ({ properties: { vehicle: { fleetNumber } = {} } = {} } = {}) =>
      fleetNumber ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Role',
    id: 'properties.vehicle.role',
    accessorFn: ({ properties: { vehicle: { role } = {} } = {} } = {}) =>
      role ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Type',
    id: 'properties.vehicle.type',
    accessorFn: ({ properties: { vehicle: { type } = {} } = {} } = {}) =>
      type ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'IMEI',
    id: 'properties.imei',
    accessorFn: ({ properties: { imei } = {} } = {}) => imei ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Time',
    accessorKey: 'properties.time',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
    filterVariant: 'datetime-range',
  },
  {
    header: 'Ignition',
    accessorKey: 'properties.ignitionOn',
    Cell: ({ cell }) => <InputStatusIndicator value={cell.getValue()} />,
    filterVariant: 'checkbox',
  },
  {
    header: 'Warning Equipment',
    accessorKey: 'properties.emergencyEquipmentOn',
    Cell: ({ cell }) => <InputStatusIndicator value={cell.getValue()} />,
    filterVariant: 'checkbox',
  },
  {
    header: 'Speed',
    accessorKey: 'properties.speedKilometresPerHour',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mph`,
    filterVariant: 'range-slider',
  },
  {
    header: 'Longitude',
    accessorKey: 'geometry.coordinates.0',
  },
  {
    header: 'Latitude',
    accessorKey: 'geometry.coordinates.1',
  },
];
