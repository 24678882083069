import {
  AutocompleteController,
  AvatarController,
  CategoryPickerController,
  DateTimePickerController,
  GroupAncestors,
  GroupCodesController,
  SwitchController,
  TagField,
  TextFieldController,
} from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { doesIdExist, getRfidErrors, ssiValid } from '@/utils';
import { personForm, useDallasKeys, useRestricted } from '@/utils/config';
import { Person as PersonIcon } from '@mui/icons-material';
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { RadioHandheld as RadioHandheldIcon } from 'mdi-material-ui';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router';
import { RfidCardsButton } from './RfidCardsButton';
import { RfidCardsController } from './RfidCardsController';
import { SkillsController } from './SkillsController';

export function PersonForm({
  defaultValues,
  canEdit,
  onSubmit,
  onDelete,
  supervisors,
  homeStationNames,
  radios,
  wards,
  typeOptions,
  personAttributes,
  personRanks,
  personRoles,
  personGroups,
  activeDirectoryGroups,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    getValues,
    reset,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (personForm[fieldName]?.editable || !defaultValues?._id)
    );
  }

  async function codeDoesNotExist(code) {
    return !defaultValues?._id && (await doesIdExist('people', code))
      ? 'Exists'
      : true;
  }

  async function isValidSsi(ssi) {
    const validSsi = await ssiValid(ssi, getValues('code'));

    if (!validSsi) {
      return { radioSsi: 'In use' };
    }

    return true;
  }

  async function areValidRfidCards(cards) {
    const rfidErrors = await getRfidErrors(getValues('code'), cards);

    if (rfidErrors) {
      return { rfidCards: rfidErrors };
    }

    return true;
  }

  return (
    <Fragment>
      <form id="person-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <AvatarController
              name="picture"
              control={control}
              icon={<PersonIcon />}
              disabled={isDisabled('picture')}
            />
            <Typography variant="h6" sx={{ p: 1, flexGrow: 1 }}>
              {defaultValues?._id
                ? `${defaultValues.forenames} ${defaultValues.surname}`.trim()
                : 'New Person'}
            </Typography>
            {!isDirty && (
              <Fragment>
                {defaultValues?.rfidCards?.length > 0 && (
                  <RfidCardsButton
                    serialNumbers={defaultValues.rfidCards.map(
                      ({ reference }) => reference,
                    )}
                  />
                )}
                {defaultValues?.radioSsi && (
                  <Tooltip title="View radio">
                    <IconButton
                      size="small"
                      component={Link}
                      to={`/resources/radios/${defaultValues?.radioSsi}`}
                    >
                      <RadioHandheldIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Fragment>
            )}
            <Chip
              variant="outlined"
              icon={
                <Chip variant="outlined" label="Last Poll Time" size="small" />
              }
              label={
                defaultValues?.lastPollTime
                  ? format(
                      new Date(defaultValues?.lastPollTime),
                      'dd/MM/yyyy HH:mm:ss',
                    )
                  : 'Never'
              }
            />
          </Stack>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <TextFieldController
              name="code"
              label="Payroll Number"
              control={control}
              disabled={isDisabled('code')}
              format={(value) => value?.toUpperCase()}
              rules={{
                required: 'Required',
                maxLength: 30,
                pattern: {
                  value: /^[a-z0-9 ]*$/i,
                  message: 'Only alphanumeric',
                },
                validate: codeDoesNotExist,
              }}
            />
            <TextFieldController
              name="forenames"
              label="Forenames"
              control={control}
              disabled={isDisabled('forenames')}
              rules={{ maxLength: 50 }}
            />
            <TextFieldController
              name="surname"
              label="Surname"
              control={control}
              disabled={isDisabled('surname')}
              rules={{ maxLength: 50 }}
            />
            <TextFieldController
              name="collarNumber"
              label="Collar Number"
              control={control}
              disabled={isDisabled('collarNumber')}
              format={(value) => value?.toUpperCase()}
              rules={{
                maxLength: 30,
                pattern: {
                  value: /^[a-z0-9 ]*$/i,
                  message: 'Only alphanumeric',
                },
              }}
            />
            <TextFieldController
              name="rank.code"
              label="Rank"
              options={personRanks}
              control={control}
              disabled={isDisabled('rank')}
            />
            <TextFieldController
              name="role"
              label="Role"
              options={personRoles}
              control={control}
              disabled={isDisabled('role')}
            />
            <TextFieldController
              name="emailAddress"
              label="Email"
              type="email"
              control={control}
              disabled={isDisabled('emailAddress')}
            />
            <TextFieldController
              name="mobileNumber"
              label="Mobile Number"
              type="tel"
              control={control}
              disabled={isDisabled('mobileNumber')}
            />
            <AutocompleteController
              name="supervisorCode"
              label="Supervisor"
              control={control}
              single
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={supervisors}
              disabled={isDisabled('supervisorCode')}
            />
            <DateTimePickerController
              name="leavingDate"
              label="Leaving Date & Time"
              control={control}
              disabled={isDisabled('leavingDate')}
            />
            <TextFieldController
              name="assignments.callSign.code"
              label="Call Sign"
              control={control}
              disabled={isDisabled('callSign')}
            />
            <AutocompleteController
              name="radioSsi"
              label="Radio SSI"
              control={control}
              single
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={radios.map((radio) => radio.ssi)}
              disabled={isDisabled('radioSsi')}
              rules={{ validate: isValidSsi }}
            />
            {useRestricted && (
              <SwitchController
                name="visibleTo"
                label="Restricted"
                control={control}
                disabled={isDisabled('visibleTo')}
                parse={(value) => !!value}
                format={(value) => (value ? [] : null)}
              />
            )}
          </Stack>
          {watch('visibleTo') && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Visible to
                </Typography>
              </Divider>
              <AutocompleteController
                name="visibleTo"
                control={control}
                fullWidth
                options={activeDirectoryGroups}
                disabled={isDisabled('visibleTo')}
              />
            </Fragment>
          )}
          {personAttributes.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Attributes
                </Typography>
              </Divider>
              <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
                {personAttributes.map(({ value, label, values }) => (
                  <TextFieldController
                    key={value}
                    name={`attributes.${value}`}
                    label={label}
                    control={control}
                    disabled={!canEdit}
                    options={values}
                  />
                ))}
              </Stack>
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Home
            </Typography>
          </Divider>
          <TextFieldController
            name="homeStation"
            label="Station"
            options={homeStationNames.map(({ name, code }) => ({
              label: name,
              value: code,
            }))}
            control={control}
            disabled={isDisabled('homeStation')}
          />
          <AutocompleteController
            name="wards"
            label="Wards"
            control={control}
            fullWidth
            options={wards.map((ward) => ({
              label: ward.name,
              value: ward.code,
            }))}
            disabled={isDisabled('wards')}
          />
          <GroupCodesController
            name="groupCodes"
            label="Groups & Areas"
            control={control}
            disabled={isDisabled('groupCodes')}
          />
          {defaultValues?.groupAncestors?.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Ancestor Groups & Areas
                </Typography>
              </Divider>
              <GroupAncestors
                groupAncestors={defaultValues?.groupAncestors}
                typeOptions={typeOptions}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Categories
            </Typography>
          </Divider>
          <CategoryPickerController
            name="groups"
            control={control}
            categories={personGroups}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Skills
            </Typography>
          </Divider>
          <SkillsController
            name="skills"
            control={control}
            disabled={isDisabled('skills')}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              {useDallasKeys ? 'Dallas Keys' : 'RFID Cards'}
            </Typography>
          </Divider>
          <RfidCardsController
            name="rfidCards"
            control={control}
            disabled={isDisabled('rfidCards')}
            rules={{
              validate: areValidRfidCards,
            }}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Tags
            </Typography>
          </Divider>
          <TagField
            Ø
            collection="people"
            id={defaultValues?._id}
            linkPrefix="/tags"
            sx={{ pb: 1 }}
            disabled={!defaultValues?._id}
          />
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* <Button
              color="primary"
              aria-owns={anchorEl ? 'events-menu' : undefined}
              aria-haspopup="true"
              onClick={handleEventsClick}
              disabled={isDirty || isSubmitting || !defaultValues?._id}
            >
              Events
            </Button> */}
            {/* <Menu
              id="events-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleEventsClose}
            >
              {!isFleet && (
                <MenuItem
                  onClick={navigateToEvents('trails', 'trails', 'people')}
                >
                  Trails
                </MenuItem>
              )}
              {!isFleet && (
                <MenuItem
                  onClick={navigateToEvents(
                    'personLocationVisits',
                    'locationVisits',
                    'people',
                  )}
                >
                  Location Visits
                </MenuItem>
              )}
              {!isFleet && (
                <MenuItem
                  onClick={navigateToEvents(
                    'doubleCrews',
                    'doubleCrews',
                    'people',
                  )}
                >
                  Double Crews
                </MenuItem>
              )}
              {!isFleet && (
                <MenuItem
                  onClick={navigateToEvents(
                    'attendances',
                    'attendances',
                    'people',
                  )}
                >
                  Attendances
                </MenuItem>
              )}
              <MenuItem
                onClick={navigateToEvents('trips', 'trips', 'vehicles')}
              >
                Trips
              </MenuItem>
              <MenuItem
                onClick={navigateToEvents('stops', 'stops', 'vehicles')}
              >
                Stops
              </MenuItem>
              <MenuItem
                onClick={navigateToEvents('idles', 'idles', 'vehicles')}
              >
                Idles
              </MenuItem>
              <MenuItem
                onClick={navigateToEvents(
                  'speedInfractions',
                  'speedInfractions',
                  'vehicles',
                )}
              >
                Speed Infractions
              </MenuItem>
              <MenuItem
                onClick={navigateToEvents(
                  'accelerometerEvents',
                  'accelerometerEvents',
                  'vehicles',
                )}
              >
                Accelerometer Events
              </MenuItem>
            </Menu> */}
            {canEdit && (
              <Button
                color="error"
                onClick={toggleDeleteDialog}
                disabled={!watch('_id')}
              >
                Delete
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={
          `${defaultValues?.forenames} ${defaultValues?.surname}`.trim() ||
          defaultValues?.collarNumber
        }
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Fragment>
  );
}
