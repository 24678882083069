import { Cancel as CancelIcon } from '@mui/icons-material';
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import { ToggleSwitch } from './ToggleSwitch';
import { useDriverIdConfiguration } from './useDriverIdConfiguration';

export function DriverIdConfiguration({ imei }) {
  const driverId = useDriverIdConfiguration(imei);

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Driver ID
        </Typography>
        <ToggleSwitch configuration={driverId} />
      </Stack>
      {driverId.isPending && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption">Update pending...</Typography>
          <Tooltip title="Cancel update">
            <IconButton size="small" color="error" onClick={driverId.cancel}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      <Divider />
    </Fragment>
  );
}
