import { useDallasKeys } from '@/utils/config';
import { Tab, Tabs, Toolbar } from '@mui/material';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router';

const tabs = [
  { label: 'People', value: '/admin/options/people' },
  { label: 'Vehicles', value: '/admin/options/vehicles' },
  { label: 'Locations', value: '/admin/options/locations' },
  { label: 'Groups & Areas', value: '/admin/options/groups' },
  { label: 'Telematics Boxes', value: '/admin/options/telematicsboxes' },
  { label: 'Radios', value: '/admin/options/radios' },
  useDallasKeys
    ? { label: 'Dallas Keys', value: '/admin/options/dallaskeys' }
    : {
        label: 'RFID Cards',
        value: '/admin/options/rfidcards',
      },
];

export function OptionsToolbar() {
  const { pathname } = useLocation();

  return (
    <Fragment>
      <Toolbar variant="dense" disableGutters>
        <Tabs
          textColor="inherit"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          value={pathname === '/admin/options' ? tabs[0].value : pathname}
        >
          {tabs.map(({ label, value }) => (
            <Tab
              key={value}
              label={label}
              value={value}
              to={value}
              component={Link}
            />
          ))}
        </Tabs>
      </Toolbar>
    </Fragment>
  );
}
