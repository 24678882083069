import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function usePeople() {
  return useQuery({
    queryKey: ['people'],
    queryFn: () => {
      const json = [
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestorCodes',
          },
        },
        {
          $project: {
            code: true,
            picture: true,
            forenames: true,
            surname: true,
            collarNumber: true,
            radioSsi: true,
            lastPollTime: true,
            rfidCards: true,
            role: true,
            homeStation: true,
            leavingDate: true,
            assignments: true,
            emailAddress: true,
            groupAncestorCodes: {
              $map: {
                input: '$groupAncestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            searchString: {
              $toLower: {
                $concat: [
                  { $ifNull: ['$forenames', ''] },
                  ' ',
                  { $ifNull: ['$surname', ''] },
                  '+',
                  { $ifNull: ['$code', ''] },
                  '+',
                  { $ifNull: ['$collarNumber', ''] },
                  '+',
                  { $ifNull: ['$radioSsi', ''] },
                  '+',
                  { $ifNull: ['$emailAddress', ''] },
                  '+',
                  {
                    $reduce: {
                      input: { $ifNull: ['$rfidCards', []] },
                      initialValue: '',
                      in: {
                        $concat: [
                          { $ifNull: ['$$value', ''] },
                          '+',
                          { $ifNull: ['$$this.reference', ''] },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
        },
      ];

      log('Read', 'People');

      return api.post('pipeline/people', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
