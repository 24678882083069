import { InputStatusIndicator } from '@/components/controls';
import { dioStates, useDallasKeys } from '@/utils/config';
import { format } from 'date-fns';
import { SignalStrengthIcon } from './SignalStrengthIcon';

export function getBatteryColor(voltage) {
  if (voltage < 8.5) {
    return 'error';
  }

  if (!voltage) {
    return 'warning';
  }

  return 'success';
}

export function getCanBusStatus(status) {
  switch (status) {
    case 1:
      return 'Connected';
    case 2:
      return 'Vehicle info changed';
    case 3:
      return 'DTC count changed';
    case 4:
      return 'Bus-off detected';
    case 5:
      return 'External tester detected';
    case 14:
      return 'Connection attempts exceeded';
    case 15:
      return 'Not connected';
    default:
      return 'Unknown';
  }
}

export function getTrigger(diagnosticCode, resetReason) {
  if (resetReason) {
    switch (diagnosticCode) {
      case 0:
        return 'Default';
      case 1:
        return 'Reset';
      case 2:
        return 'RMS';
      case 3:
        return 'CMS';
      case 4:
        return 'Firmware error';
      case 5:
        return 'Download aborted';
      case 6:
        return 'Encryption disabled';
      case 7:
        return 'Alert ocurred';
    }
  } else {
    switch (diagnosticCode) {
      case 0:
        return 'Default';
      case 1:
        return 'CAN Bus';
      case 2:
        return 'GPS fix';
      case 3:
        return 'Ignition on';
      case 4:
        return 'Ignition off';
      case 5:
        return 'Accelerometer';
      case 6:
        return 'Driver ID';
      case 7:
        return 'Input';
      default:
        return 'Unknown';
    }
  }
}

export function getResetReason(reason) {
  switch (reason) {
    case 1:
      return 'Watchdog fired';
    case 2:
      return 'Firmware updated';
    default:
      return '-';
  }
}

export function convertSoftwareId(sid) {
  if (!sid) {
    return '-';
  }

  return (
    String('00' + (sid & 0x0ff).toString(16)).slice(-2) +
    '.' +
    String('00' + ((sid / 0x100) | 0).toString(16)).slice(-2)
  );
}

export const columns = [
  {
    header: 'Recieved Time',
    accessorKey: 'deviceProperties.enqueuedTimestamp',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Signal',
    accessorKey: 'deviceProperties.deviceSignalStrength',
    Cell: ({ cell }) => (
      <SignalStrengthIcon strength={cell.getValue()} sx={{ fontSize: 16 }} />
    ),
  },
  {
    header: 'GPS Fix',
    accessorKey: 'deviceProperties.isValidated',
    Cell: ({ cell }) => (
      <InputStatusIndicator
        value={cell.getValue()}
        offColor="error.main"
        onText="Yes"
        offText="No"
      />
    ),
  },
  {
    header: 'GPS Time',
    accessorKey: 'time',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Latitude',
    id: 'latitude',
    accessorFn: ({ position }) => position?.coordinates?.[1] ?? '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
  {
    header: 'Longitude',
    id: 'longitude',
    accessorFn: ({ position }) => position?.coordinates?.[0] ?? '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
  {
    header: 'Battery',
    accessorKey: 'deviceProperties.batteryVoltage',
    Cell: ({ cell }) => (cell.getValue() ? `${cell.getValue()}v` : 'N/A'),
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: ({ cell }) => ({
      align: 'right',
      sx: { color: `${getBatteryColor(cell.getValue())}.main` },
    }),
  },
  {
    header: useDallasKeys ? 'Dallas Key Serial #' : 'RFID Card Serial #',
    id: 'deviceProperties.driverId',
    accessorFn: ({ deviceProperties }) => deviceProperties?.driverId ?? '-',
  },
  {
    header: 'Ignition',
    accessorKey: 'ignitionOn',
    Cell: ({ cell }) => <InputStatusIndicator value={cell.getValue()} />,
  },
  ...Object.entries(dioStates).map(([key, label]) => ({
    header: label,
    accessorKey: key,
    Cell: ({ cell }) => <InputStatusIndicator value={cell.getValue()} />,
  })),
];
