import { useLocationTypes } from '@/hooks';
import { Box, Tab, Tabs, Toolbar } from '@mui/material';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router';

export function LocationsToolbar() {
  const { data: locationTypes } = useLocationTypes();
  const { locationType = Object.keys(locationTypes)[0] } = useParams();

  return (
    <Fragment>
      <Toolbar variant="dense" disableGutters>
        <Tabs
          textColor="inherit"
          indicatorColor="secondary"
          value={locationType}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.entries(locationTypes).map(([path, locationType]) => (
            <Tab
              key={path}
              label={locationType.header}
              value={path}
              to={`/locations/${path}`}
              component={Link}
            />
          ))}
        </Tabs>
        <Box sx={{ flex: 1 }} />
      </Toolbar>
    </Fragment>
  );
}
