import { useOptionValues } from '@/hooks';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';

export function SkillsController({ control, name, label, disabled, rules }) {
  const {
    field: { value, disabled: isDisabled, onChange, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: [],
    disabled,
  });
  const personSkills = useOptionValues('personSkill');
  const skills = Object.entries(personSkills).reduce(
    (acc, [type, { values }]) => {
      return [...acc, ...values.map((value) => ({ type, name: value.value }))];
    },
    [],
  );
  const valuesNotInSkills = useMemo(
    () =>
      (value ?? [])
        .flat()
        .filter((value) => !skills.find((skill) => skill.name === value.name)),
    [value, skills],
  );

  const groupBy = (option) => personSkills[option.type]?.label;

  const getOptionLabel = (option) =>
    personSkills[option.type]?.values?.find(
      (value) => value.value === option.name,
    )?.label ?? option.name;

  const isOptionEqualToValue = (option, selected) =>
    (option.name ?? option) === (selected.name ?? selected);

  function handleChange(_, selections) {
    onChange(selections);
  }

  function renderInput(params) {
    return (
      <TextField
        inputRef={ref}
        label={label}
        error={invalid}
        helperText={error?.message}
        {...params}
      />
    );
  }

  function renderTags(value, getTagProps) {
    return value.map((option, index) => {
      const { key, ...props } = getTagProps({ index });

      return (
        <Chip
          key={key}
          label={getOptionLabel(option)}
          icon={<Chip color="primary" label={groupBy(option)} size="small" />}
          {...props}
        />
      );
    });
  }

  return (
    <Autocomplete
      value={value}
      fullWidth
      size="small"
      disabled={isDisabled}
      multiple
      onChange={handleChange}
      options={valuesNotInSkills.concat(skills)}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      sx={{ minWidth: 240 }}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
}
