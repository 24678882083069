import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  boundary: null,
  query: {},
  parameters: {},
});

export const columns = [
  {
    header: 'Staff #',
    id: 'properties.person.code',
    accessorFn: ({ properties: { person: { code } = {} } = {} } = {}) =>
      code ?? '',
  },
  {
    header: 'Forenames',
    id: 'properties.person.forenames',
    accessorFn: ({ properties: { person: { forenames } = {} } = {} } = {}) =>
      forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'properties.person.surname',
    accessorFn: ({ properties: { person: { surname } = {} } = {} } = {}) =>
      surname ?? '',
  },
  {
    header: 'Role',
    id: 'properties.person.role',
    accessorFn: ({ properties: { person: { role } = {} } = {} } = {}) =>
      role ?? '',
  },
  {
    header: 'Collar Number',
    id: 'properties.person.collarNumber',
    accessorFn: ({ properties: { person: { collarNumber } = {} } = {} } = {}) =>
      collarNumber ?? '',
  },
  {
    header: 'Rank',
    id: 'properties.person.rank.code',
    accessorFn: ({
      properties: { person: { rank: { code } = {} } = {} } = {},
    } = {}) => code ?? '',
  },
  {
    header: 'SSI',
    id: 'properties.ssi',
    accessorFn: ({ properties: { ssi } = {} } = {}) => ssi ?? '',
  },
  {
    header: 'Time',
    accessorKey: 'properties.time',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Longitude',
    accessorKey: 'geometry.coordinates.0',
  },
  {
    header: 'Latitude',
    accessorKey: 'geometry.coordinates.1',
  },
];
