import { useDallasKeys } from '@/utils/config';
import { Nfc as NfcIcon, VpnKey as VpnKeyIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Fragment, useState } from 'react';
import { Link } from 'react-router';

export function RfidCardsButton({ serialNumbers = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <Tooltip title={useDallasKeys ? 'View Dallas keys' : 'View RFID cards'}>
        <IconButton size="small" onClick={handleClick}>
          {useDallasKeys ? <VpnKeyIcon /> : <NfcIcon />}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {serialNumbers.map((serialNumber) => (
          <MenuItem
            dense
            component={Link}
            to={`/resources/${
              useDallasKeys ? 'dallaskeys' : 'rfidcards'
            }/${serialNumber}`}
            key={serialNumber}
          >
            {serialNumber}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
