import { RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  PinDrop as PinDropIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';

export function Incident({
  color,
  background,
  description,
  number,
  responseCategory,
  grade,
  openedTime,
  type,
  assignedTime,
  attendedTime,
  closedTime,
  closingCodes,
  reference,
  date,
  address,
  locations,
}) {
  const id = `${reference}, ${date.split('-').reverse().join('/')}`;
  useDocumentTitle(['IR3', 'Incident', id].filter(Boolean).join(' | '));
  const navigate = useNavigate();

  function handleViewClick() {
    navigate(`/eventreplay/incidents/${number}`);
  }

  const details = [
    //   { label: 'Type', value: incident.type && incident.type.name },
    //   { label: 'Status', value: incident.status },
    { label: 'Address', value: address },
    { label: 'Description', value: description },
    //   { label: 'Tags', value: incident.tags.join(',') },
    { label: 'Response Category', value: responseCategory?.name },
    ...(locations || []).map((location) => ({
      label: location.type,
      value: location.name,
    })),
  ];

  const title = `Incident ${id}`;
  const secondary = `${type?.code ?? ''} ${type?.name ?? ''}`;

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Badge
            badgeContent={
              responseCategory && responseCategory.code
                ? responseCategory.code
                : grade || '?'
            }
            color="primary"
          >
            <Tooltip title={responseCategory.name || `Grade ${grade}`}>
              <Avatar
                style={{
                  background,
                  color,
                }}
              >
                <RetrospectiveTypeIcon type="incidents" />
              </Avatar>
            </Tooltip>
          </Badge>
        }
        title={title}
        subheader={secondary}
        action={
          <Tooltip title="View Replay">
            <IconButton
              aria-label="replay"
              onClick={handleViewClick}
              size="large"
            >
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small">
          <TableBody>
            {details.map(({ label, value }) => (
              <TableRow key={label}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {label}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical" sx={{ mt: 2, ml: 2 }}>
          <Step active>
            <StepLabel
              icon={
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    color: 'common.white',
                    bgcolor: 'error.main',
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(openedTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              Opened
            </StepLabel>
            <StepContent>
              {type && <Typography variant="caption">{type.code}</Typography>}
            </StepContent>
          </Step>
          {assignedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    c
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'common.white',
                      backgroundColor: 'warning.main',
                    }}
                  >
                    <DoneIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {format(new Date(assignedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Assigned
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {attendedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'common.white',
                      backgroundColor: 'success.main',
                    }}
                  >
                    <PinDropIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {format(new Date(attendedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Attended
              </StepLabel>
              <StepContent />
            </Step>
          )}
          {closedTime && (
            <Step active>
              <StepLabel
                icon={
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'common.white',
                      backgroundColor: 'grey.500',
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </Avatar>
                }
                optional={
                  <Typography variant="caption">
                    {format(new Date(closedTime), 'dd/MM/yyyy HH:mm:ss')}
                  </Typography>
                }
              >
                Closed
              </StepLabel>
              <StepContent>
                {!closingCodes
                  ? ''
                  : (closingCodes || []).map((cc, index) => (
                      <Typography variant="caption" key={index}>
                        {cc.code}
                      </Typography>
                    ))}
              </StepContent>
            </Step>
          )}
        </Stepper>
      </CardContent>
    </Card>
  );
}
