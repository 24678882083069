import { hiddenRoutes, showLegacyAudit, useDallasKeys } from '@/utils/config';
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  AdsClick as AdsClickIcon,
  HowToReg as AttendanceIcon,
  BarChart as BarChartIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Event as EventIcon,
  ExploreOff as ExploreOffIcon,
  Fence as FenceIcon,
  Fluorescent as FluorescentIcon,
  Group as GroupIcon,
  Groups as GroupsIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Inventory as InventoryIcon,
  Language as LanguageIcon,
  LocationCity as LocationCityIcon,
  More as MoreIcon,
  NewReleases as NewReleasesIcon,
  Nfc as NfcIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Replay as ReplayIcon,
  Reply as ReplyIcon,
  Route as RouteIcon,
  Router as RouterIcon,
  Schedule as ScheduleIcon,
  Snooze as SnoozeIcon,
  Star as StarIcon,
  Subject as SubjectIcon,
  Timeline as TimelineIcon,
  Timer as TimerIcon,
  Tour as TourIcon,
  VpnKey as VpnKeyIcon,
  Whatshot as WhatshotIcon,
  Work as WorkIcon,
} from '@mui/icons-material';
import {
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  CastAudioVariant as CastAudioVariantIcon,
  CarShiftPattern as CarShiftPatternIcon,
  Counter as CounterIcon,
  Engine as EngineIcon,
  Garage as GarageIcon,
  Gauge as GaugeIcon,
  AccountCancel as PeopleAvailabilityIcon,
  RadioHandheld as RadioHandheldIcon,
  Speedometer as SpeedometerIcon,
  Steering as SteeringIcon,
  Stethoscope as StethoscopeIcon,
} from 'mdi-material-ui';

// NOTE: The path value should start with '/' to support to build authorised siteMap. Please do not remove '/' from start of the path value.
export const siteMap = [
  { label: 'Home', Icon: HomeIcon, path: '/' },
  {
    label: 'Highlights',
    Icon: StarIcon,
    path: '/highlights',
  },
  { label: 'Live', Icon: LanguageIcon, path: '/live' },
  { label: 'Briefs', Icon: WorkIcon, path: '/briefs' },
  {
    label: 'Retrospective',
    Icon: HistoryIcon,
    path: '/retrospective',
  },
  {
    label: 'Replay',
    Icon: ReplayIcon,
    path: '/replay',
  },
  { label: 'Tags', Icon: MoreIcon, path: '/tags' },
  {
    label: 'Objectives',
    Icon: DataUsageIcon,
    path: '/objectives',
  },
  { label: 'Groups & Areas', Icon: GroupsIcon, path: '/groups' },
  {
    label: 'Locations',
    Icon: LocationCityIcon,
    path: '/locations',
  },
  {
    label: 'Resources',
    Icon: GroupIcon,
    group: '/resources',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        path: '/resources/vehicles',
      },
      {
        label: 'People',
        Icon: PersonIcon,
        path: '/resources/people',
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        path: '/resources/telematicsboxes',
      },
      {
        label: 'Radios',
        Icon: RadioHandheldIcon,
        path: '/resources/radios',
      },
      {
        label: useDallasKeys ? 'Dallas Keys' : 'RFID Cards',
        Icon: useDallasKeys ? VpnKeyIcon : NfcIcon,
        path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
      },
    ],
  },
  {
    label: 'Reports',
    Icon: BarChartIcon,
    group: '/reports',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        group: '/reports/vehicles',
        children: [
          {
            label: 'Daily Utilisation',
            Icon: EventIcon,
            path: '/reports/vehicles/dailyutilisation',
          },
          {
            label: 'Hourly Utilisation',
            Icon: ScheduleIcon,
            path: '/reports/vehicles/hourlyutilisation',
          },
          {
            label: 'Hourly in Location',
            Icon: GarageIcon,
            path: '/reports/vehicles/hourlyinlocations',
          },
          {
            label: 'Timeline in Location',
            Icon: TimelineIcon,
            path: '/reports/vehicles/timelineinlocations',
          },
          {
            label: 'Mileage',
            Icon: CounterIcon,
            path: '/reports/vehicles/mileage',
          },
          {
            label: 'Availability',
            Icon: EmojiTransportationIcon,
            path: '/reports/vehicles/availability',
          },
          {
            label: 'Fleet Utilisation',
            Icon: GaugeIcon,
            path: '/reports/vehicles/fleetutilisation',
          },
          {
            label: 'Heat Map',
            Icon: WhatshotIcon,
            path: '/reports/vehicles/heat',
          },
        ],
      },
      {
        label: 'People',
        Icon: PersonIcon,
        group: '/reports/people',
        children: [
          {
            label: 'Driver Behaviour',
            Icon: SteeringIcon,
            path: '/reports/people/driverbehaviour',
          },
          {
            label: 'Daily Activity',
            Icon: EventIcon,
            path: '/reports/people/dailyactivity',
          },
          {
            label: 'Hourly Activity',
            Icon: ScheduleIcon,
            path: '/reports/people/hourlyactivity',
          },
          {
            label: 'Availability',
            Icon: PeopleAvailabilityIcon,
            path: '/reports/people/availability',
          },
          {
            label: 'Heat Map',
            Icon: WhatshotIcon,
            path: '/reports/people/heat',
          },
        ],
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        group: '/reports/telematicsboxes',
        children: [
          {
            key: 'lastContact',
            label: 'Last Contact',
            Icon: TimerIcon,
            path: '/reports/telematicsboxes/lastcontact',
          },
        ],
      },
    ],
  },
  {
    label: 'Events',
    Icon: NewReleasesIcon,
    group: '/events',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        group: '/events/vehicles',
        children: [
          {
            label: 'Trips',
            Icon: CarTractionControlIcon,
            path: '/events/vehicles/trips',
          },
          {
            label: 'Stops',
            Icon: CarBrakeParkingIcon,
            path: '/events/vehicles/stops',
          },
          {
            label: 'Idles',
            Icon: SnoozeIcon,
            path: '/events/vehicles/idles',
          },
          {
            label: 'Speed Infractions',
            Icon: SpeedometerIcon,
            path: '/events/vehicles/speedinfractions',
          },
          {
            label: 'Accelerometer Events',
            Icon: AxisArrowIcon,
            path: '/events/vehicles/accelerometerevents',
          },
          {
            label: 'Location Visits',
            Icon: CarSideIcon,
            path: '/events/vehicles/locationvisits',
          },
          {
            label: 'Boundary Visits',
            Icon: FenceIcon,
            path: '/events/vehicles/boundaryvisits',
          },
          {
            label: 'Equipment Activations',
            Icon: FluorescentIcon,
            path: '/events/vehicles/equipmentactivations',
          },
          {
            label: 'Malfunction Indicator Light',
            Icon: EngineIcon,
            path: '/events/vehicles/malfunctionindicatorlight',
          },
          {
            label: 'On-Board Diagnostics',
            Icon: StethoscopeIcon,
            path: '/events/vehicles/onboarddiagnostics',
          },
          {
            label: 'Driving Telemetry',
            Icon: CarShiftPatternIcon,
            path: '/events/vehicles/drivingtelemetry',
          },
        ],
      },
      {
        label: 'People',
        Icon: PersonIcon,
        group: '/events/people',
        children: [
          {
            label: 'Trails',
            Icon: RouteIcon,
            path: '/events/people/trails',
          },
          {
            label: 'Location Visits',
            Icon: TourIcon,
            path: '/events/people/locationvisits',
          },
          {
            label: 'Boundary Visits',
            Icon: FenceIcon,
            path: '/events/people/boundaryvisits',
          },
          {
            label: 'Double Crews',
            Icon: PeopleIcon,
            path: '/events/people/doublecrews',
          },
          {
            label: 'Objective Attendances',
            Icon: AttendanceIcon,
            path: '/events/people/attendances',
          },
          {
            label: 'Incident Responses',
            Icon: ReplyIcon,
            path: '/events/people/responses',
          },
        ],
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        group: '/events/telematicsboxes',
        children: [
          {
            label: 'Outages',
            Icon: ExploreOffIcon,
            path: '/events/telematicsboxes/outages',
          },
        ],
      },
    ],
  },
  {
    label: 'Admin',
    Icon: AdminPanelSettingsIcon,
    group: '/admin',
    children: [
      {
        label: 'Audit',
        Icon: ChromeReaderModeIcon,
        path: '/admin/audit',
      },
      showLegacyAudit && {
        label: 'Legacy Audit',
        Icon: CastAudioVariantIcon,
        path: '/admin/legacyaudit',
      },
      {
        label: 'Data Retention',
        Icon: InventoryIcon,
        path: '/admin/retention',
      },
      {
        label: 'Engagement',
        Icon: AdsClickIcon,
        path: '/admin/engagement',
      },
      {
        label: 'Options',
        Icon: SubjectIcon,
        path: '/admin/options',
      },
    ].filter(Boolean),
  },
];

export function isAuthorised(path, auth, authorisation) {
  if (!auth || !auth.isAuthenticated) {
    return false;
  }

  const { routes, hiddenRoutes: serverSideHiddenRoutes } = authorisation ?? {};
  const allHiddenRoutes = [
    ...(hiddenRoutes || []),
    ...(serverSideHiddenRoutes || []),
  ];

  return (
    ((routes || []).some((route) => route.startsWith(path)) ||
      (routes || []).some((route) => path.startsWith(route))) &&
    (allHiddenRoutes || []).every((route) => !path.startsWith(route))
  );
}
