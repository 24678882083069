import { dioOptions } from '@/utils/config';
import { Divider, Stack, Typography } from '@mui/material';
import { DriverIdConfiguration } from './DriverIdConfiguration';
import { TrackingConfiguration } from './TrackingConfiguration';

export function ConfigurationPanel({ imei }) {
  return (
    <Stack
      spacing={1}
      sx={{
        p: 1,
        pl: 2,
        border: 1,
        borderColor: 'divider',
        borderRadius: 1,
        flexGrow: 1,
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        Configuration
      </Typography>
      <Divider />
      {dioOptions.showDriverId && <DriverIdConfiguration imei={imei} />}
      {dioOptions.showTracking && <TrackingConfiguration imei={imei} />}
    </Stack>
  );
}
