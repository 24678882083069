import { downloadData } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

const columns = [
  { header: 'IMEI', key: 'imei' },
  { header: 'RFID', key: 'associatedRfid' },
  { header: 'VIN', key: 'vehicleIdentificationNumber' },
  { header: 'Time', key: 'time' },
  { header: 'Latitude', key: 'position.coordinates.0' },
  { header: 'Longitude', key: 'position.coordinates.1' },
  { header: 'Heading (degrees)', key: 'headingDegrees' },
  { header: 'Speed (mph)', key: 'speedMilesPerHour' },
  { header: 'Speed Limit (mph)', key: 'speedLimitMilesPerHour' },
  { header: 'Odometer (miles)', key: 'odometerMiles' },
  { header: 'Ignition On', key: 'ignitionOn' },
  {
    header: 'Malfunction Indicator Light On',
    key: 'malfunctionIndicatorLightOn',
  },
  { header: 'Emergency Equipment On', key: 'emergencyEquipmentOn' },
  { header: 'Accelerometer Event Stored', key: 'accelerometerEventStored' },
  { header: 'Diagnostic Code', key: 'diagnosticCode' },
];

const pipelineFn = (imei, startTime, endTime) => [
  {
    $match: {
      imei,
      time: { $gte: startTime, $lte: endTime },
    },
  },
  {
    $project: {
      _id: false,
      imei: true,
      associatedRfid: true,
      vehicleIdentificationNumber: true,
      time: true,
      position: true,
      headingDegrees: true,
      speedMilesPerHour: {
        $round: [{ $multiply: ['$speedKilometresPerHour', 0.62137119] }, 2],
      },
      speedLimitMilesPerHour: {
        $round: [
          {
            $multiply: [
              '$reverseGeocode.speedLimitKilometresPerHour',
              0.62137119,
            ],
          },
          2,
        ],
      },
      odometerMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
      ignitionOn: { $cond: ['$ignitionOn', 'Yes', 'No'] },
      malfunctionIndicatorLightOn: {
        $cond: ['$malfunctionIndicatorLightOn', 'Yes', 'No'],
      },
      emergencyEquipmentOn: { $cond: ['$emergencyEquipmentOn', 'Yes', 'No'] },
      accelerometerEventStored: {
        $cond: ['$deviceProperties.pendingAccelerometerEvent', 'Yes', 'No'],
      },
      diagnosticCode: true,
    },
  },
  { $sort: { time: 1 } },
];

export function DownloadPollsLink({ imei, startTime, endTime }) {
  const [isDownloading, setIsDownloading] = useState(false);

  function handleClick(event) {
    event.stopPropagation();

    setIsDownloading(true);

    downloadData(
      'telematicsBoxPolls',
      'polls.csv',
      pipelineFn(imei, startTime, endTime),
      columns,
    )
      .catch((error) => {
        captureException(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setIsDownloading(false));
  }

  return (
    <Tooltip title="Download polls">
      <IconButton size="small" onClick={handleClick}>
        {isDownloading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          <DownloadIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
}
