import { scoreColour } from '@/components/reports/people/DriverBehaviour/utils';
import { kilometresToMiles, round, shortHumanizer } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: { ...query, durationSeconds: { $gt: 0 } } },
    {
      $project: {
        _id: false,
        identifier: true,
        startTime: true,
        endTime: true,
        driver: {
          code: true,
          collarNumber: true,
          forenames: true,
          surname: true,
          role: true,
        },
        vehicle: {
          registrationNumber: true,
          fleetNumber: true,
          identificationNumber: true,
          telematicsBoxImei: true,
          role: true,
        },
        rfidCard: {
          reference: true,
          type: true,
          label: true,
        },
        classification: true,
        distanceKilometres: true,
        durationSeconds: true,
        equipmentActivations: {
          emergencyOn: true,
        },
        excessAccelerationSeconds: {
          $add: [
            '$excessAccelerationDurationSeconds',
            '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
          ],
        },
        excessBrakingSeconds: {
          $add: [
            '$excessBrakingDurationSeconds',
            '$excessBrakingWithEmergencyEquipmentDurationSeconds',
          ],
        },
        excessCorneringSeconds: {
          $add: [
            '$excessCorneringDurationSeconds',
            '$excessCorneringWithEmergencyEquipmentDurationSeconds',
          ],
        },
        speedInfractionsSeconds: {
          $add: [
            '$speedInfractionsDurationSeconds',
            '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
          ],
        },
        score: {
          $multiply: [
            {
              $divide: [
                {
                  $subtract: [
                    '$durationSeconds',
                    {
                      $add: [
                        '$excessAccelerationDurationSeconds',
                        '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                        '$excessBrakingDurationSeconds',
                        '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                        '$excessCorneringDurationSeconds',
                        '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                        '$speedInfractionsDurationSeconds',
                        '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                      ],
                    },
                  ],
                },
                '$durationSeconds',
              ],
            },
            100,
          ],
        },
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: { ...query, durationSeconds: { $gt: 0 } } },
  {
    $set: {
      excessAccelerationSeconds: {
        $add: [
          '$excessAccelerationDurationSeconds',
          '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
        ],
      },
      excessBrakingSeconds: {
        $add: [
          '$excessBrakingDurationSeconds',
          '$excessBrakingWithEmergencyEquipmentDurationSeconds',
        ],
      },
      excessCorneringSeconds: {
        $add: [
          '$excessCorneringDurationSeconds',
          '$excessCorneringWithEmergencyEquipmentDurationSeconds',
        ],
      },
      speedInfractionsSeconds: {
        $add: [
          '$speedInfractionsDurationSeconds',
          '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
        ],
      },
      score: {
        $multiply: [
          {
            $divide: [
              {
                $subtract: [
                  '$durationSeconds',
                  {
                    $add: [
                      '$excessAccelerationDurationSeconds',
                      '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                      '$excessBrakingDurationSeconds',
                      '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                      '$excessCorneringDurationSeconds',
                      '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                      '$speedInfractionsDurationSeconds',
                      '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                    ],
                  },
                ],
              },
              '$durationSeconds',
            ],
          },
          100,
        ],
      },
    },
  },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
      distanceKilometres: { $sum: '$distanceKilometres' },
      excessAccelerationSeconds: { $sum: '$excessAccelerationSeconds' },
      excessBrakingSeconds: { $sum: '$excessBrakingSeconds' },
      excessCorneringSeconds: { $sum: '$excessCorneringSeconds' },
      speedInfractionsSeconds: { $sum: '$speedInfractionsSeconds' },
      score: { $avg: '$score' },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (query) => [
  { $match: { ...query, durationSeconds: { $gt: 0 } } },
  {
    $project: {
      _id: false,
      identifier: true,
      startTime: true,
      endTime: true,
      driver: {
        code: true,
        collarNumber: true,
        forenames: true,
        surname: true,
        role: true,
      },
      vehicle: {
        registrationNumber: true,
        fleetNumber: true,
        role: true,
        homeStation: true,
        type: true,
        telematicsBoxImei: true,
      },
      rfidCard: {
        reference: true,
        type: true,
        label: true,
      },
      classification: true,
      distanceKilometres: true,
      durationSeconds: true,
      equipmentActivations: {
        emergencyOn: {
          $cond: ['$equipmentActivations.emergencyOn', 'Yes', 'No'],
        },
      },
      excessAccelerationSeconds: {
        $add: [
          '$excessAccelerationDurationSeconds',
          '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
        ],
      },
      excessBrakingSeconds: {
        $add: [
          '$excessBrakingDurationSeconds',
          '$excessBrakingWithEmergencyEquipmentDurationSeconds',
        ],
      },
      excessCorneringSeconds: {
        $add: [
          '$excessCorneringDurationSeconds',
          '$excessCorneringWithEmergencyEquipmentDurationSeconds',
        ],
      },
      speedInfractionsSeconds: {
        $add: [
          '$speedInfractionsDurationSeconds',
          '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
        ],
      },
      score: {
        $multiply: [
          {
            $divide: [
              {
                $subtract: [
                  '$durationSeconds',
                  {
                    $add: [
                      '$excessAccelerationDurationSeconds',
                      '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                      '$excessBrakingDurationSeconds',
                      '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                      '$excessCorneringDurationSeconds',
                      '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                      '$speedInfractionsDurationSeconds',
                      '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                    ],
                  },
                ],
              },
              '$durationSeconds',
            ],
          },
          100,
        ],
      },
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Staff ID',
    id: 'lastDriver.code',
    accessorFn: ({ driver: { code } = {} } = {}) => code ?? '',
  },
  {
    header: 'Forenames',
    id: 'driver.forenames',
    accessorFn: ({ driver: { forenames } = {} } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'driver.surname',
    accessorFn: ({ driver: { surname } = {} } = {}) => surname ?? '',
  },
  {
    header: 'Collar Number',
    id: 'driver.collarNumber',
    accessorFn: ({ driver: { collarNumber } = {} } = {}) => collarNumber ?? '',
  },
  {
    header: 'Driver Role',
    id: 'driver.role',
    accessorFn: ({ driver: { role } = {} } = {}) => role ?? '',
  },
  {
    header: useDallasKeys ? 'Dallas Key' : 'RFID Card',
    id: 'rfidCard.reference',
    accessorFn: ({ rfidCard: { reference } = {} } = {}) => reference ?? '',
  },
  {
    header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
    id: 'rfidCard.type',
    accessorFn: ({ rfidCard: { type } = {} } = {}) => type ?? '',
  },
  {
    header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
    id: 'rfidCard.label',
    accessorFn: ({ rfidCard: { label } = {} } = {}) => label ?? '',
  },
  {
    header: 'Classification',
    accessorKey: 'classification',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Warning Equipment',
    id: 'equipmentActivations.emergencyOn',
    accessorFn: ({ equipmentActivations }) =>
      Boolean(equipmentActivations?.emergencyOn),
    Cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No'),
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: ({ cell }) => ({
      align: 'right',
      sx: {
        color: cell.getValue() ? 'primary.main' : 'text.disabled',
      },
    }),
  },
  {
    header: 'Score',
    accessorKey: 'score',
    Cell: ({ cell }) => `${Math.round(cell.getValue())}%`,
    footer: Number.isNaN(footer.score) ? '' : `${round(footer.score, 0)}%`,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: ({ cell }) => ({
      align: 'right',
      sx: {
        color: scoreColour(cell.getValue()),
      },
    }),
    muiTableFooterCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Distance',
    accessorKey: 'distanceKilometres',
    Cell: ({ cell }) =>
      `${kilometresToMiles(cell.getValue()).toLocaleString()}mi`,
    footer: Number.isNaN(footer.distanceKilometres)
      ? ''
      : `${kilometresToMiles(footer.distanceKilometres).toLocaleString()}mi`,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    muiTableFooterCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
  {
    header: 'Harsh Acceleration',
    accessorKey: 'excessAccelerationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.excessAccelerationSeconds)
      ? ''
      : shortHumanizer(footer.excessAccelerationSeconds * 1000),
  },
  {
    header: 'Harsh Braking',
    accessorKey: 'excessBrakingSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.excessBrakingSeconds)
      ? ''
      : shortHumanizer(footer.excessBrakingSeconds * 1000),
  },
  {
    header: 'Harsh Cornering',
    accessorKey: 'excessCorneringSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.excessCorneringSeconds)
      ? ''
      : shortHumanizer(footer.excessCorneringSeconds * 1000),
  },
  {
    header: 'Speeding',
    accessorKey: 'speedInfractionsSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.speedInfractionsSeconds)
      ? ''
      : shortHumanizer(footer.speedInfractionsSeconds * 1000),
  },
];
