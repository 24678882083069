import {
  AutocompleteController,
  AvatarController,
  CategoryPickerController,
  DateTimePickerController,
  GroupAncestors,
  GroupCodesController,
  ImageUploadController,
  SwitchController,
  TagField,
  TextFieldController,
} from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { doesIdExist, imeiValid } from '@/utils';
import { useDallasKeys, useRestricted, vehicleForm } from '@/utils/config';
import {
  DirectionsCar as CarIcon,
  ExpandMore,
  Router as RouterIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router';
import { OdometerReadingsController } from './OdometerReadingsController';

export function VehicleForm({
  defaultValues,
  canEdit,
  onSubmit,
  onDelete,
  homeStationNames,
  typeOptions,
  installLocations,
  driverIdLocations,
  imeis,
  vehicleAttributes,
  vehicleRoles,
  vehicleTypes,
  vehicleFuelTypes,
  vehicleEquipment,
  vehicleGroups,
  activeDirectoryGroups,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (vehicleForm[fieldName]?.editable || !defaultValues?._id)
    );
  }

  async function isValidImei(imei) {
    const validImei = await imeiValid(imei, getValues('identificationNumber'));
    if (!validImei) {
      return { telematicsBoxImei: 'In use' };
    }

    return true;
  }

  async function identificationNumberDoesNotExist(identificationNumber) {
    return !defaultValues?._id &&
      (await doesIdExist('vehicles', identificationNumber))
      ? 'Exists'
      : true;
  }

  return (
    <Fragment>
      <form id="vehicle-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <AvatarController
              name="picture"
              control={control}
              icon={<CarIcon />}
              disabled={isDisabled('picture')}
            />
            <Typography variant="h6" sx={{ p: 1, flexGrow: 1 }}>
              {defaultValues?._id
                ? defaultValues.registrationNumber
                : 'New Vehicle'}
            </Typography>
            {!isDirty && (
              <Fragment>
                {defaultValues?.telematicsBoxImei && (
                  <Tooltip title="View telematics box">
                    <IconButton
                      size="small"
                      component={Link}
                      to={`/resources/telematicsboxes/${defaultValues?.telematicsBoxImei}`}
                    >
                      <RouterIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Fragment>
            )}
            <Chip
              variant="outlined"
              icon={
                <Chip variant="outlined" label="Last Poll Time" size="small" />
              }
              label={
                defaultValues?.lastPollTime
                  ? format(
                      new Date(defaultValues?.lastPollTime),
                      'dd/MM/yyyy HH:mm:ss',
                    )
                  : 'Never'
              }
            />
          </Stack>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <TextFieldController
              name="identificationNumber"
              label="VIN"
              control={control}
              disabled={isDisabled('identificationNumber')}
              format={(value) => value?.toUpperCase()}
              rules={{
                required: 'Required',
                maxLength: 30,
                pattern: {
                  value: /^[a-z0-9 ]*$/i,
                  message: 'Only alphanumeric',
                },
                validate: identificationNumberDoesNotExist,
              }}
            />
            <TextFieldController
              name="registrationNumber"
              label="Registration"
              control={control}
              disabled={isDisabled('registrationNumber')}
              format={(value) => value?.toUpperCase()}
              rules={{ required: 'Required', maxLength: 20 }}
            />
            <TextFieldController
              name="fleetNumber"
              label="Fleet Number"
              control={control}
              disabled={isDisabled('fleetNumber')}
              format={(value) => value?.toUpperCase()}
              rules={{ maxLength: 50 }}
            />
            <TextFieldController
              name="role"
              label="Role"
              options={vehicleRoles}
              control={control}
              disabled={isDisabled('role')}
            />
            <TextFieldController
              name="assignments.callSign.code"
              label="Call Sign"
              control={control}
              disabled={isDisabled('callSign')}
              format={(value) => value?.toUpperCase()}
              rules={{ maxLength: 20 }}
            />
            {useRestricted && (
              <SwitchController
                name="visibleTo"
                label="Restricted"
                control={control}
                disabled={isDisabled('visibleTo')}
                parse={(value) => !!value}
                format={(value) => (value ? [] : null)}
              />
            )}
          </Stack>
          {watch('visibleTo') && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Visible to
                </Typography>
              </Divider>
              <AutocompleteController
                name="visibleTo"
                control={control}
                fullWidth
                options={activeDirectoryGroups}
                disabled={isDisabled('visibleTo')}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Details
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <TextFieldController
              name="type"
              label="Type"
              options={vehicleTypes}
              control={control}
              disabled={isDisabled('type')}
            />
            <TextFieldController
              name="make"
              label="Make"
              control={control}
              disabled={isDisabled('make')}
            />
            <TextFieldController
              name="model"
              label="Model"
              control={control}
              disabled={isDisabled('model')}
            />
            <TextFieldController
              name="colour"
              label="Colour"
              control={control}
              disabled={isDisabled('colour')}
            />
            <TextFieldController
              name="fuelType"
              label="Fuel Type"
              options={vehicleFuelTypes}
              control={control}
              disabled={isDisabled('fuelType')}
            />
            <TextFieldController
              name="keyNumber"
              label="Key Number"
              type="number"
              control={control}
              disabled={isDisabled('keyNumber')}
            />
            <TextFieldController
              name="marked"
              label="Marked"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              control={control}
              disabled={isDisabled('marked')}
            />
            <DateTimePickerController
              name="disposalDate"
              label="Disposal Date & Time"
              control={control}
              disabled={isDisabled('disposalDate')}
            />
          </Stack>
          {vehicleAttributes.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Attributes
                </Typography>
              </Divider>
              <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
                {vehicleAttributes.map(({ value, label, values }) => (
                  <TextFieldController
                    key={value}
                    name={`attributes.${value}`}
                    label={label}
                    control={control}
                    disabled={!canEdit}
                    options={values}
                  />
                ))}
              </Stack>
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Home
            </Typography>
          </Divider>
          <TextFieldController
            name="homeStation"
            label="Home Station"
            options={homeStationNames.map(({ name, code }) => ({
              label: name,
              value: code,
            }))}
            control={control}
            disabled={isDisabled('homeStation')}
          />
          <GroupCodesController
            name="groupCodes"
            label="Groups & Areas"
            control={control}
            disabled={isDisabled('groupCodes')}
          />
          {defaultValues?.groupAncestors?.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Ancestor Groups & Areas
                </Typography>
              </Divider>
              <GroupAncestors
                groupAncestors={defaultValues?.groupAncestors}
                typeOptions={typeOptions}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Categegories
            </Typography>
          </Divider>
          <CategoryPickerController
            name="groups"
            control={control}
            categories={vehicleGroups}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Equipment
            </Typography>
          </Divider>
          <AutocompleteController
            name="equipment"
            control={control}
            fullWidth
            options={vehicleEquipment}
            disabled={isDisabled('equipment')}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Telematics Box
            </Typography>
          </Divider>
          <Stack spacing={1}>
            <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
              <AutocompleteController
                name="telematicsBoxImei"
                label="IMEI"
                control={control}
                single
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={imeis}
                disabled={isDisabled('telematicsBoxImei')}
                rules={{
                  validate: isValidImei,
                }}
              />
              <DateTimePickerController
                name="commissionDate"
                label="Install Date & Time"
                control={control}
                disabled={isDisabled('commissionDate')}
              />
            </Stack>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body2" component="span">
                  Installation Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1} direction="row">
                  <Stack
                    spacing={1}
                    sx={{
                      flex: 1,
                      p: 1,
                      borderStyle: 'solid',
                      borderColor: 'divider',
                      borderWidth: 1,
                      borderRadius: 1,
                    }}
                  >
                    <AutocompleteController
                      name="installLocation"
                      label="Unit Location"
                      control={control}
                      single
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={installLocations}
                      disabled={isDisabled('installLocation')}
                    />
                    <ImageUploadController
                      name="installLocationImage"
                      control={control}
                      disabled={isDisabled('installLocationImage')}
                    />
                  </Stack>
                  <Stack
                    spacing={1}
                    sx={{
                      flex: 1,
                      p: 1,
                      borderStyle: 'solid',
                      borderColor: 'divider',
                      borderWidth: 1,
                      borderRadius: 1,
                    }}
                  >
                    <AutocompleteController
                      name="driverIdLocation"
                      label={`${
                        useDallasKeys ? 'Dallas Key' : 'RFID Card'
                      } Reader Location`}
                      control={control}
                      single
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={driverIdLocations}
                      disabled={isDisabled('driverIdLocation')}
                    />
                    <ImageUploadController
                      name="driverIdLocationImage"
                      control={control}
                      disabled={isDisabled('driverIdLocationImage')}
                    />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Notes
            </Typography>
          </Divider>
          <TextFieldController
            name="notes"
            control={control}
            fullWidth
            placeholder="Add notes..."
            multiline
            minRows={4}
            disabled={isDisabled('notes')}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Odometer Readings
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <OdometerReadingsController
              name="odometerReadings"
              control={control}
              disabled={isDisabled('odometerReadings')}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Tags
            </Typography>
          </Divider>
          <TagField
            collection="vehicles"
            id={defaultValues?._id}
            linkPrefix="/tags"
            sx={{ pb: 1 }}
            disabled={!defaultValues?._id}
          />
          {canEdit && (
            <Stack spacing={1} direction="row">
              <Button
                color="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={!isDirty || isSubmitting}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {/* <Button
                color="primary"
                aria-owns={anchorEl ? 'events-menu' : undefined}
                aria-haspopup="true"
                onClick={handleEventsClick}
                disabled={isDirty || isSubmitting || defaultValues === null}
              >
                Events
              </Button> */}
              {/* <Menu
                id="events-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEventsClose}
              >
                <MenuItem onClick={navigateToEvents('trips')}>Trips</MenuItem>
                <MenuItem onClick={navigateToEvents('stops')}>Stops</MenuItem>
                <MenuItem onClick={navigateToEvents('idles')}>Idles</MenuItem>
                <MenuItem onClick={navigateToEvents('speedInfractions')}>
                  Speed Infractions
                </MenuItem>
                <MenuItem onClick={navigateToEvents('accelerometerEvents')}>
                  Accelerometer Events
                </MenuItem>
                <MenuItem
                  onClick={navigateToEvents(
                    'vehicleLocationVisits',
                    'locationVisits',
                  )}
                >
                  Location Visits
                </MenuItem>
                <MenuItem
                  onClick={navigateToEvents('malfunctionIndicatorLight')}
                >
                  Malfunction Indicator Light
                </MenuItem>
                <MenuItem onClick={navigateToEvents('onBoardDiagnostics')}>
                  On-Board Diagnostics
                </MenuItem>
              </Menu> */}
              <Button
                color="error"
                onClick={toggleDeleteDialog}
                disabled={!watch('_id')}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.registrationNumber || defaultValues?.fleetNumber}
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Fragment>
  );
}
