import { BoundaryEditor, Parameters, Popup } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, downloadJSON } from '@/utils';
import {
  DataObject as DataObjectIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import {
  alpha,
  Box,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { blue, green, orange, red, yellow } from '@mui/material/colors';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl/maplibre';
import { useVehicleBoundaryPolls } from './useVehicleBoundaryPolls';
import { columns, stateAtom } from './utils';

export function VehicleHeatMap() {
  useDocumentTitle('IR3 | Vehicle Heat Map');
  const { boundaryEditorMap } = useMap();
  const theme = useTheme();
  const [{ boundary, query, parameters }, setState] = useAtom(stateAtom);
  const {
    data: features,
    isFetching,
    isLoading,
    cancel,
  } = useVehicleBoundaryPolls({
    boundary,
    query,
  });
  const featureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features,
    }),
    [features],
  );
  const [error, setError] = useState(null);
  const [mapHoverInfo, setMapHoverInfo] = useState(null);
  const [tableHoverInfo, setTableHoverInfo] = useState(null);

  function handleCancel() {
    cancel();
    setState((state) => ({ ...state, query: {} }));
  }

  function handleParametersChange(value) {
    setState((state) => ({ ...state, parameters: value }));
  }

  function handleFetch(event, value) {
    if (boundary) {
      setError(null);
      setState((state) => ({ ...state, query: value }));
    } else {
      setError('Required');
    }
  }

  function handleBoundaryChange(value) {
    setState((state) => ({ ...state, query: {}, boundary: value }));
  }

  const handleMouseMove = useCallback((event) => {
    const hoveredFeature = event.features && event.features[0];

    if (hoveredFeature) {
      console.log(hoveredFeature);
    }

    setMapHoverInfo({
      feature: hoveredFeature,
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  function handleDownloadClick() {
    const headers = columns.map(({ header, id, accessorKey }) => ({
      label: header,
      key: id ?? accessorKey,
    }));

    downloadCSV(features, 'vehicle-boundary-polls.csv', headers);
  }

  function handleDownloadGeoJSONClick() {
    downloadJSON(featureCollection, 'polls.geojson');
  }

  function renderBottomToolbar() {
    return (
      <Stack
        direction="row"
        sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack direction="row" spacing={1}>
          {features?.length > 0 && [
            <Tooltip key="csv" title="Download CSV">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>,
            <Tooltip key="json" title="Download GeoJSON">
              <IconButton size="small" onClick={handleDownloadGeoJSONClick}>
                <DataObjectIcon />
              </IconButton>
            </Tooltip>,
          ]}
        </Stack>
        <MRT_TablePagination table={table} />
      </Stack>
    );
  }

  const handleRowMouseOver = (feature) => () => {
    setTableHoverInfo({
      longitude: feature.geometry.coordinates[0],
      latitude: feature.geometry.coordinates[1],
      feature: {
        ...feature,
        source: 'vehicles',
      },
    });
  };

  function handleMouseOut() {
    setTableHoverInfo(null);
  }

  const handleRowClick = (center) => () => {
    boundaryEditorMap.flyTo({ center, zoom: 20 });
  };

  const table = useMaterialReactTable({
    columns,
    data: features,
    initialState: {
      sorting: [{ id: 'properties.time', desc: false }],
    },
    state: {
      density: 'compact',
      isLoading: isFetching || isLoading,
    },
    defaultColumn: { size: 0 },
    getRowId: (row) => +row.id,
    renderBottomToolbar,
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'none',
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    // enableColumnFilters: true,
    // enableFacetedValues: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 298px)',
        maxHeight: 'calc(100vh - 178px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onMouseOver: handleRowMouseOver(row.original),
      onMouseOut: handleMouseOut,
      onClick: handleRowClick(row.original.geometry?.coordinates),
      sx: { cursor: 'pointer' },
    }),
  });

  return (
    <Stack direction="row" sx={{ height: 'calc(100vh - 48px)' }}>
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Parameters
          collection="vehicleBoundaryPolls"
          onFetch={handleFetch}
          onCancel={handleCancel}
          isFetching={isFetching || isLoading}
          value={parameters}
          onChange={handleParametersChange}
          sx={{ pt: 1, height: 1 }}
          vehicle
          pointEvent
        />
      </Box>
      <Stack
        spacing={1}
        sx={{
          p: 1,
          overflow: 'auto',
          '& .maplibregl-popup-content': {
            backgroundColor: theme.palette.background.paper,
          },
          '& .maplibregl-popup-anchor-top .maplibregl-popup-tip': {
            borderBottomColor: theme.palette.background.paper,
          },
          '& .maplibregl-popup-anchor-bottom .maplibregl-popup-tip': {
            borderTopColor: theme.palette.background.paper,
          },
          '& .maplibregl-popup-anchor-left .maplibregl-popup-tip': {
            borderRightColor: theme.palette.background.paper,
          },
          '& .maplibregl-popup-anchor-right .maplibregl-popup-tip': {
            borderLeftColor: theme.palette.background.paper,
          },
        }}
      >
        <BoundaryEditor
          value={boundary}
          onChange={handleBoundaryChange}
          interactiveLayerIds={['point-layer']}
          onMouseMove={handleMouseMove}
          error={error}
          sx={{ minWidth: 240, height: 640, flexShrink: 0 }}
        >
          <Source id="vehicles" type="geojson" data={featureCollection}>
            <Layer
              id="point-heatmap-layer"
              type="heatmap"
              paint={{
                'heatmap-weight': 0.01,
                'heatmap-intensity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  1,
                  20,
                  3,
                ],
                'heatmap-color': [
                  'interpolate',
                  ['linear'],
                  ['heatmap-density'],
                  0,
                  alpha(theme.palette.common.black, 0),
                  0.2,
                  blue[500],
                  0.4,
                  green[500],
                  0.6,
                  yellow[500],
                  0.8,
                  orange[500],
                  1,
                  red[500],
                ],
                'heatmap-radius': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  2,
                  20,
                  20,
                ],
              }}
            />
            <Layer
              id="point-layer"
              type="circle"
              minzoom={18}
              paint={{
                'circle-color': blue[500],
                'circle-radius': 6,
                'circle-stroke-color': theme.palette.common.white,
                'circle-stroke-width': 2,
              }}
            />
          </Source>
          {mapHoverInfo?.feature && <Popup hoverInfo={mapHoverInfo} />}
          {tableHoverInfo && <Popup hoverInfo={tableHoverInfo} />}
        </BoundaryEditor>
        <Box>
          <MaterialReactTable table={table} />
        </Box>
      </Stack>
    </Stack>
  );
}
