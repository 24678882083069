import { useDocumentTitle } from '@/hooks';
import ir3Logo from '@/svg/white-logo.svg';
import { Home as HomeIcon } from '@mui/icons-material';
import { AppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useNavigate, useRouteError } from 'react-router';
import { LoginAvatar } from './controls';

export function ErrorBoundary() {
  useDocumentTitle('IR3 | Error');
  const { error } = useRouteError();
  const navigate = useNavigate();

  function handleHomeClick() {
    navigate('/', { replace: true });
  }

  return (
    <Fragment>
      <AppBar color="error" position="fixed">
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={handleHomeClick}
          >
            <HomeIcon />
          </IconButton>
          <img src={ir3Logo} alt="IR3 Logo" style={{ height: 24 }} />
          <Typography variant="h6" sx={{ pl: 0.5, fontWeight: 'bold' }}>
            IR3
          </Typography>
          <Typography variant="h6" sx={{ px: 0.5, flexGrow: 1 }}>
            | Error
          </Typography>
          <LoginAvatar />
        </Toolbar>
      </AppBar>
      <Stack spacing={1} sx={{ px: 1 }}>
        <Toolbar variant="dense" />
        <Typography component="p">
          {error?.message ?? 'An unknown error has occurred.'}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {error?.stack}
        </Typography>
      </Stack>
    </Fragment>
  );
}
